<template>
    <div v-loading="loading" :style="{height: this.data.length === 0 ? '100vh' : 'auto'}">
    <div class="container" :style="{height: this.data.length === 0 ? 'calc(100vh - 73px)' : 'auto'}">
        <Nav :isDark.sync="this.isDark" @darkmode="isDark = $event"></Nav>
        <div class="d-flex justify-content-between align-items-center" style="width: 1080px;margin: 0 auto;">
            <span class="fs-4">{{ this.dateStr }}</span>
            <div>
                <router-link :to="'/qsda-tomorrow'" class="text-primary" style="cursor: pointer;">查看明日比赛>></router-link>
                <router-link :to="'/qsda-today-new'" class="text-primary ps-3" style="cursor: pointer;">返回新版>></router-link>
            </div>
        </div>
        <div :class="{'h-100':this.data.length === 0}">
            <div class="mb-4 text-center" :class="{'h-100':this.data.length === 0}" v-if="this.data.length === 0 && !loading">
                <div class="nodata d-flex flex-column justify-content-center" :class="{'h-100':this.data.length === 0}">
                    <span class="align-center">暂无比赛数据</span>
                </div>
            </div>
            <table :class="{'table-dark': this.isDark}" class="table table-striped border" ref="table" v-if="this.data.length !== 0" style="width: 1080px;margin: 0 auto;">
                <thead style="background-color: #82959F!important;">
                    <tr class="font-weight-normal py-0 text-white">
                        <th class="border-end font-weight-normal py-1" width='40'>#</th>
                        <th class="border-end font-weight-normal py-1">联赛</th>
                        <th class="border-end text-center font-weight-normal py-1" width='40'>时间</th>
                        <th class="border-end text-end font-weight-normal py-1">主队</th>
                        <th class="border-end text-center font-weight-normal p-1" width='60'>qsda</th>
                        <th class="border-end text-left font-weight-normal py-1">客队</th>
                        <th class='border-end text-center font-weight-normal p-1' v-if="isToday == true">指数1</th>
                        <th class='border-end text-center font-weight-normal p-1'>Crow*</th>
                        <th class='border-end text-center font-weight-normal p-1' v-if="isToday == true">指数2</th>
                        <th class='text-center font-weight-normal py-1' v-if="isToday == false">大小</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in data" :key="index">
                        <td class="border-end align-middle px-1 text-center" width='30'>{{index+1}}</td>
                        <td :style="{'background':callSetColor(item.color,item.name)}" class='border-end td2 text-white align-middle text-center py-0' width='100'>{{ item.name }}</td>
                        <td class='border-end text-center align-middle' width='40'>{{item.time}}</td>
                        <td class='border-end text-end align-middle'>
                            <sup><font>{{ item.homerank }}</font></sup>
                            <router-link :to="{ path: '/charts/' + item.id, query: { teams: item.home+' VS '+item.away } }" class="my-2 text-decoration-none ps-1" tag="a" target="_blank">{{ item.home }}</router-link>
                        </td>
                        <td class='text-center font-weight-bold border-end align-middle qsda qsdaColor' width='60' :style="{color: qsdaColorSet(item.handicap,item.qsda)}"><a :href="'https://zq.titan007.com/analysis/'+item.id+'cn.htm'" target='_blank' class="text-decoration-none p-1">{{ item.qsda }}</a>
                        </td>
                        <td class='border-end text-left align-middle'>
                            {{ item.away }}
                            <sup><font>{{ item.awayrank }}</font></sup>
                        </td>
                        <td class='border-end text-center' width="60" v-if="isToday == true">
                            <div style='color:#880000;'>{{ item.index1 }}</div>
                            <div>{{ item.big }}</div>
                        </td>
                        <td class='border-end text-center' width="100">
                            <div style='color:#880000;'>{{ item.handicap }}</div>
                            <div v-if="isToday == true">{{ item.bigsmall }}</div>
                        </td>
                        <td class='border-end text-center' width="60" v-if="isToday == true">
                            <div style='color:#880000;'>{{ item.index2 }}</div>
                            <div>{{ item.small }}</div>
                        </td>
                        <td class='border-end text-center' v-if="isToday == false">{{ item.bigsmall }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
// import jsonData from '@/data/tomorrow.json';
// eslint-disable-next-line no-unused-vars
import {setcolor } from '@/utils/setcolor';
import { checkColor, handicapToNum, convertToReal, compare, dateFormatted } from '../utils/utils'
import Nav from './comment/nav.vue'
import Footer from './comment/footer.vue'
export default {
    inject: ['reload'],
    components: { Nav,Footer },
    data() {
        return {
            data: [],
            league:[],
            columns: [
                { label: '联赛', prop: 'league' },
                { label: '时间', prop: 'time' },
                { label: '主排名', prop: 'homerank' },
                { label: '主队', prop: 'home' },
                { label: 'qsda', prop: 'qsda' },
                { label: '客队', prop: 'away' },
                { label: '客排名', prop: 'awayrank' },
                { label: '指数1', prop: 'index1' },
                { label: 'Crow', prop: 'handicap' },
                { label: '指数2', prop: 'index2' },
                { label: '小球', prop: 'small' },
                { label: '大小', prop: 'bigsmall' },
                { label: '大球', prop: 'big' },
                { label: 'id', prop: 'id' },
            ],
            isDark: false,
            isToday: true,
            isTomorrow: false,
            loading: true,
            dateStr: '',
            currentDate: 0,
            dateParams: ''
        }
    },
    mounted() {
        console.log(this.$route.params.date);
        this.dateParams = this.$route.params.date;
        this.getData();
    },
    methods: {
        setcolor,
        dateFormatted,
        handicapToNum,
        checkColor,
        convertToReal,
        compare,
        getData(){
            let date = 'today';
            if (this.dateParams !== undefined) {
                date = 'date/'+this.dateParams
            }
            console.log(date);
            const url = 'https://api.qsda.net/api/match/'+date;
            // const url = '/match/todaym';
            fetch(url)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                // const today = new Date();
                // const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
                // const currentHours = today.getHours()
                // const currentMin = today.getMinutes();
                // console.log( currentHours < 10 || currentHours == 10 && currentMin < 30);
                // this.data = data.data;

                // if (currentHours < 10 || currentHours == 10 && currentMin < 30) {
                //     console.log('日期不是今日但小于10:30');
                    this.dateStr = data.data[0].date
                    this.data = data.data;
                    this.loading = false;
                // }else if ( data.data[0].date == this.dateFormatted(today)) {
                    // console.log('日期是今日');
                    // this.dateStr = this.dateFormatted(today)
                    // this.data = data.data;
                    // this.loading = false;
                // }else{
                //     console.log('不是今日，读取明日');
                //             console.log('没有数据');
                //             this.data = [];
                //             this.dateStr = ''
                //             this.loading = false;
                //             this.isToday = false;
                        
                // }
            })
            .catch(error => console.error('Error:', error));
        },
        switeToTomorrowData(){
            this.loading = true;
            this.isTomorrow = true;
            this.isToday = false;
            const today = new Date();
            const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
            const currentHours = today.getHours()
            const currentMin = today.getMinutes();
            const tomorrowUrl = 'https://api.qsda.net/api/match/tomorrow';
                    fetch(tomorrowUrl)
                    .then(response => response.json())
                    .then(data => {
                        if (currentHours < 10 || currentHours == 10 && currentMin < 30) {
                            console.log('当前时间小于10点30分');
                            this.data = data.data;
                            this.dateStr = this.data[0].date;
                            this.loading = false;
                            this.isToday = false;
                            this.isTomorrow = true;
                        }else if (data.data[0].date == this.dateFormatted(tomorrow)) {
                            console.log('日期是明日');
                            this.dateStr = this.dateFormatted(tomorrow)
                            this.data = data.data;
                            this.loading = false;
                            this.isToday = false;
                        }else{
                            console.log('没有数据');
                            this.data = [];
                            this.dateStr = this.dateFormatted(tomorrow)
                            this.loading = false;
                            this.isToday = false;
                        }
                    })
                    .catch(error => console.error('Error:', error));
        },
        switeToTodayData(){
            this.loading = true;
            this.isTomorrow = false;
            this.isToday = true;
            this.getData()
            // const today = new Date();
            // const currentHours = today.getHours()
            // const currentMin = today.getMinutes();
            // const Url = 'https://api.qsda.net/api/match/today';
            // fetch(Url)
            // .then(response => response.json())
            // .then(data => {
            //     if (currentHours < 10 || currentHours == 10 && currentMin < 30) {
            //         console.log('日期不是今日但小于10:30');
            //         this.dateStr = this.dateFormatted(today)
            //         this.data = data.data;
            //         this.loading = false;
            //     }
            //     else if (data.data[0].date == this.dateFormatted(today)) {
            //         console.log('日期是今日');
            //         this.data = data.data;
            //         this.dateStr = this.dateFormatted(today)
            //         this.loading = false;
            //         this.isToday = true;
            //     }else{
            //         console.log('没有数据');
            //         this.data = [];
            //         this.dateStr = ''
            //         this.loading = false;
            //         this.isToday = true;
            //     }
            // })
            // .catch(error => console.error('Error:', error));
        },
        callSetColor(color,league){
            return setcolor(color,league);
        },
        qsdaColorSet(handicap,qsda){
            let qsdaColor = 'rgb(128,0,255)';
            let lowOrHight = 2;
            if (handicap!=='') {
                const handicapNum = handicapToNum(handicap);
                const realHandicapNum = convertToReal(qsda);
                if (handicapNum === 0) {
                    qsdaColor = 'rgb(128,0,255)';
                    return qsdaColor
                }else{
                    if (typeof handicapNum !== 'undefined' && typeof realHandicapNum !== 'undefined') {
                            lowOrHight = compare(handicapNum, realHandicapNum);
                        if(lowOrHight === 0){
                            qsdaColor = '#000';
                            return qsdaColor
                        }
                        if (lowOrHight === 1) {
                            qsdaColor = 'red';
                            return qsdaColor
                        }
                        if (lowOrHight === -1) {
                            qsdaColor = 'green';
                            return qsdaColor
                        }
                    }
                }
            }
        },
        istoday(){
            const today = new Date();
            if (this.dateStr == this.dateFormatted(today)) {
            console.log('istoday true');
                return true
            }else{
                console.log('istoday false');
                return false
            }
        },
        istomorrow(){
            const today = new Date();
            const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
            if (this.dateStr == this.dateFormatted(tomorrow)) {
            console.log('istomorrow true');
                return true
            }else{
                console.log('istomorrow false');
                return false
            }
        },
        // callChangeTeamName(name){
        //     return changeTeamName(name);
        // },
        // callChangeLeagueName(name){
        //     return changeLeagueName(name);
        // }
        filterTag({value, row, column}) {
            // console.log(column.filters);
            const property = column['property'];
                return row[property] === value;
        },
    },
}
</script>
<style lang="scss" scoped>
    @import '../assets/sass/global.scss';
    .container{padding: 60px 0;}
    font {color: rgb(136, 136, 136);}
    .table {
        --bs-table-bg: none;
        --bs-table-color: none;
        td {padding: 0.75rem;}
        .td2 {--bs-table-bg-type: inherit;}
    }
    .nodata {
            color: #ccc;
        }
    .NIGHT {
        .nodata {
            color: #666;
        }
    }
    // .nodata:empty::before {
    //     display: block;
    //     width: 100%;
    //     padding: 100px 2rem;
    //     color: #ccc;
    //     text-align: center;
    //     content: "暂无比赛数据";
    //     border-radius: 0.5rem;
    // }
</style>