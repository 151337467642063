<template>
    <div>
        <Nav :isDark.sync="isDark" @darkmode="isDark = $event"></Nav>
        <div class="container">
            <div class="mt-3 h-100 d-flex justify-content-center flex-column align-items-center">
                <div class="d-flex justify-content-center">
                    <div class="me-2">
                        <el-input type="number" placeholder="QSDA" v-model="qsdaVal" clearable></el-input>
                    </div>
                    <div class="d-flex flex-column">
                        <el-button type="primary" @click="qsdaToReal(qsdaVal)">转换</el-button>
                    </div>
                </div>
                <div class="text-center mt-4 py-4 fs-24">
                    真实盘口：{{ this.realVal }}
                </div>
            </div>
            
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Nav from './comment/nav.vue'
import Footer from './comment/footer.vue'
import { convertToReal, numToHandicap } from '../utils/utils'
export default {
    components: { Nav,Footer },
    data(){
        return {
            isDark: false,
            qsdaVal: 0,
            realVal: ''
        }
    },
    methods: {
        qsdaToReal(num){
           const res = convertToReal(num);
           this.realVal = numToHandicap(res);
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../assets/sass/global.scss';
.container {height: calc(100vh - 75px); padding-top: 60px;}
</style>