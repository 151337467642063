<template>
    <div v-loading="loading">
    <div class="container py-4" :style="{'min-height': this.tomorrowData.length === 0 ? 'calc(100vh - 75px)' : 'auto'}">
        <Nav :isDark.sync="this.isDark" @darkmode="isDark = $event"></Nav>
        <h1 class="fs-24 font-weight-bold text-center mb-3 mt-5">明日数据获取</h1>
        <div>
            <div class="d-flex justify-content-between">
                <h4 class="fs-16 font-weight-bold">要抓的联赛（必须提前一天，不然抓不到）:</h4>
                <el-button type="text" class="fs-12 text-decoration-underline" @click="changeTools">我要切换方式 >></el-button>
            </div>
            <div v-if="!this.switchToTxt">
                <el-empty description="暂无联赛数据，点击按钮获取" v-if="this.leagueList.length === 0">
                    <el-button type="primary" @click="getJiebao">获取</el-button>
                </el-empty>
                <div class="leagueTag">
                    <el-tag
                        :key="tag"
                        v-for="tag in leagueList"
                        closable
                        :disable-transitions="false"
                        @close="handleClose(tag)">
                        {{tag}}
                    </el-tag>
                    <!-- <el-tag type="info" @click="updateJbLeague" v-if="this.leagueList.length > 0">保存更改</el-tag> -->
                    <el-tag type="warning" v-if="this.leagueList.length > 0">
                        <el-button type="text" class="p-0 m-0 fs-12 text-warning" @click="clearList">清空</el-button>
                    </el-tag>
                </div>
            </div>
            <el-input v-if="this.switchToTxt" class="league_textarea" type="textarea" v-model="league" :placeholder="this.placeholder"></el-input>
            <div class="d-flex justify-content-between align-items-end">
                <h4 class="fs-16 font-weight-bold mt-3">抓取的比赛（必须先抓取联赛列表，再执行这里的抓取）:</h4>
                <el-popover placement="bottom" trigger="click" content="把所有比赛先全选删除即可再抓取">
                    <el-button slot="reference" type="text" class="fs-12 p-0">重新抓取比赛>></el-button>
                </el-popover>
            </div>
            <el-empty description="暂无比赛数据，点击按钮获取" v-if="this.tomorrowData.length === 0">
                <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="getMatch">获取</el-button>
            </el-empty>
            <div class="d-flex justify-content-between mt-3">
                <div class="text-danger fs-12">
                    {{ this.getDataRes }}
                </div>
            </div>
        </div>
        <div v-if="this.tomorrowData.length > 0">
            <Edite class="mt-2" :matchdata="this.tomorrowData" :dataType="'tomorrow-new'"></Edite>
        </div>
        
    </div>
    <Footer></Footer>
    </div>
    <!-- <div class="container py-4">
        <h1 class="fs-24 font-weight-bold text-center mb-3">获取比赛信息</h1>
        
        <div class="text-center mt-4">
            <el-select v-model="chooseValue" placeholder="请选择日期" @change="selectRefs()">
                <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                </el-option>
            </el-select>
            <el-button type="primary ms-2" @click="getMatch">获取比赛</el-button>
        </div>
        <div>
            <el-input class="league_textarea" type="textarea" v-model="league"></el-input> 
            <div v-if="this.chooseValue == '2'">
            <h4 class="fs-16 font-weight-bold">选择要抓的联赛:</h4>
                <el-empty v-if="this.leagueList.length == 0" description="暂无联赛列表，请点击“获取联赛”按钮">
                    <el-button type="primary" @click="getLeagueArry">获取联赛</el-button>
                </el-empty>
            </div>
        </div>
    </div>-->
</template>
<script>
import Edite from './edite.vue';
import Nav from './comment/nav.vue'
import Footer from './comment/footer.vue'
import TokenBucket from '../utils/tokenBucket';
import { crawlerTomorrowMatchUrl,crawlerLeagueListUrl, getMatchUrl } from '../constants/http.config'
import { dateFormatted } from '../utils/utils'
// import {changeLeagueName,changeTeamName} from '@/utils/change';
export default {
    components: { Edite, Nav,Footer },
    inject: ['reload'],
    data() {
        return {
            bucket: new TokenBucket(1, 10),
            league: '',
            chooseValue: '',
            placeholder: '填要采的比赛，联赛用英文逗号隔开，格式：英超,意甲,德甲,法甲,...',
            fullscreenLoading: false,
            options: [
                        {value:'1',label:'今日比赛'},
                        {value:'2',label:'明日比赛'},
                    ],
            leagueList: [],
            getDataRes: '',
            tomorrowData: [],
            dynamicTags: [],
            dateTitle: '',
            isDark: false,
            loading: true,
            switchToTxt: true 
        }
    },
    mounted() {
        this.validatorData();
    },
    methods: {
        dateFormatted,
        getJiebao(){
            // const url = '/crawler/tomorrow_jiebao';
            // const url = 'https://api.qsda.net/api/crawler/tomorrow_jiebao';
            fetch(crawlerLeagueListUrl,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                if (data.msg == 'success') {
                    this.$notify({
                        title: '成功',
                        message: '抓取数据成功',
                        type: 'success'
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            });
        },
        validatorData(){
            //读取联赛列表
            // const url1 = 'https://api.qsda.net/api/match/tomorrow_jiebao';
            // const url1 = '/match/tomorrow_jiebao';
            // fetch(leagueListTomorrowUrl)
            // .then(response => response.json())
            // .then(data => {
            //     this.loading = false;
            //     console.log(data.data);
            //     this.leagueList = data.data;
            // }).catch(err=>{
            //     this.loading = false;
            //     console.log(err);
            // })

            //读取比赛
            let date = new Date();
            date.setDate(date.getDate() + 1);
            const tomorrowDate = this.dateFormatted(date);
            // console.log(tomorrowDate);
            
            const url = getMatchUrl + tomorrowDate;
            // const url = '/match/date/' + tomorrowDate;
            fetch(url)
            .then(response => response.json())
            .then(data => {
                this.loading = false;
                console.log(data.data);
                this.tomorrowData = data.data;
            }).catch(err=>{
                this.loading = false;
                console.log('读取数据出错',err);
            })
        },
        getMatch() {
            // console.log(this.leagueList);
            const now = Date.now();
            const lastRequestTime = localStorage.getItem('lastRequestTime');
            if (lastRequestTime == undefined || null == lastRequestTime) {
                localStorage.setItem('lastRequestTime', now);
            }
            if (lastRequestTime && now - lastRequestTime < 30000) {
                console.log('Request rejected');
                alert('操作太频繁，请休息一下再试');
                return;
            }
            let postData = []
            if (!this.switchToTxt) {
                //按钮方式
                console.log('按钮...',this.leagueList);
                if (this.leagueList.length == 0) {
                    this.$message.error('没有需要抓的联赛!');
                    return;
                }
                postData = this.leagueList.map(league => ({ name: league }));
            }else{
                //输入框方式
                console.log('输入框...');
                if (this.league == '') {
                    this.$message.error('请输入需要抓的联赛!');
                    return;
                }
                const leagueArry = this.league.split(',');
                // let leagueData = [];
                for (let index = 0; index < leagueArry.length; index++) {
                    const league = leagueArry[index];
                    postData.push(
                        {'name':league }
                    )
                }
            }
            // const postData = this.leagueList.map(league => ({ name: league }));
            // console.log(postData);
            const url = '/crawler/tomorrow_tk';
            // const url = 'https://api.qsda.net/api/crawler/tomorrow_tk';
            fetch(crawlerTomorrowMatchUrl,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(postData)
            })
            .then(response => response.json())
            .then(data => {
                // console.log(data);
                localStorage.setItem('lastRequestTime', now);
                this.fullscreenLoading = true;
                if (data.code == 200) {
                    this.loading = false;
                    this.fullscreenLoading = false;
                    this.$notify({
                        title: '抓取成功',
                        message: data.data,
                        type: 'success',    
                        // duration: 0
                    });
                    this.validatorData()
                } else {
                    this.fullscreenLoading = false;
                    this.$notify.error({
                        title: '抓取失败',
                        message: '未知错误，请重试'
                    });
                }
            })
            .catch(error=>{
                console.log(error);
                // localStorage.setItem('lastRequestTime', now);
                this.$notify.error({
                    title: '请求失败',
                    message: '未知错误，请重试'
                });
            })
        },
        changeTools(){
            this.switchToTxt = !this.switchToTxt
        },
        sendRequest() {
            const now = Date.now();
            const lastRequestTime = localStorage.getItem('lastRequestTime');
            if (lastRequestTime && now - lastRequestTime < 60000) {
                console.log('Request rejected');
                alert('操作太频繁，请休息一下再试')
                // return false;
            } else {
                localStorage.setItem('lastRequestTime', now);
                console.log('Request accepted');
                // 在这里发送请求
                // return true;
            }
        },
        handleClose(tag) {
            this.leagueList.splice(this.leagueList.indexOf(tag), 1);
            console.log(this.leagueList.length);
        },
        clearList(){
            this.leagueList = [];
        },
        updateJbLeague(){
            const updateData = this.leagueList.map(league => ({ name: league }));
            console.log(updateData);
        }
    },
}
</script>
<style lang="scss" scoped>
    @import '../assets/sass/global.scss';
    ::v-deep .el-textarea textarea { min-height: 100px!important;}
    .leagueTag { margin: 0 -8px;}
    .el-tag { margin: 8px; }
</style>