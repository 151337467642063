<template>
    <div :class="{'NIGHT': isDark}" v-loading="loading">
        <Nav :isDark="this.isDark"></Nav>
        <div class="container-fluid px-0" :style="{height: loading || this.favList.length < 10 ? 'calc(100vh - 75px)' : 'auto'}" v-if="!loading">
      <div>
            <el-empty v-if="this.favList.length == 0 && !loading" description="暂无数据，请到比赛页面添加收藏"></el-empty>
      </div>
      <div v-for="(item,index) in favList" :key="index">
        <div>
          <div class="d-flex justify-content-between align-items-center py-2 border-bottom px-2">
            <div class="d-flex flex-column">
              <div>
                <span class="fs-10 me-3" :style="{ color: item.color }">{{ item.league }}</span>
                <span class="fs-10 time me-2">{{ formattedDateToMMDD(item.date,item.time) }}</span>
                <span class="fs-10 time">{{ item.time }}</span>
              </div>
              <div class="d-flex">
                <button class="btn ps-0" @click="addOrDelFav(item.id)">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#ffc107" class="bi bi-star-fill" viewBox="0 0 16 16">
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                </svg>
              </button>
                <div class="d-flex flex-column">
                  <span>
                      <b class="fs-14">{{ item.home }}</b>
                      <font class="fs-12 me-1">{{ filteredNum(item.homerank) }}</font>
                  </span>
                  <span>
                      <b class="fs-14">{{ item.away }}</b>
                      <font class="fs-12 me-1">{{ filteredNum(item.awayrank) }}</font>
                  </span>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column pe-1">
              <span class="fs-10 d-flex justify-content-end align-items-center" style="height: 24px;">
                <router-link :to="{ path: '/charts/' + item.id, query: { teams: item.home+' VS '+item.away } }" class="text-decoration-none ms-2" tag="a" target="_blank">
                  <i class="el-icon-s-data fs-12" style="color: #ccc;"></i>
              </router-link>
              </span>
              <div class="d-flex" style="height: 48px;">
                <div class="d-flex flex-column align-items-end">
                  <span class="fs-12" style="color:#e6b272;">{{ item.handicap }}</span>
                  <span class="fs-12 mt-1" style="color:#e6b272;">{{ item.bigsmall }}</span>
                </div>
                <a class="text-decoration-none d-flex flex-column justify-content-center" :href="'https://m.titan007.com/Analy/ShiJian/'+item.m_id+'.htm'" target="_blank">
                  <b class="fs-14 ps-3 qsdaColor" :style="{color: qsdaColorSet(item.handicap,item.qsda)}">{{ item.qsda }}</b>
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- <div data-bs-toggle="collapse" :data-bs-target="'#collapse' + index+1" aria-expanded="false" :aria-controls="'collapse' + index+1" @click="getHistory(item.handicap, item.handicap, item.bigsmall)"> -->
          <!-- <div class="d-flex justify-content-between align-items-center py-3 border-bottom px-3">
            <div class="team d-flex align-items-center">
              <button class="btn" @click="addOrDelFav(index,item)">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" v-show="!isFav[index]" fill="#eee" class="bi bi-star-fill" viewBox="0 0 16 16">
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" v-show="isFav[index]" fill="#ffc107" class="bi bi-star-fill" viewBox="0 0 16 16">
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                </svg>
              </button>
              <div class="flex-fill text-end d-flex flex-column">
                <span class="fs-10" :style="{ color: item.color }">{{ item.name }}</span>
                <span>
                  <font class="fs-12 me-1">{{ filteredNum(item.homerank) }}</font>
                  <b class="fs-14">{{ item.home }}</b>
                </span>
                <span class="fs-10" style="color:#e6b272;">{{ item.handicap }}</span>
              </div>
            </div>
            <div class=" d-flex flex-column">
              <span> </span>
              <a :href="'https://m.nowscore.com/Analy/ShiJian/'+item.id+'.htm'" target="_blank"><b class="fs-14 px-3 qsdaColor">{{ item.qsda }}</b></a>
              <span> </span>
            </div>
            <div class="flex-fill d-flex flex-column team">
              <span class="fs-10 time">{{ item.time }}</span>
              <span>
                <b class="fs-14">{{ item.away }}</b>
                <font class="fs-12 ms-1">{{ filteredNum(item.awayrank) }}</font>
              </span>
              <span class="fs-10" style="color:#e6b272;">{{ item.bigsmall }}</span>
            </div>
          </div> -->
      </div>
    </div>
    <Footer></Footer>
    </div>
</template>
<script>
import Nav from './comment/nav.vue';
import Footer from './comment/footer.vue'
import { handicapToNum, convertToReal, compare } from '../utils/utils'
export default {
    components: { Nav,Footer },
    data() {
        return {
            matchdata: [],
            historyList: [],
            isDark: false,
            isToday: true,
            loading: true,
            isFav: [],
            favList: [],
        }
    },
    mounted() {
        this.getFavList()
        console.log(this.favList);
    },
    methods:{
      formattedDateToMMDD(date,time){
      const formattedDate = date.slice(5);
      if (time < '12:00') {
        const [month, day] = formattedDate.split('-').map(Number);
        // 获取当前年份
        const currentYear = new Date().getFullYear();
        // 创建一个新的 Date 对象，年份可以随意设置
        const d = new Date(currentYear, month - 1, day);
        // 日期加1天
        d.setDate(d.getDate() + 1);
        // 格式化新的日期
        const newMonth = String(d.getMonth() + 1).padStart(2, '0');
        const newDay = String(d.getDate()).padStart(2, '0');
        const newDateStr = `${newMonth}-${newDay}`;
        return newDateStr;
      }else{
        return formattedDate;
      }
    },
    qsdaColorSet(handicap,qsda){
      let qsdaColor = 'rgb(128,0,255)';
      let lowOrHight = 2;
      if (handicap!=='') {
          const handicapNum = handicapToNum(handicap);
          const realHandicapNum = convertToReal(qsda);
          // console.log(handicapNum, realHandicapNum);
          if (handicapNum === 0) {
              qsdaColor = 'rgb(128,0,255)';
              return qsdaColor
          }else{
              if (typeof handicapNum !== 'undefined' && typeof realHandicapNum !== 'undefined') {
                      lowOrHight = compare(handicapNum, realHandicapNum);
                  if(lowOrHight === 0){
                      qsdaColor = '#666';
                      return qsdaColor
                  }
                  if (lowOrHight === 1) {
                      qsdaColor = 'red';
                      return qsdaColor
                  }
                  if (lowOrHight === -1) {
                      qsdaColor = 'green';
                      return qsdaColor
                  }
              }
          }
      }
    },
      filteredNum(str){
        if (null == str || '' == str) {
          return '';
        }else{
          const rank = str.match(/\d+/) !==null ? '['+str.match(/\d+/)[0]+']' : '';
          return rank;
        }
      },
        getFavList(){
          // console.log('qqqq');
            if (this.favList.length == 0) {
                // 从 localStorage 中获取字favlist
                let favListStr = localStorage.getItem('favList');
                let favStateStr = localStorage.getItem('favState');
                if ( null !== favListStr) {
                  this.favList = JSON.parse(favListStr);
                }
                if (null !== favStateStr) {
                  this.isFav = JSON.parse(favStateStr);
                }else if(null == favStateStr || favStateStr.length == ''){
                  this.isFav = [];
                }
            }
            this.loading = false;
        },
        addOrDelFav(id){
          const updatedFavList = this.favList.filter(match => match.id !== id);
          this.favList = updatedFavList;
          for (let index = 0; index < this.isFav.length; index++) {
            if (id === this.isFav[index].id) {
              this.isFav[index].selected = false;
              console.log(index,this.isFav[index]);
            }
          }
          // 设置过期时间为1天后
          // var expiration = new Date().getTime() + (24 * 60 * 60 * 1000);
          // 设置过期时间为每天的10:30
          var now = new Date();
          var millisTill10 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10, 30, 0, 0) - now;
          if (millisTill10 < 0) {
              millisTill10 += 86400000; // 如果时间已经过了，那么设置的是明天的10:30
          }
          //保存到 localStorage
          let favListStr = JSON.stringify(updatedFavList);
          let favStateStr = JSON.stringify(this.isFav);
          localStorage.setItem('favList', favListStr);
          localStorage.setItem('favListExpirationDate', JSON.stringify({
            value: 'favListStr',
            expiration: now.getTime() + millisTill10
          }));
          localStorage.setItem('favState', favStateStr);
          localStorage.setItem('favListExpirationDate', JSON.stringify({
            value: 'favStateStr',
            expiration: now.getTime() + millisTill10
          }));
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../assets/sass/global.scss';
.team { width: 45%;}
font,.time {color: rgb(136, 136, 136);}
.container-fluid {padding-top: 56px;}
@media (max-width:1024px) {
  .container-fluid {padding-top: 42px;}
}
</style>