<template>
    <div style="height: 100vh;">
        <Nav :isDark.sync="this.isDark" @darkmode="isDark = $event"></Nav>
        <div class="container pc-list">
            <div class="row" v-if="!isphone" style="height: 100%;">
                <div class="col-lg-3">
                    <el-input class="mb-1" placeholder="搜索国家..." v-model="filterText"></el-input>
                    <!-- <el-tree :data="countryList" :props="defaultProps" @node-click="handleNodeClick"></el-tree> -->
                    <div style="max-height: 76vh; overflow-y: scroll;" v-if="this.countryList.length > 0">
                        <el-tree
                        accordion
                        lazy
                        ref="tree"
                        highlight-current:true
                        :props="defaultProps"
                        :filter-node-method="filterNode"
                        :load="loadNode"
                        @node-click="handleNodeClick"></el-tree>
                    </div>
                </div>
                <div class="col-lg-9 ps-5 h-100">
                    <div class="match-container h-100">
                        <!-- &lt;&lt; 左侧选择比赛 -->
                        <div class="match-list" v-if="this.matchList.length > 0">
                            <h1 class="mb-0 text-center py-3 fs-18 border-bottom">{{ this.matchList[0].league }}</h1>
                            <div class="py-3 border-bottom" v-for="(item,index) in matchList" :key="index">
                                <b class="">{{ item.date }}</b>
                                <div class="d-flex justify-content-between my-3" v-for="(elm,i) in item.list" :key="i">
                                    <div class="d-flex w-75">
                                        <div class="">
                                            <span class="me-4">{{ elm.kot }}</span>
                                            <img :src="'https://www.teamform.com/image/team_logo/'+ elm.htid +'.png'" alt="" width="20" height="20">
                                            <span class="mx-2">{{ elm.h === undefined ? '态况无队名' : elm.h }}</span>
                                            <span class="mx-2">{{ elm.hrt }}</span>
                                        </div>
                                        <div class="mx-4 text-black-50"> VS </div>
                                        <div>
                                            <img :src="'https://www.teamform.com/image/team_logo/'+ elm.atid +'.png'" alt="" width="20" height="20">
                                            <span class="mx-2">{{ elm.a === undefined ? '态况无队名' : elm.a }}</span>
                                            <span class="mx-2">{{ elm.art }}</span>
                                        </div>
                                    </div>
                                    <div class="font-weight-bold qsdaColor">
                                        {{ elm.hrt*10-elm.art*10 }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="this.matchList.length == 0 && !this.isloading" class="nodata text-center d-flex flex-column justify-content-center h-100">
                            <span>暂无比赛数据</span>
                        </div>
                        <div v-if="this.isloading" class="text-center d-flex flex-column justify-content-center h-100">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--pc-->
        <div class="league-list position-fixed w-100 h-100 phone-menu" :class="{'menu-show':isShow}" v-if="isphone">
            <div class="position-relative">
                <el-input class="mb-1" placeholder="搜索国家..." v-model="filterText"></el-input>
                <button type="button" class="btn btn-light border close-menu text-muted fs-12 position-absolute" @click="closeMenu">关闭</button>
            </div>
            <el-tree v-if="this.countryList.length > 0"
                    accordion
                    lazy
                    ref="tree"
                    highlight-current:true
                    :props="defaultProps"
                    :filter-node-method="filterNode"
                    :load="loadNode"
                    @node-click="nodeClickPhone"></el-tree>
        </div>
        <div class="m-list container px-0">
            <div class="h-100">
                <div class="m-list-title d-flex justify-content-between border-bottom">
                    <button class="btn px-0" @click="openMenu">
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" :fill="isDark ? '#fff' : '#000'" class="bi bi-list" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                        </svg>
                    </button>
                    <h1 class="mb-0 py-3 fs-16" v-if="this.matchList.length > 0">{{ this.matchList[0].league }}</h1>
                    <h1 class="mb-0 py-3 fs-16 me-3" v-if="this.matchList.length == 0">选择联赛</h1>
                </div>
                <div v-if="this.matchList.length == 0 && !this.isloading" class="nodata text-center d-flex flex-column justify-content-center">
                    <span>暂无比赛数据</span>
                </div>
                <div v-if="this.isloading" class="text-center d-flex flex-column justify-content-center h-100">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div class="m-list-content pt-2" v-if="this.matchList.length > 0">
                    <div class="" v-for="(item,index) in matchList" :key="index">
                        <b class="d-block w-100 py-1 px-2 date-title">{{ item.date }}</b>
                        <div class="d-flex justify-content-between align-items-center py-3  border-bottom m-list-cell" v-for="(elm,i) in item.list" :key="i">
                            <div class="d-flex flex-column">
                                <span class="mb-2">{{ elm.kot }}</span>
                                <div class="mb-2">
                                    <img :src="'https://www.teamform.com/image/team_logo/'+ elm.htid +'.png'" alt="" width="22" height="22">
                                    <span class="mx-2">{{ elm.h === undefined ? '态况无队名' : elm.h }}</span>
                                    <span class="mx-2">{{ elm.hrt }}</span>
                                </div>
                                <div>
                                    <img :src="'https://www.teamform.com/image/team_logo/'+ elm.atid +'.png'" alt="" width="22" height="22">
                                    <span class="mx-2">{{ elm.a === undefined ? '态况无队名' : elm.a }}</span>
                                    <span class="mx-2">{{ elm.art }}</span>
                                </div>
                            </div>
                            <div class="font-weight-bold qsdaColor">
                                {{ elm.hrt*10-elm.art*10 }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Nav from './comment/nav.vue'
// import Footer from './comment/footer.vue'
// import country from '../data/country.json'
export default {
    inject: ['reload'],
    components: { Nav },
    watch: {
      filterText(val) {
        this.$refs.tree.filter(val);
      }
    },
    data() {
        return {
            isDark: false,
            isShow: false,
            countryList: [],
            matchList: [],
            tomorrowMatchList: [],
            defaultProps: {
                children: 'children',
                label: 'label',
                isLeaf: 'leaf'
            },
            filterText: '',
            isloading: false,
            isphone: false
        }
    },
    mounted(){
        this.getCountryList()
        this.isMobile()
        console.log(this.matchList);
        
    },
    methods:{
        getCountryList(){
            let country = []
            // const url = '/league/list';
            const url = 'https://api.qsda.net/api/league/list';
            fetch(url)
            .then(response => response.json())
            .then(res => {
                country = res.data
                // console.log(country);
                let arry = [];
                var leagueMap = {};
                for (let index = 0; index < country.length; index++) {
                    const countryName = country[index].c_name;
                    // const id = country[index].c_id;
                    if(leagueMap[country[index].c_name]) {
                        // 如果name已经出现过，则添加到对应的数组中
                        leagueMap[country[index].c_name].push(
                            {
                                label: country[index].l_name,
                                id: country[index].id,
                                leaf: true
                            }
                        );
                        // console.log(leagueMap);
                        
                    } else {
                        // 如果name没有出现过，那么在map中创建一个新的数组并添加
                        leagueMap[country[index].c_name] = [
                            {
                                label: country[index].l_name,
                                id: country[index].c_id,
                                leaf: true
                            }
                        ];
                        // console.log(leagueMap);
                        
                    }
                    const listCell = {
                        label: countryName,
                        children: [
                        // {label: '',id:''},
                        ]
                    }
                    arry.push(listCell)
                }
                
                var nameMap = {};
                this.countryList = arry.filter(function(item) {
                    if(nameMap[item.label]) {
                        // 如果name已经出现过，则剔除
                        return false;
                    } else {
                        // 如果name没有出现过，那么添加到map中并保留
                        nameMap[item.label] = true;
                        return true;
                    }
                });
                for (let index = 0; index < this.countryList.length; index++) {
                    if (leagueMap[this.countryList[index].label]) {
                        const childrenList = leagueMap[this.countryList[index].label];
                        this.countryList[index].children = childrenList
                    }
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
        },
        filterNode(value, data) {
          if (!value) return true;
          return data.label.indexOf(value) !== -1;
        },
        loadNode(node, resolve) {
            // console.log('6666666',node.data);
            // const id = node.data.id;
                
                if(node.level === 0){
                    this.getCountryList()
                    return resolve(this.countryList);
                }
                if(node.level === 1){
                    // console.log('cccccccc',node.data.children);
                    let leaguelist = node.data.children
                    return resolve(leaguelist);
                }
                if(node.level === 2){
                    resolve([]);
                }
        },
        handleNodeClick(data) {
            if (data.leaf == true) {
                this.isloading = true;
                this.matchList = [];
                console.log('id',data.id);const today = new Date();
                let tomorrow = today.getDate()+1;
                const year = today.getFullYear();
                let month = today.getMonth();
                let day = today.getDate();
                let tomorrowAfter = today.getDate()+2;
                month = month < 9 ? '0'+( month + 1) : month + 1;
                day = day < 10 ? '0' + day : day;
                tomorrow = tomorrow < 10 ? '0' + tomorrow : tomorrow;
                tomorrowAfter = tomorrowAfter < 10 ? '0' + tomorrowAfter : tomorrowAfter;
                const todayDate = year + '-' + month+'-' + day;
                const tomorrowDate = year + '-' + month+'-' + tomorrow;
                const tomorrowAfterDate = year + '-' + month+'-' + tomorrowAfter;
                console.log(todayDate,tomorrowDate,tomorrowAfterDate);
                const parmar = 'function=tf1&version=4.3&locale=CN&lang_code=zh_hans&tz_code=Asia/Shanghai&ot=D&date='+ todayDate +'&inc_comp_flag=N&inc_match_flag=Y&c_id=,'+ data.id +',&filter_fav=Y&sort_by_time=Y&filter_live=N&date_direction=future&last_m_id=&m_limit=6&t_id=,51310100';
                fetch(
                    '/tfws1/json',
                    {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept': '*/*',
                        'Accept-Language': 'zh-cn',
                    },
                    body:parmar
                    },
                ).then(response => response.json()).then(res => {
                    const dataList = res.tf1DList;
                    // console.log(dataList);
                    dataList.map((item)=>{
                        if(item.tf1CList[0].cid == data.id ){
                            console.log(item.tf1CList[0].tfMList);
                            const object = {
                                date: item.kod,
                                league: item.tf1CList[0].a + ' — ' + item.tf1CList[0].c, 
                                list: item.tf1CList[0].tfMList,
                            }
                            this.matchList.push(object);
                        }
                    });
                    this.isloading = false;
                    console.log(this.matchList);
                }).catch(error => console.error(error))
            }
        },
        openMenu(){
            this.isShow = !this.isShow;
            // console.log(this.isShow);
        },
        closeMenu(){
            this.isShow = false;
        },
        nodeClickPhone(data){
            if (data.leaf == true) {
                this.isShow = false;
                this.handleNodeClick(data)
            }
        },
        isMobile() {
            const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
            if (flag) {
                console.log('移动端打开');
                this.isphone = true;
            } else {
                console.log('PC端打开');
                this.isphone = false;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    @import '../assets/sass/global.scss';
    .container {
        height: 100%; padding-top: 66px;
        .nodata {color: #ccc; height: calc(100% - 52px);}
        .match-list {
            max-height: calc(76vh + 46px);
            overflow-y: scroll;
            padding-right: 10px;
        }
        .spinner-border {margin: 0 auto;}
    }
    .league-list, .m-list {display: none;}
    @media (max-width: 1024px) {
        .pc-list {display: none;}
        .m-list {display: block;}
        .m-list-title {padding: 0 15px;}
        .m-list-content {padding: 0 15px; height: calc(100% - 73px);}
        .m-list-cell:last-child {border: none!important;;}
        .league-list {
            background-color: #fff;
            display: block;
        }
        .phone-menu {
            // margin-top: 56px;
            padding: 56px 15px 15px 15px;
            z-index: 99;
            transition: all .3s ease 0s;
            right: 100%;
            overflow-y: scroll;
        }
        .menu-show {right: 0;}
        .menu-hide {right: -100%;}
        .close-menu {right: 0; top: 3px;}
        .date-title {background-color: #eee;}
        .NIGHT {
            .date-title {background-color: #333;}
        } 
        ::v-deep .el-input__inner{
            border: none;
            border-bottom: 1px solid #DCDFE6;
            border-radius: 0;
        }
    }
</style>