<template>
    <div class="position-relative" v-if="this.data.length>0 " v-loading="loading">
        <ux-grid
             ref="plxTable"
             stripe
            show-overflow
            use-virtual
             :data = data
             :highlightCurrentRow="true"
             :checkboxConfig="{highlight: true}"
             :edit-config="{trigger: 'click', mode: 'cell'}"
             show-header-overflow="ellipsis">
         <ux-table-column type="checkbox" :width="40"/>
         <ux-table-column type="index" :width="40"/>
         <ux-table-column :width="100" field="league" title="联赛" :filters="league" :filter-method="filterTag">
            <template v-slot="scope">
                <div class="text-white text-center" :style="{background: scope.row.color}">{{isnew ? scope.row.league : scope.row.name}}</div>
            </template>
            <!-- <template v-slot:edit="scope">
                <el-input v-model="scope.row.name"></el-input>
            </template> -->
         </ux-table-column>
         <ux-table-column :width="40" field="id" title="id"></ux-table-column>
         <!-- <ux-table-column :width="40" field="l_id" title="lid"></ux-table-column> -->
         <!-- <ux-table-column :width="80" field="homerank" title="排名1" edit-render>
            <template v-slot:edit="scope">
                <el-input v-model="scope.row.homerank"></el-input>
            </template>
         </ux-table-column>
         <ux-table-column field="home" title="主队" edit-render>
            <template v-slot:edit="scope">
                <el-input v-model="scope.row.home"></el-input>
            </template>
         </ux-table-column>
         <ux-table-column :width="70" field="qsda" title="qsda" edit-render>
            <template v-slot:edit="scope">
                <el-input v-model="scope.row.qsda"></el-input>
            </template>
         </ux-table-column>
         <ux-table-column field="away" title="客队" edit-render>
            <template v-slot:edit="scope">
                <el-input v-model="scope.row.away"></el-input>
            </template>
         </ux-table-column>
         <ux-table-column :width="80" field="awayrank" title="排名2" edit-render>
            <template v-slot:edit="scope">
                <el-input v-model="scope.row.awayrank"></el-input>
            </template>
         </ux-table-column>
         <ux-table-column :width="60" field="index1" title="指数1" edit-render>
            <template v-slot:edit="scope">
                <el-input v-model="scope.row.index1"></el-input>
            </template>
         </ux-table-column>
         <ux-table-column :width="80" field="handicap" title="Crow" edit-render>
            <template v-slot:edit="scope">
                <el-input v-model="scope.row.handicap"></el-input>
            </template>
         </ux-table-column>
         <ux-table-column :width="60" field="index2" title="指数2" edit-render>
            <template v-slot:edit="scope">
                <el-input v-model="scope.row.index2"></el-input>
            </template>
         </ux-table-column>
         <ux-table-column :width="60" field="big" title="大球" edit-render>
            <template v-slot:edit="scope">
                <el-input v-model="scope.row.big"></el-input>
            </template>
         </ux-table-column>
         <ux-table-column :width="60" field="bigsmall" title="大小" edit-render>
            <template v-slot:edit="scope">
                <el-input v-model="scope.row.bigsmall"></el-input>
            </template>
         </ux-table-column>
         <ux-table-column :width="60" field="small" title="小球" edit-render>
            <template v-slot:edit="scope">
                <el-input v-model="scope.row.small"></el-input>
            </template>
         </ux-table-column> -->
         <ux-table-column
                 v-for="item in columns.slice(1,8)"
                 :key="item.id"
                 :field="item.prop"
                 :title="item.label">
        </ux-table-column>
        <ux-table-column :width="80" field="handicap" title="Crow" edit-render>
            <template v-slot:edit="scope">
                <el-input v-model="scope.row.handicap"></el-input>
            </template>
         </ux-table-column>
         <ux-table-column
                 v-for="item in columns.slice(9,11)"
                 :key="item.id"
                 :field="item.prop"
                 :title="item.label">
        </ux-table-column>
        <ux-table-column :width="80" field="bigsmall" title="大小" edit-render>
            <template v-slot:edit="scope">
                <el-input v-model="scope.row.bigsmall"></el-input>
            </template>
         </ux-table-column>
         <ux-table-column field="big" title="大球">
         </ux-table-column>
        <ux-table-column field="m_id" title="m_id" edit-render>
            <template v-slot:edit="scope">
                <el-input v-model="scope.row.m_id"></el-input>
            </template>
         </ux-table-column>
         <!-- <ux-table-column title="详情" field="detail" :width="90" fixed="right">
            <template v-slot="scope">
                <a class="text-primary pointer" @click="getLiSan(scope.row)">离散</a>
                <router-link class="ms-1 text-primary" :to="'/charts/'+ scope.row.id" tag="a" target="_blank">查看</router-link>
                <a class="text-danger me-2 pointer" @click="removeSelected(scope.row)">删除</a>
                <a class="text-primary pointer" @click="insertEvent(scope.row)">插入</a>
            </template>
         </ux-table-column> -->
     </ux-grid>
     <div class="bottom-btn w-100 text-end mt-2">
         <el-button @click="saveChg">保存</el-button>
         <el-button @click="removeSel">删除选中</el-button>
         
     </div>
     <el-dialog title="提示" :top="'40vh'" :visible.sync="centerDialogVisible" width="30%" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" center>
        <div class="text-center" v-show="!this.showRes">
            <div class="d-flex justify-content-center align-items-center">
                <i class="el-icon-loading fs-18 text-primary"></i><span class="ms-1">执行中…</span>
            </div>
            <!-- <p class="fs-12 text-primary">第 {{this.progressNum}} 执行中...</p> -->
            <el-progress :percentage="this.progressNum"></el-progress>
        </div>
        <p class="mb-0 text-center" v-show="this.showRes">{{ this.delmsg }}</p>
        <span slot="footer" class="dialog-footer" v-show="this.showRes">
            <el-button type="primary" @click="reloadPage">确 定</el-button>
        </span>
    </el-dialog>
     <div class="" style="position: absolute; left: -10000px; bottom: 0;">
        <el-input
        id="copyText"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="请输入内容"
            v-model="textarea">
        </el-input>
     </div>
    </div>
</template>
<script>
// import FileSaver from 'file-saver'
// import jsonData from '@/data/tomorrow.json';
// import {changeLeagueName,changeTeamName} from '@/utils/change';
import { deleteMatchUrl } from '../constants/http.config'
export default {
    props: {
        matchdata: {
            type: Array,
            default: ()=>[]
        },
        dataType: {
            type: String,
            // default: ''
        }
    },
    data() {
        return {
            data: [],
            columns: [
                { label: '联赛', prop: 'league'},
                { label: '时间', prop: 'time'},
                { label: '主排名', prop: 'home_rank'},
                { label: '主队', prop: 'home'},
                {label: 'qsda', prop: 'qsda'},
                { label: '客队', prop: 'away'},
                { label: '客排名', prop: 'away_rank'},
                {label: '指数1', prop: 'index1'},
                {label: 'Crow', prop: 'handicap'},
                {label: '指数2', prop: 'index2'},
                {label: '小球', prop: 'small'},
                {label: '大小', prop: 'bigsmall'},
                {label: '大球', prop: 'big'},
                {label: 'id', prop: 'id'},
            ],
            textarea: '',
            league:[],
            fullscreenLoading: false,
            loading: false,
            centerDialogVisible: false,
            showRes: false,
            delmsg: '',
            progressNum: 0,
            isnew: false
        }
    },
    mounted() {
        // this.getData();
        this.data = this.matchdata;
        // console.log('ssss',this.dataType);
        if (this.data[0].league !== undefined) {
            this.isnew = true;
        }
        // console.log(this.data[0]);
        const leagueSet = new Set(this.data.map((item) => item.league));
        const leagueArray = Array.from(leagueSet);
        this.league = leagueArray.map((item) => ({ label: item, value: item }));
        // console.log(this.league);
        
    },
    methods: {
        removeSelected(row){
            // console.log(row);
            this.$refs.plxTable.remove(row)
        },
        async insertEvent (row) {
            // console.log(row);
            let record = {
                name: '联赛',
                time: '时间',
                home: '主队',
                qsda: 'qsda',
                away: '客队',
                handicap: 'Crow',
                bigsmall: '大小',
                color: '#000000',
                homerank: '[0]',
                awayrank: '[0]',
            }
            // 加入行, 表示加入行的row对象，row为空则插入第一行，为-1插入最后一行
            let { row: newRow } = await this.$refs.plxTable.insertRow(record, row)
            // 激活单元格编辑
            await this.$refs.plxTable.setActiveRow(newRow)
        },
        saveChg(){
            const selectData = this.$refs.plxTable.getCheckboxRecords();
            let apiUrl = '';
            if (this.dataType === 'today') {
                apiUrl = 'https://api.qsda.net/api/match/change_today';
                const chgData = this.$refs.plxTable.getTableData().tableData;
                chgData.map(obj => {
                    delete obj._XID;
                    return obj;
                });
                const postData = selectData.length === 0 ? chgData : selectData;
                fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(postData)
                })
                .then(response => response.json())
                .then(data => {
                    // console.log(data);
                    if (data.msg === 'success') {
                        //弹出成功提示
                        this.$alert('更新数据成功！', '', {
                            confirmButtonText: '确定',
                            callback: () => {
                                location.reload();
                            }
                        });
                    }else{
                        //弹出失败提示
                        this.$alert('更新数据失败！', '', {
                            confirmButtonText: '确定',
                        });
                    }
                    }).catch((error)=>{
                    console.log(error);
                    })
            }else{
                if (selectData.length == 0) {
                    this.$message({
                        showClose: true,
                        type: 'warning',
                        message: '请选中至少一条数据!'
                    });
                }else{
                    if (this.dataType === 'today-new') {
                        apiUrl = 'https://api.qsda.net/api/match/update_today';
                    }
                    if (this.dataType === 'tomorrow') {
                        apiUrl = 'https://api.qsda.net/api/match/change_tomorrow';
                    }
                    if (this.dataType === 'checkback') {
                        apiUrl = 'https://api.qsda.net/api/match/change_checkback';
                    }
                    if (this.dataType === 'checkback-tomorrow') {
                        apiUrl = 'https://api.qsda.net/api/match/change_checkback_tomorrow';
                    }
                    // console.log(apiUrl);
                    for (let index = 0; index < selectData.length; index++) {
                        const postData = selectData[index];
                        fetch(apiUrl, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(postData)
                        })
                        .then(response => response.json())
                        .then(data => {
                            // console.log(data);
                            if (data.msg === 'success') {
                                //弹出成功提示
                                this.$alert('更新数据成功！', '', {
                                    confirmButtonText: '确定',
                                    // callback: () => {
                                    //     location.reload();
                                    // }
                                });
                            }else{
                                //弹出失败提示
                                this.$alert('更新数据失败！', '', {
                                    confirmButtonText: '确定',
                                });
                            }
                            
                        });
                    }
                }
            // const chgData = this.$refs.plxTable.getTableData().tableData;
            }
            // console.log('ssdsdsd',postData);
        },
        reloadPage(){
            // this.centerDialogVisible = false;
            location.reload();
        },
        removeSel(){
            const res = this.$refs.plxTable.getCheckboxRecords();
            if (res.length === 0) {
                this.$message({
                    showClose: true,
                    type: 'warning',
                    message: '请选中至少一条数据!'
                });
            }else{
                //旧
                if (this.dataType === 'today') {
                    for (let index = 0; index < res.length; index++) {
                        const element = res[index];
                        this.$refs.plxTable.remove(element);
                    }
                }
                //新
                if (this.dataType === 'today-new' || this.dataType === 'tomorrow-new') {
                    this.centerDialogVisible = true
                    let successCount = 0;//成功数
                    let failCount = 0;//失败数
                    let completedCount = 0; // 用于跟踪已完成的请求数
                    const requests = [];
                    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
                    for (let index = 0; index < res.length; index++) {
                        const elm = res[index];
                        // console.log(elm.id);
                        const request = fetch(deleteMatchUrl + elm.id)
                        .then(response => response.json())
                        .then(data => {
                            if (data.msg == 'success') {
                                successCount ++;
                                this.$refs.plxTable.remove(elm);
                            }else{
                                failCount ++;
                            }
                        })
                        .catch(error => {
                            failCount = failCount + 1;
                            console.error('Error:', error);
                        })
                        .finally(async() => {
                            completedCount++;
                            // 更新进度条的值，向上取整
                            this.progressNum = Math.ceil((completedCount / res.length) * 100);
                            // console.log(this.progressNum);
                            // 如果总行数少于100，添加延迟
                            if (res.length < 100) {
                                await delay(500);
                            }else{
                                // if (index+1 > res.length-5) {
                                    await delay(100);
                                // }
                            }
                        });
                        requests.push(request);
                    }
                    Promise.all(requests).then(() => {
                        this.delmsg = `更新${successCount}条成功，${failCount}条失败`;
                        this.showRes = true;
                        // this.$notify.info({
                        //     title: '消息',
                        //     message: `更新${successCount}条成功，${failCount}条失败`
                        // });
                    }).catch(error => {
                        console.error('Error:', error);
                    });
                }
            }
        },
        filterTag({value, row, column}) {
            const property = column['property'];
            return row[property] === value;
        },
    },
}
</script>
<style lang="scss" scoped>
    @import '../assets/sass/global.scss';
    ::v-deep .elx-cell .col_102 {padding: 0;}
    ::v-deep .elx-cell .el-input__inner {padding: 0 5px;}
    ::v-deep .elx-cell .elx-cell--edit-icon {display: none;}
    ::v-deep .elx-cell .elx-cell--title {font-size: 12px;}
</style>