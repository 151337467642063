<template>
    <div class="container-fluid" style="height:calc(100vh - 75px)">
      <div class="form-div d-flex justy-content-center align-items-center">
        
      <el-form :model="ruleForm" status-icon ref="ruleForm" label-width="60px" class="demo-ruleForm">
        <p class="font-weight-bold text-primary mb-4" style="text-align: justify; text-align-last: justify; font-size: 32px;">足球广实</p>
          <el-form-item label="用户" prop="name">
              <el-input v-model="ruleForm.name" autocomplete="off" @input="canClick()"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="pass">
              <el-input type="password" v-model="ruleForm.pass" autocomplete="off" show-password @input="canClick()"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="validateCode">
              <el-input class="me-3" v-model="ruleForm.validateCode" style="width:100px" @input="canClick()"></el-input>
              <validate-code ref="ref_validateCode" @change="changeCode" @vcode="setcode"></validate-code>
          </el-form-item>
          <!-- <el-form-item>
              <el-checkbox label="记住密码" name="type"></el-checkbox>
          </el-form-item> -->
          <el-form-item>
              <el-button :disabled="this.disabled" type="primary" @click="submitForm()">登录</el-button>
              <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
      </el-form>
      <!-- <div class="text-danger">aaaaa</div> -->
      </div>
      <Footer></Footer>
    </div>
  </template>
  
  <script>
  import Footer from './comment/footer.vue'
  import ValidateCode from '@/components/validateCode.vue';
  import { saveLoginStatus } from '@/user/auth'
  import { loginUrl } from '../constants/http.config'
  // import { loginUrl } from '@/constants/http.config'
  // const axios = require('axios')
  import jwtDecode from 'jwt-decode';
  export default {
    components: { ValidateCode,Footer },
      data(){
        var validatePass = (rule, value, callback) => {
          if (value === '') {
            callback(new Error('请输入密码'));
          } else if(this.ruleForm.checkPass !== '') {
              this.$refs.ruleForm.validateField('checkPass');
            callback();
          } else{
              if (this.errorCode == true) {
                  callback(new Error('密码错误'));
              }
          }
        };
        var validatecode = (rule, value, callback) => {
          if (value === '') {
            callback(new Error('请输入验证码'));
          } else if (value.toLowerCase() !== this.checkCode) {
            callback(new Error('验证码不正确!'));
            this.checkCode = '';
            this.$refs['ref_validateCode'].draw();
          } else {
            callback();
          }
        };
        return {
          ruleForm: {
            name: '',
            pass: '',
            validateCode: ''
          },
          rules: {
            pass: [
              { validator: validatePass, trigger: 'blur' }
            ],
            validateCode: [
              { validator: validatecode, trigger: 'blur' }
            ],
          },
          checkCode:'',
          errorCode: false,
          disabled:true,
        };
      },
      mounted(){
      },
      updated(){
        
      },
      methods:{
          submitForm() {
            if (this.ruleForm.validateCode !== this.checkCode) {
              this.$message.error('验证码错误!');
            }else{
              fetch(loginUrl,{
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  editer_name: this.ruleForm.name,
                  password: this.ruleForm.pass,
                })
              }).then(response => response.json()).then((res)=>{
                  if (res.msg === 'success') {
                    const token = res.data.token;
                    const refreshtoken = res.data.refreshtoken;
                    console.log('登录成功!',jwtDecode(token));
                    // 登录成功后保存 token
                    saveLoginStatus(token,refreshtoken);
                    // this.$router.push('/date_list')
                    //判断有没有上一个路由,有则返回没则直接路由到首页
                    window.history.state.back ? this.$router.go(-1) : this.$router.push('/');
                  }else{
                    this.$message.error(res.msg)
                    console.log(res.msg);
                  }
              }).catch((error)=>{
                console.log(error);
                  console.log('登录失败',error);
                      this.errorCode = true
                      this.$message.error('用户名或密码错误!');
              })
            }
        },
        resetForm(formName) {
          this.$refs[formName].resetFields();
        },
        changeCode(value){
          this.checkCode = '';
          this.checkCode = value;
        },
        setcode(value){
          this.checkCode = value;
          // console.log(this.checkCode);
        },
        canClick(){
          if (this.ruleForm.name !='' && this.ruleForm.pass != '' && this.ruleForm.validateCode != '') {
            this.disabled = false;
          }else{
            this.disabled = true;
          }
        }
      },
  }
  </script>
  
  <style lang="scss" scoped>
      @import "../assets/sass/global.scss";
      .form-div{
          width: 316px; margin: 0 auto; height: 100%;
      }
      ::v-deep .el-form-item__content{ display: flex;}
  </style>