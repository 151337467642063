<template>
    <div class="container py-2" style="height: calc(100vh - 129px);">
        <Nav :isDark.sync="this.isDark" @darkmode="isDark = $event"></Nav>
        <h1 class="fs-24 text-center my-3">修改球队名</h1>
        <div>
            <div class="d-flex justify-content-center">
                <el-input placeholder="搜索态况队名..." v-model="teamName" class="input-with-select mb-3 w-25" clearable>
                    <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
                </el-input>
                <div class="ms-3">
                    <el-dropdown trigger="click" placement="top-start" class="mb-3" @command="handleCommand">
                        <el-input placeholder="搜索联赛名..." v-model="leagueName" class="input-with-select" @input="searchLeague" clearable>
                        </el-input>
                        <el-dropdown-menu slot="dropdown" class="dropdown-menu-content">
                            <el-dropdown-item v-for="(item,index) in countries" :key="index" :command="item" v-show="hasFilterRes[index]">{{ filterLeagueName(item.value) }}</el-dropdown-item>
                            <el-dropdown-item v-show="noRes">无搜索结果</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <!-- <el-button type="info" icon="el-icon-search" plain></el-button> -->
                </div>
            </div>
            <div>
            <!-- <div v-for="(item,index) in countries" :key="index" class="d-inline mx-1">
                <el-tag type="info">{{ item.value }}</el-tag>
            </div> -->
            </div>
            <ux-grid
             ref="plxTable"
             stripe
             show-overflow
             use-virtual
             :data = data
             :highlightCurrentRow="true"
             :checkboxConfig="{highlight: true}"
             :edit-config="{trigger: 'click', mode: 'cell'}"
             show-header-overflow="ellipsis">
             <ux-table-column type="checkbox" :width="40"/>
             <!-- <ux-table-column type="index" :width="60"/> -->
             <ux-table-column field="id" title="id" edit-render>
                <template v-slot:edit="scope">
                    <el-input v-model="scope.row.id"></el-input>
                </template>
             </ux-table-column>
             <ux-table-column field="l_id" title="球队id" edit-render>
                <template v-slot:edit="scope">
                    <el-input v-model="scope.row.l_id"></el-input>
                </template>
             </ux-table-column>
             <!-- <ux-table-column field="league" title="联赛/国家" edit-render :width="120">
                <template v-slot:edit="scope">
                    <el-input v-model="scope.row.league"></el-input>
                </template>
            </ux-table-column> -->
             <ux-table-column field="name_tk" title="态况球队名" edit-render>
                <template v-slot:edit="scope">
                    <el-input v-model="scope.row.name_tk"></el-input>
                </template>
             </ux-table-column>
             <ux-table-column field="name" title="捷报队名" edit-render>
                <template v-slot:edit="scope">
                    <el-input v-model="scope.row.name"></el-input>
                </template>
             </ux-table-column>
             <ux-table-column title="操作" field="detail" :width="90" fixed="right">
                <template v-slot="scope">
                    <!-- <a class="text-primary pointer me-2" @click="insertEvent(scope.row)">插入</a> -->
                    <a class="text-primary pointer" @click="saveChg(scope.row)">保存</a>
                </template>
             </ux-table-column>
            </ux-grid>
        </div>
        <div class="bottom-btn w-100 text-end py-2">
         <el-button @click="saveSelectedChg">保存修改</el-button>
         <!-- <el-button @click="removeSel">删除选中</el-button> -->
     </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Nav from '../comment/nav.vue'
import Footer from '../comment/footer.vue'
import { searchTimByLidUrl, leagueListUrl, updateTeamUrl, searchTimByNameUrl } from '../../constants/http.config'
export default {
    components: { Nav,Footer },
    data(){
        return {
            isDark: false,
            noRes: false,
            teamName: '',
            leagueName: '',
            leagueID: '',
            data: [],
            columns: [
                { label: '球队id', prop: 'id'},
                // { label: '联赛/国家', prop: 'league'},
                { label: '态况球队名', prop: 'name_tk'},
                { label: '捷报队名', prop: 'name'}
            ],
            countries: [],
            hasFilterRes: [],
            loading: false
        }
    },
    mounted() {
        // this.getTeamList();
        this.getLeagueList();
    },
    methods: {
        getLeagueList(){
            // const url = '/league/list'
            fetch(leagueListUrl)
            .then(response => response.json())
            .then(res => {
                // console.log(res.data);
                // this.data = res.data;
                let countriesList = res.data.map((item)=>({id: item.id, value: '' == item.s_name ? item.c_name+item.l_name :item.s_name}));
                this.countries = Array.from(new Set(countriesList.map(JSON.stringify))).map(JSON.parse);
                if (this.hasFilterRes.length == 0) {
                    for (let index = 0; index < this.countries.length; index++) {
                        this.hasFilterRes.push(true)
                    }                    
                }
                // console.log(this.countries,this.hasFilterRes);
            })
            .catch(error => console.error('Error:', error));
        },
        filterCountry({value, row, column}){
            const property = column['property'];
                return row[property] === value;
        },
        filterLeagueName(name){
            const hasFenGe = name.indexOf('/');
            if (hasFenGe !== -1) {
                return name.slice(0,hasFenGe);
            }else{
                return name;
            }
        },
        removeSelected(row){
            // console.log(row);
            this.$refs.plxTable.remove(row)
        },
        async insertEvent (row) {
            // console.log(row);
            let record = {
                // c_id: 'id',
                league: '国家/联赛',
                name: '态况队名',
                rename: '捷报队名',
            }
            // 加入行, 表示加入行的row对象，row为空则插入第一行，为-1插入最后一行
            let { row: newRow } = await this.$refs.plxTable.insertRow(record, row)
            // 激活单元格编辑
            await this.$refs.plxTable.setActiveRow(newRow)
        },
        saveChg(row){
            const updateData = {
                'id': row.id,
                'l_id': row.l_id,
                'name': row.name,
                'name_tk': row.name_tk
            }
            // console.log('row',row,updateData);
            // const url = '/team/update'
            fetch(updateTeamUrl,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updateData)
            })
            .then(response => response.json())
            .then(data => {
                // console.log(data);
                if (data.msg == 'success') {
                    this.$notify({
                        title: '成功',
                        message: '球队信息更新成功！',
                        type: 'success'
                    });
                }else{
                    this.$notify.error({
                        title: '错误',
                        message: '更新失败请重试！'
                    });
                }
            }).catch(error => {
                console.error('Error:', error);
            });
        },
        saveSelectedChg(){
            // const chgData = this.$refs.plxTable.getTableData().tableData;
            const selectedTeams = this.$refs.plxTable.getCheckboxRecords();
            if (selectedTeams.length === 0) {
                this.$message({
                    showClose: true,
                    type: 'warning',
                    message: '请选中至少一条数据!'
                });
            } else {
                let countSuccess = 0;
                let countFail = 0;
                const requests = [];
                for (let index = 0; index < selectedTeams.length; index++) {
                    const updateData = selectedTeams[index];
                    // const url = '/team/update';
                    const request = fetch(updateTeamUrl,{
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(updateData)
                    })
                    .then(response => response.json())
                    .then(data => {
                        // console.log(data);
                        if (data.msg == 'success') {
                            countSuccess++;
                        }else{
                            countFail++;
                        }
                    }).catch(error => {
                        console.error('Error:', error);
                    });
                    requests.push(request);
                }
                Promise.all(requests).then(() => {
                    this.$notify.info({
                        title: '消息',
                        message: `更新${countSuccess}条成功，${countFail}条失败`
                    });
                }).catch(error => {
                    console.error('Error:', error);
                });
            }
        },
        search(){
            if (this.teamName !== '') {
                // console.log(this.teamName);
                // const url = '/team/search?name='
                fetch(searchTimByNameUrl + this.teamName)
                .then(response => response.json())
                .then(res => {
                    // console.log(res.data);
                    this.data = res.data;
                })
                .catch(error => {
                    console.error('Error:', error);
                })
            }else{
                this.$message.error('搜索名不能为空!');
                
            }
        },
        searchLeague(){
            for (let index = 0; index < this.countries.length; index++) {
                const elm = this.countries[index];
                if (this.leagueName == '') {
                    this.hasFilterRes[index] = true;
                }
                if (elm.value.indexOf(this.leagueName) == -1) {
                    this.hasFilterRes[index] = false;
                }
            }
        },
        handleCommand(command){
            // this.$message('click on item ' + command.value);
            this.leagueName = command.value;
            // console.log(command.id);
            this.getTeamByLeagueId(command.id);
        },
        getTeamByLeagueId(id){
            // const url = '/team/search-by-lid?id=';
            fetch(searchTimByLidUrl + id)
            .then(response => response.json())
            .then(data => {
                // console.log(data);
                this.data = data.data;
            })
            .catch(error => console.error('Error:', error));
        }
    }
}
</script>
<style lang="scss" scoped>
    @import '../../assets/sass/global.scss';
    .container {margin-top: 56px;}
    .dropdown-menu-content {height: 400px; overflow-y: scroll; margin-top: 5px!important;}
</style>