<template>
    <div v-loading="loading">
    <div class="container py-4" :style="{height: this.tomorrowData.length === 0 ? 'calc(100vh - 75px)' : 'auto'}">
        <Nav :isDark.sync="this.isDark" @darkmode="isDark = $event"></Nav>
        <h1 class="fs-24 font-weight-bold text-center mb-3 mt-5">明日数据获取</h1>
        <div>
            <h4 class="fs-16 font-weight-bold">要抓的联赛（必须提前一天，不然抓不到）:</h4>
            <el-input class="league_textarea" type="textarea" v-model="league" :placeholder="this.placeholder"></el-input>
            <div class="d-flex justify-content-between mt-3">
                <div class="text-danger fs-12">
                    {{ this.getDataRes }}
                </div>
                <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="getMatch">立即抓取</el-button>
            </div>
        </div>
        <div v-if="this.tomorrowData.length === 0" class="text-center text-secondary h-50 d-flex align-items-center justify-content-center bg-light mt-2 rounded">- 暂无数据 -</div>
        <div v-if="this.tomorrowData.length > 0">
            <Edite class="mt-2" :matchdata="this.tomorrowData" :dataType="'tomorrow'"></Edite>
        </div>
        
    </div>
    <Footer></Footer>
    </div>
    <!-- <div class="container py-4">
        <h1 class="fs-24 font-weight-bold text-center mb-3">获取比赛信息</h1>
        
        <div class="text-center mt-4">
            <el-select v-model="chooseValue" placeholder="请选择日期" @change="selectRefs()">
                <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                </el-option>
            </el-select>
            <el-button type="primary ms-2" @click="getMatch">获取比赛</el-button>
        </div>
        <div>
            <el-input class="league_textarea" type="textarea" v-model="league"></el-input> 
            <div v-if="this.chooseValue == '2'">
            <h4 class="fs-16 font-weight-bold">选择要抓的联赛:</h4>
                <el-empty v-if="this.leagueList.length == 0" description="暂无联赛列表，请点击“获取联赛”按钮">
                    <el-button type="primary" @click="getLeagueArry">获取联赛</el-button>
                </el-empty>
            </div>
        </div>
    </div>-->
</template>
<script>
import Edite from './edite.vue';
import Nav from './comment/nav.vue'
import Footer from './comment/footer.vue'
import TokenBucket from '../utils/tokenBucket';
import { crawlerLeagueUrl } from '../constants/http.config'
// import {changeLeagueName,changeTeamName} from '@/utils/change';
export default {
    components: { Edite, Nav,Footer },
    inject: ['reload'],
    data() {
        return {
            bucket: new TokenBucket(1, 10),
            league: '',
            chooseValue: '',
            placeholder: '填要采的比赛，联赛用英文逗号隔开，格式：英超,意甲,德甲,法甲,...',
            fullscreenLoading: false,
            options: [
                        {value:'1',label:'今日比赛'},
                        {value:'2',label:'明日比赛'},
                    ],
            leagueList: [],
            getDataRes: '',
            tomorrowData: [],
            dateTitle: '',
            isDark: false,
            loading: true
        }
    },
    mounted() {
        this.validatorData();
    },
    methods: {
        getLeagueArry(){
            fetch(crawlerLeagueUrl,).then(response => response.json())
                .then(data => console.log(data));
        },
        dateFormatted (date) {
            // const date = new Date();
            const year = date.getFullYear();
            let month = date.getMonth();
            let day = date.getDate();
            month = month < 9 ? '0'+( month + 1) : month + 1;
            day = day < 10 ? '0' + day : day;
            return year + '-' + month+'-' + day;
        },
        validatorData(){
            fetch('https://api.qsda.net/api/match/tomorrow')
            .then(response => response.json())
            .then(data => {
                this.loading = false;
                const today = new Date();
                const targetTime = new Date().setHours(10, 30, 0, 0);
                console.log(today.getTime() > targetTime);
                const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)
                console.log(data.data, this.dateFormatted(tomorrow));
                //日期是当日并时间已过10:30
                if (data.data[0].date == this.dateFormatted(tomorrow) && today.getTime() > targetTime){
                    console.log('日期相同');
                    this.dateTitle = data.data[0].date;
                    this.tomorrowData = data.data
                }else{
                    console.log('日期不相同');
                }
            }).catch(err=>{
                this.loading = false;
                console.log(err);
            })
        },
        getMatch() {
            if (this.league == '') {
                this.$message.error('请输入需要抓的联赛!');
                return;
            }
            const leagueArry = this.league.split(',');
            let leagueData = [];
            for (let index = 0; index < leagueArry.length; index++) {
                const league = leagueArry[index];
                leagueData.push(
                    {'name':league }
                )
            }
            console.log(leagueData);
            const now = Date.now();
            const lastRequestTime = localStorage.getItem('lastRequestTime');
            if (lastRequestTime && now - lastRequestTime < 60000) {
                console.log('Request rejected');
                alert('操作太频繁，请休息一下再试');
                return;
            } else {
                localStorage.setItem('lastRequestTime', now);
                this.fullscreenLoading = true;
                console.log('Request accepted');
                // 在这里发送请求
                fetch('https://api.qsda.net/api/crawler/tomorrow',{
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(leagueData)
                }).then(response => response.json())
                .then(data => {
                    console.log(data);
                    this.fullscreenLoading = false; 
                    if (data.msg == 'success') {
                        this.getDataRes = '抓取成功,' + data.data;
                        this.validatorData();
                    } else {
                        this.getDataRes = '抓取失败，请检查格式是否正确！';
                    }
                }).catch((error) => {
                    console.log(error)
                    this.fullscreenLoading = false;
                    this.getDataRes = '抓取失败！';
                });
            }
        },
        selectRefs(){
            console.log(this.chooseValue);
        },
        sendRequest() {
            const now = Date.now();
            const lastRequestTime = localStorage.getItem('lastRequestTime');
            if (lastRequestTime && now - lastRequestTime < 60000) {
                console.log('Request rejected');
                alert('操作太频繁，请休息一下再试')
                // return false;
            } else {
                localStorage.setItem('lastRequestTime', now);
                console.log('Request accepted');
                // 在这里发送请求
                // return true;
            }
        }
    },
}
</script>
<style lang="scss" scoped>
    @import '../assets/sass/global.scss';
    ::v-deep .el-textarea textarea { min-height: 100px!important;}
</style>