<template>
    <div>
    <div class="container py-2" v-loading="loading" :style="{height: loading || this.todayData.length === 0 ? 'calc(100vh - 129px)' : 'auto'}">
        <Nav :isDark.sync="this.isDark" @darkmode="isDark = $event"></Nav>
            <h1 class="fs-24 text-center mt-2">明日回查数据获取</h1>
            <span class="fs-14 text-center text-muted d-block mb-3">注意：抓明日回查数据前，需要先把明日的比赛抓出来再来这抓回查，不然会抓到旧的回查数据！</span>
            <div v-if="this.todayData.length === 0 && !loading" class="text-center text-secondary h-75 d-flex align-items-center justify-content-center flex-column mt-2 rounded mb-4">
                <div class="d-flex align-items-center mb-3">
                    <span class="me-3">暂无明日回查数据，请点击“获取回查”按钮</span>
                <!-- <el-button type="text" class="fs-12" v-if="isnew" @click="switchButton">返回旧版>></el-button> -->
                <el-button type="text" class="fs-12 ms-0" v-if="!isnew" @click="switchButton">使用新版>></el-button>
                </div>
                
                <!-- <el-button type="primary" v-if="!isnew" v-loading.fullscreen.lock="fullscreenLoading" @click="getMatch()">获取回查(旧)</el-button> -->
                <el-button type="primary" v-if="isnew" v-loading.fullscreen.lock="fullscreenLoading" @click="getMatch()">获取回查(新)</el-button>
            </div>
        <div v-if="this.todayData.length > 1 && !loading">
            <!-- <el-button class="mb-3" type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="getMatch()">重新抓取</el-button> -->
            <div class="d-flex justify-content-between">
                <b>{{ this.dateTitle }}</b>
                <div>
                    <el-button type="text" @click="reCrawler()" class="fs-12 text-end text-primary">不满意？重新抓取比赛>></el-button>
                    <!-- <el-button type="text" v-if="isnew" @click="switchNewOrOld('old')" class="fs-12 text-end text-primary">切换旧版>></el-button> -->
                    <!-- <el-button type="text" v-if="!isnew" @click="switchNewOrOld()" class="fs-12 text-end text-primary">切换新版>></el-button> -->
                </div>
            </div>
            <Edite class="mt-2" :matchdata="this.todayData" :dataType="'checkback-tomorrow'"></Edite>
        </div>
    </div>
    <Footer></Footer>
    </div>
</template>
<script>
import Edite from './edite.vue';
import Nav from './comment/nav.vue'
import Footer from './comment/footer.vue'
import { dateFormatted } from '../utils/utils'
import { checkBackListTomorrowUrl,checkbackCrawler } from '../constants/http.config'
export default {
    components: { Edite, Nav,Footer },
    inject: ['reload'],
    data() {
        return {
            loading: true,
            fullscreenLoading: false,
            tips: '',
            todayData: [],
            dateTitle: '',
            isDark: false,
            isnew: true
        }
    },
    mounted() {
        this.validatorData();
        // this.loading = false;
    },
    methods: {
        dateFormatted,
        switchButton(){
            this.isnew = !this.isnew
        },
        switchNewOrOld(type){
            this.loading = true;
            this.todayData = [];
            this.validatorData(type)
        },
        validatorData(type){
            console.log('验证数据');
            // const url = 'https://api.qsda.net/api/match/checkback-t';
            // const url = 'http://localhost:8888/api/match/checkback';
            let url = '';
            if (type == 'old') {
                url = checkBackListTomorrowUrl;
                this.isnew = false;
            }else{
                url = checkBackListTomorrowUrl;
                this.isnew = true;
            }
            fetch(url, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => response.json())
            .then(data => {
                this.loading = false;
                const todayData = new Date();
                console.log(data.data[0].date, this.dateFormatted(todayData));
                // if (data.data[0].date == this.dateFormatted(todayData)){
                //     console.log('日期相同');
                    this.dateTitle = data.data[0].date;
                    this.todayData = data.data;
                // }else{
                //     console.log('日期不相同');
                // }
            }).catch(err=>{
                this.loading = false;
                console.log('读取数据出错');
                console.log(err);
            })
        },
        getMatch(){
            this.fullscreenLoading = true;            
            let date = new Date();
            date.setDate(date.getDate() + 1);
            const tomorrowDate = this.dateFormatted(date)
            const url = checkbackCrawler + tomorrowDate;
            // const url = 'http://localhost:8888/api/crawler/checkback';
            fetch(url,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(data => {
                this.fullscreenLoading = false;
                if (data.code == 200) {
                    this.$notify({
                        title: '抓取成功',
                        message: data.data,
                        type: 'success',    
                        // duration: 0
                    });
                    this.validatorData();
                } else {
                    this.tips = '抓取失败，请检查格式是否正确！';
                    this.$notify.error({
                        title: '抓取失败',
                        message: '未知错误，请重试',
                        // duration: 0
                    });
                }
                console.log(data);
            }).catch(err=>{
                this.fullscreenLoading = false;
                this.$notify.error({
                        title: '抓取失败',
                        message: '连接超时',
                        // duration: 0
                    });
                console.error(err);
            })
        },
        reCrawler(){
            this.todayData = [];
        },
    }
}
</script>
<style lang="scss" scoped>
    @import '../assets/sass/global.scss';
    .container {margin-top: 56px;}
</style>