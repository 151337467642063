<template>
    <div>
        <Nav :isDark.sync="this.isDark" @darkmode="isDark = $event"></Nav>
        <div class="container" style="height:calc(100vh - 75px); padding-top: 95px;">
            <div class="row">
                <!-- <div class="col-xl-6">
                    <h4 class="mb-3 fs-16">历史赛程</h4>
                    <ul style="padding-left: 20px;">
                        <li v-for="(item,index) in dateList" :key="index">
                            <span class="text-decoration-none d-inline-block py-1">{{item}}</span>
                            <router-link class="text-decoration-none d-inline-block py-1 ms-4 ps-4 text-primary" :to="'/qsda-today/'+item" tag="a" target="_blank">电脑端>> </router-link>
                            <router-link class="text-decoration-none d-inline-block py-1 ms-2 ps-2 text-primary" :to="'/qsda-today-m/'+item" tag="a" target="_blank">手机端>> </router-link>
                        </li>
                    </ul>
                    <el-pagination background layout="prev, pager, next" :total="this.total" :current-page.sync="page" @current-change="handleCurrentChange"></el-pagination>
                </div> -->
                <div class="col-xl-6">
                    <h4 class="mb-3 fs-16">历史赛程</h4>
                    <el-calendar v-model="value">
                        <template
                            slot="dateCell"
                            slot-scope="{date, data}">
                                <router-link class="text-decoration-none d-block date-link" :class="data.isSelected ? 'is-selected' : ''" :to="'/qsda-today-new/'+data.day" target="_blank">{{ data.day.split('-').slice(2).join('-') }}</router-link>
                        </template>
                    </el-calendar>
                    <!-- <div class="my-2">
                        <router-link :to="'/qsda-today'">电脑端</router-link>
                    </div>
                    <div class="my-2">
                        <router-link :to="'/qsda-today-m'" class="my-2">手机端</router-link>
                    </div>
                    <div class="my-2">
                        <router-link :to="'/checkback'" class="my-2">大数据回查</router-link>
                    </div>
                    <div class="my-2">
                        <router-link :to="'/tomorrow'" class="my-2">抓取数据（明日）</router-link>
                    </div>
                    <div class="my-2">
                        <router-link :to="'/today'" class="my-2">抓取数据（今日）</router-link>
                    </div> -->
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Nav from './comment/nav.vue'
import Footer from './comment/footer.vue'
import { dateFormatted } from '../utils/utils'
export default {
    components: { Nav,Footer },
    inject: ['reload'],
    data() {
        return {
            isDark: false,
            dateList: [],
            page: 1,
            totalPage: 1,
            total: 1,
            value: new Date(),
            selectedDate: ''
        }
    },
    mounted() {
        this.getDateList(1)
        console.log(this.value);
        
    },
    methods: {
        dateFormatted,
        getDateList(page) {
            fetch(`https://api.qsda.net/api/match/date-list?page=${page}&pagesize=10`).then(response => response.json())
            .then(res => {
                this.dateList = res.data.list
                this.totalPage = res.data.totalPage
                this.total = res.data.total
                console.log(res.data);
            })
        },
        handleCurrentChange(){
            // console.log('qqqq',this.page);
            this.getDateList(this.page)
        },
        getDate(){
            this.selectedDate = this.dateFormatted(this.value);
            return this.dateFormatted(this.value)
        }
    },
}
</script>
<style lang="scss" scoped>
    @import '../assets/sass/global.scss';
    ::v-deep .el-calendar-table .el-calendar-day {
        padding: 8px;
        height: 45px;
    }
    ::v-deep .el-calendar-table td.is-selected {
        background-color: #deeefe;
    }
    .date-link { line-height: 29px; }
</style>