<template>
    <div>
        <div class="container py-2" v-loading="loading" :style="{height: loading || this.list.length === 0 ? 'calc(100vh - 129px)' : 'auto'}">
            <Nav :isDark.sync="this.isDark" @darkmode="isDark = $event"></Nav>
            <div class="mt-2 my-4">
                <h1 class="fs-20 text-center">{{this.dateTitle}}</h1>
                <table :class="{'table-dark': this.isDark}" class="table table-striped border" ref="table" v-if="this.list.length !== 0" style="width: 950px;margin: 0 auto;">
                <thead class="fs-12" style="background-color: #82959F!important;">
                    <tr class="font-weight-normal py-0 text-white">
                        <th class="border-end font-weight-normal py-1" width='40'>#</th>
                        <th class="border-end text-center font-weight-normal py-1">联赛</th>
                        <th class="border-end text-center font-weight-normal py-1" width='80'>时间</th>
                        <th class="border-end text-end font-weight-normal py-1">主队</th>
                        <th class="border-end text-center font-weight-normal py-1" width='80'>qsda</th>
                        <th class="border-end text-left font-weight-normal py-1">客队</th>
                        <th class='border-end text-center font-weight-normal py-1'>Crow*</th>
                        <th class='border-end text-center font-weight-normal py-1'>大小</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in list" :key="index">
                        <td class="border-end align-middle" width='40'>{{index+1}}</td>
                        <td :style="{'background':item.color}" class='border-end td2 text-white align-middle text-center py-0' width='100'>{{ item.league }}</td>
                        <td class='border-end text-center align-middle' width='80'>{{item.time}}</td>
                        <td class='border-end text-end align-middle'>
                            <font>{{ item.home_rank }}</font>
                            {{ item.home }}
                        </td>
                        <td class='text-center font-weight-bold border-end align-middle qsda qsdaColor' width='80' :style="{color: qsdaColorSet(item.handicap,item.qsda)}"><a :href="'https://zq.titan007.com/analysis/'+item.id+'cn.htm'" target='_blank' class="text-decoration-none">{{ item.qsda }}</a>
                        </td>
                        <td class='border-end text-left align-middle'>
                            {{ item.away }}
                            <font>{{ item.away_rank }}</font>
                        </td>
                        <td class='border-end text-center' width="120">
                            <router-link style='color:#880000; text-decoration: none;' :to="{ path: '/checkback-list-detail/'+item.m_id,query: {qsda:item.qsda,handicap:item.handicap,bigsmall:item.bigsmall,color:item.color,league:item.league,time:item.time,home:item.home,away: item.away}}" target='_blank'>{{ item.handicap }}</router-link>
                        </td>
                        <td class='border-end text-center' width="80">
                            <div>{{ item.bigsmall }}</div>
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>
            <Footer></Footer>
        </div>
    </div>
</template>
<script>
import Nav from './comment/nav.vue'
import Footer from './comment/footer.vue'
import {setcolor } from '@/utils/setcolor';
import { handicapToNum, convertToReal, compare } from '../utils/utils'
import { checkbackListUrl } from '../constants/http.config'
export default {
    components: { Nav,Footer },
    inject: ['reload'],
    data() {
        return {
            loading: true,
            fullscreenLoading: false,
            isDark: false,
            list: [],
            dateTitle: ''
        }
    },
    mounted() {
        this.getMatch()
    },
    methods: {
        handicapToNum,
        convertToReal,
        compare,
        // callSetColor(color,league){
        //     return setcolor(color,league);
        // },
        getMatch(){
            this.fullscreenLoading = true;
            // const url = 'https://api.qsda.net/api/match/checkback';
            fetch(checkbackListUrl, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => response.json())
            .then(data => {
                this.loading = false;
                // const todayData = new Date();
                // console.log(data.data);
                // if (data.data[0].date == this.dateFormatted(todayData)){
                //     console.log('日期相同');
                    this.dateTitle = data.data[0].date;
                    this.list = data.data;
                // }else{
                //     console.log('日期不相同');
                // }
            }).catch(err=>{
                this.loading = false;
                console.log('读取数据出错');
                console.log(err);
            })
        },
        qsdaColorSet(handicap,qsda){
            let qsdaColor = 'rgb(128,0,255)';
            let lowOrHight = 2;
            if (handicap!=='') {
                const handicapNum = handicapToNum(handicap);
                const realHandicapNum = convertToReal(qsda);
                if (handicapNum === 0) {
                    qsdaColor = 'rgb(128,0,255)';
                    return qsdaColor
                }else{
                    if (typeof handicapNum !== 'undefined' && typeof realHandicapNum !== 'undefined') {
                            lowOrHight = compare(handicapNum, realHandicapNum);
                        if(lowOrHight === 0){
                            qsdaColor = '#666';
                            return qsdaColor
                        }
                        if (lowOrHight === 1) {
                            qsdaColor = 'red';
                            return qsdaColor
                        }
                        if (lowOrHight === -1) {
                            qsdaColor = 'green';
                            return qsdaColor
                        }
                    }
                }
            }
        },
    },
}
</script>
<style lang="scss" scoped>
    @import '../assets/sass/global.scss';
    .container {margin-top: 56px;}
</style>