<template>
    <div :style="{height: this.tableData.length === 0 ? '100vh' : 'auto'}">
    <div :class="this.isphone ? 'container-fluid' : 'container'" :style="{height: this.tableData.length === 0 ? 'calc(100vh - 73px)' : 'auto'}">
        <Nav :isDark.sync="this.isDark" @darkmode="isDark = $event"></Nav>
        <el-backtop></el-backtop>
        <div class="d-flex justify-content-between align-items-center" :style="{width: this.isphone ? '100%' : '1080px',margin: '0 auto'}">
            <span class="fs-4">{{ this.dateStr }}</span>
            <div>
                <!-- <el-button type="text" class="p-0 fs-14 text-primary" @click="jingzu">竞足</el-button> -->
                <!-- <el-button type="text" class="p-0 fs-14 text-primary me-3" @click="sortedTime">时间排序>></el-button> -->
                <router-link :to="'/qsda-tomorrow-new'" class="text-primary fs-14" style="cursor: pointer;">查看明日比赛>></router-link>
            </div>
        </div>
        <div class="d-flex justify-content-center" :class="{'h-100':this.tableData.length === 0}">
            <!-- <div class="mb-4 text-center" :class="{'h-100':this.tableData.length === 0}" v-if="this.tableData.length === 0 && !loading">
                <div class="nodata d-flex flex-column justify-content-center" :class="{'h-100':this.tableData.length === 0}">
                    <span class="align-center">暂无比赛数据</span>
                </div>
            </div> -->
            <div :style="{width: this.isphone ? '100%' : '1080px'}">
            <ux-grid
             ref="table"
             stripe
             show-overflow
             use-virtual
             :widthResize = 'true'
             :empty-text = this.emptyText
             :data = tableData
             :highlightCurrentRow="true"
             :row-class-name = "tableRowClassName"
             show-header-overflow="ellipsis">
                <!-- <ux-table-column type="checkbox" :width="40"/> -->
                <ux-table-column type="index" :width="40"/>
                <ux-table-column :width="100" field="league" title="联赛" :filters="filters" :filter-method="filterTag">
                    <template v-slot="scope">
                        <div class="text-white text-center" :style="{background: scope.row.color, padding: '0 8px'}">{{scope.row.league}}</div>
                    </template>
                </ux-table-column>
                <ux-table-column :width="60" field="time" title="时间"></ux-table-column>
                <ux-table-column field="home" title="球队" :width="this.isphone ? 150 : ''">
                    <template v-slot="scope">
                        <div class="d-flex flex-column">
                            <div>
                                {{ scope.row.home }}
                                <sup><font>{{ scope.row.home_rank }}</font></sup>
                            </div>
                            <div>
                                {{ scope.row.away }}
                                <sup><font>{{ scope.row.away_rank }}</font></sup>
                            </div>
                        </div>
                    </template>
                </ux-table-column>
                <ux-table-column :width="80" field="qsda" title="qsda">
                    <template v-slot="scope">
                        <a :href="'https://zq.titan007.com/analysis/'+scope.row.m_id+'cn.htm'" target='_blank' class="text-decoration-none font-weight-bold">
                            <span class="qsda qsdaColor" :style="{color: qsdaColorSet(scope.row.handicap,scope.row.qsda)}">{{ scope.row.qsda }}</span>
                        </a>
                    </template>
                </ux-table-column>
                <ux-table-column :width="100" field="handicap" title="让分">
                    <template v-slot="scope">
                        <div class="d-flex">
                            <span>{{ scope.row.handicap }}</span>
                            <div class="d-flex flex-column ms-2">
                                <span style='color:#880000;'>{{ scope.row.h_index }}</span>
                                <span style='color:#880000;'>{{ scope.row.a_index }}</span>
                            </div>
                            <!-- <span>{{ scope.row.bigsmall }}</span> -->
                        </div>
                    </template>
                </ux-table-column>
                <ux-table-column :width="130" field="index1" title="总分">
                    <template v-slot="scope">
                        <div class="d-flex">
                            <div class="d-flex flex-column">
                                <span v-if="scope.row.bigsmall !== null">大</span>
                                <span v-if="scope.row.bigsmall !== null">小</span>
                            </div>
                            <div class="flex flex-column">
                                <div>
                                    <span class="mx-3">{{ scope.row.bigsmall }}</span>
                                    <span style='color:#880000;'>{{ scope.row.big }}</span>
                                </div>
                                <div>
                                    <span class="mx-3">{{ scope.row.bigsmall }}</span>
                                    <span style='color:#880000;'>{{ scope.row.small }}</span>
                                </div>
                            </div>
                        </div>
                    </template>
                </ux-table-column>
            </ux-grid>
            </div>
        </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
// import jsonData from '@/data/tomorrow.json';
// eslint-disable-next-line no-unused-vars
import {setcolor } from '@/utils/setcolor';
import { checkColor, handicapToNum, convertToReal, compare, dateFormatted } from '../utils/utils';
import Nav from './comment/nav.vue'
import Footer from './comment/footer.vue'
export default {
    inject: ['reload'],
    components: { Nav,Footer },
    data() {
        return {
            tableData: [],
            filters: [],
            myLeague1: [],
            myLeague2: [],
            ishide: [],
            selectedFilters: [],
            emptyText: '数据加载中...',
            columns: [
                { label: '联赛', prop: 'league' },
                { label: '时间', prop: 'time' },
                { label: '主排名', prop: 'homerank' },
                { label: '主队', prop: 'home' },
                { label: 'qsda', prop: 'qsda' },
                { label: '客队', prop: 'away' },
                { label: '客排名', prop: 'awayrank' },
                { label: '指数1', prop: 'index1' },
                { label: 'Crow', prop: 'handicap' },
                { label: '指数2', prop: 'index2' },
                { label: '小球', prop: 'small' },
                { label: '大小', prop: 'bigsmall' },
                { label: '大球', prop: 'big' },
                { label: 'id', prop: 'id' },
            ],
            isDark: false,
            isToday: true,
            isTomorrow: false,
            loading: true,
            dateStr: '',
            currentDate: 0,
            dateParams: '',
            isphone: false
        }
    },
    mounted() {
        // console.log(this.$route.params.date);
        this.dateParams = this.$route.params.date;
        this.getCheckLeague();
        this.loadData();
        this.isMobile();
    },
    methods: {
        setcolor,
        dateFormatted,
        handicapToNum,
        checkColor,
        convertToReal,
        compare,
        formattedDateToMMDD(date,time){
      if (time < '11:00') {
        const [year,month, day] = date.split('-').map(Number);
        // 获取当前年份
        const currentYear = new Date().getFullYear();
        // 创建一个新的 Date 对象，年份可以随意设置
        const d = new Date(year, month - 1, day);
        // 日期加1天
        d.setDate(d.getDate() + 1);
        // 格式化新的日期
        const newYear = String(d.getFullYear());
        const newMonth = String(d.getMonth() + 1).padStart(2, '0');
        const newDay = String(d.getDate()).padStart(2, '0');
        const newDateStr = `${newYear}-${newMonth}-${newDay}`;
        return newDateStr;
      }else{
        return date;
      }
    },
        sortedTime(){
            const currentTime = new Date();
            const twoHourLater = new Date(currentTime.getTime() - 2 * 60 * 60 * 1000);
            // console.log(this.tableData[0].time,this.formattedDateToMMDD(this.tableData[200].date,this.tableData[200].time));
            // 排序函数
            const sortedData = this.tableData.sort((a, b) => {
                const aDateTime = new Date(`${this.formattedDateToMMDD(a.date)} ${a.time}`);
                const bDateTime = new Date(`${this.formattedDateToMMDD(b.date)} ${b.time}`);
                console.log(aDateTime,bDateTime);
                
                // 如果 a 的时间 <= 2小时后 b 的时间 > 2小时后，则将 a 排到后面
                if (aDateTime <= twoHourLater && bDateTime > twoHourLater) {
                    return 1;
                }
                // 如果 b 的时间 <= cutoffTime，且 a 的时间 > cutoffTime，则将 b 排到后面
                if (bDateTime <= twoHourLater && aDateTime > twoHourLater) {
                    return -1;
                }
                // 如果都在 cutoffTime 之后或之前，则保持原顺序
                return 0;
            });
            this.tableData = sortedData;
        },
        loadData(){
            let date = dateFormatted(new Date());
            if (this.dateParams !== undefined) {
                date = this.dateParams;
            }else{
                // 设置目标时间为 10:30 AM
                let targetTime = new Date();
                let currentTime = new Date();
                targetTime.setHours(10);
                targetTime.setMinutes(35);
                targetTime.setSeconds(0);
                // 比较当前时间和目标时间
                if (currentTime > targetTime) {
                    console.log("当前时间大于 10:35 AM，读取今日数据");
                    date = this.dateFormatted(currentTime);
                } else {
                    console.log("当前时间小于 10:35 AM，读取昨天数据");
                    // 将日期减一天
                    currentTime.setDate(currentTime.getDate() - 1)
                    date = this.dateFormatted(currentTime);
                    // console.log(dateParams);
                }
            }
            console.log(date);
            const url = '/basketball/match/today';
            // const url = '/match/date/' + date;
            fetch(url)
            .then(response => response.json())
            .then(data => {
                    console.log(data);
                    this.dateStr = data.data.length > 0 ? data.data[0].date : ''
                    this.tableData = data.data;
                    if (this.tableData.length == 0) {
                        this.emptyText = '暂无数据';
                    }else{
                        this.emptyText = '加载中...';
                    }
                    this.ishide = Array(this.tableData.length).fill(false);
                    this.$refs.table.reloadData(this.tableData);
                    this.updateFilterEvent()
                    this.loading = false;
                    // console.log(this.ishide);
                    
            })
            .catch(error => console.error('Error:', error));
        },
        addHoursToTime(timeStr, hoursToAdd) {
  // 将时间字符串转换为 Date 对象
  const [hours, minutes] = timeStr.split(':');
  const date = new Date();
  
  date.setHours(parseInt(hours, 10));
  date.setMinutes(parseInt(minutes, 10));
  date.setSeconds(0);  // 确保秒数为0
  
  // 增加指定的小时数
  date.setHours(date.getHours() + hoursToAdd);
  
  // 格式化回字符串形式
  const newHours = String(date.getHours()).padStart(2, '0');
  const newMinutes = String(date.getMinutes()).padStart(2, '0');
  
  return `${newHours}:${newMinutes}`;
},
        callSetColor(color,league){
            return setcolor(color,league);
        },
        qsdaColorSet(handicap,qsda){
            let qsdaColor = 'rgb(128,0,255)';
            let lowOrHight = 2;
            if (handicap!=='') {
                const handicapNum = handicapToNum(handicap);
                const realHandicapNum = convertToReal(qsda);
                if (handicapNum === 0) {
                    qsdaColor = 'rgb(128,0,255)';
                    return qsdaColor
                }else{
                    if (typeof handicapNum !== 'undefined' && typeof realHandicapNum !== 'undefined') {
                            lowOrHight = compare(handicapNum, realHandicapNum);
                        if(lowOrHight === 0){
                            qsdaColor = '#000';
                            return qsdaColor
                        }
                        if (lowOrHight === 1) {
                            qsdaColor = 'red';
                            return qsdaColor
                        }
                        if (lowOrHight === -1) {
                            qsdaColor = 'green';
                            return qsdaColor
                        }
                    }
                }
            }
        },
        istoday(){
            const today = new Date();
            if (this.dateStr == this.dateFormatted(today)) {
            console.log('istoday true');
                return true
            }else{
                console.log('istoday false');
                return false
            }
        },
        istomorrow(){
            const today = new Date();
            const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
            if (this.dateStr == this.dateFormatted(tomorrow)) {
            console.log('istomorrow true');
                return true
            }else{
                console.log('istomorrow false');
                return false
            }
        },
        filterData(){
            if (this.selectedFilters.length === 0) return this.tableData;
            return this.tableData.filter(row => this.selectedFilters.includes(row.league));
        },
        filterTag({value, row, column}) {
            const property = column['property'];
            // console.log(row[property],row[property] === value);
            if (value === '竞足') {
                // 当选择 '竞足' 时，检查 myLeague1 数组中的任何一个值是否与 row[property] 匹配
                const match = this.myLeague1.some(league => row[property] === league);
                return match;
            }else if(value === '北单'){
                // 当选择 '北单' 时，检查 myLeague2 数组中的任何一个值是否与 row[property] 匹配
                const match = this.myLeague2.some(league => row[property] === league);
                return match;
            }else{
                // 重置 ishide 数组
                this.ishide.forEach((_, index) => {
                    this.ishide[index] = false;
                });
                return row[property] === value;
            }
        },
        updateFilterEvent(){
            const column = this.$refs.table.getColumnByField('league')
            const leagueSet = new Set(this.tableData.map((item) => item.league));
            const leagueArray = Array.from(leagueSet);
            this.filters = leagueArray.map((item) => ({ label: item, value: item }));
            if (this.myLeague2.length > 0) {
                this.filters.unshift({label: '北单', value: '北单'})
            }
            if (this.myLeague1.length > 0) {
                this.filters.unshift({label: '竞足', value: '竞足'})
            }
            // console.log(this.filters,'wwwww');
            this.$refs.table.setFilter(column, this.filters)
            this.$refs.table.updateData()
        },
        //获取国彩联赛列表
        getCheckLeague(){
            // const url = '/league/check-league';
            const url = 'https://api.qsda.net/api/league/check-league';
            const today = this.dateFormatted(new Date());
            // console.log(today);
            
            fetch(url)
            .then(response => response.json())
            .then(data => {
                // console.log(data.data);
                if (data.data[0].date === today) {
                    // console.log('sssddd');
                    
                    this.myLeague1 = data.data[0].data;
                    this.myLeague2 = data.data[1].data;
                }
                
            }).catch(err=>{
                console.log('读取数据出错',err);
            })
        },
        jingzu(){
            const allMatch = this.$refs.table.getTableData().tableData;            
            for (let index = 0; index < allMatch.length; index++) {
                const all = allMatch[index].league;
                let isMatchFound = false;
                for (let i = 0; i < this.myLeague1.length; i++) {
                    const check = this.myLeague1[i];
                    if (check === all) {
                        // console.log(all,check);
                        isMatchFound = true;
                        break;
                    }
                }
                this.ishide[index] = !isMatchFound;
            }
            // console.log(this.ishide);
            this.$refs.table.updateData()
        },
        beidan(){
            const allMatch = this.$refs.table.getTableData().tableData;            
            for (let index = 0; index < allMatch.length; index++) {
                const all = allMatch[index].league;
                let isMatchFound = false;
                for (let i = 0; i < this.myLeague2.length; i++) {
                    const check = this.myLeague2[i];
                    if (check === all) {
                        // console.log(all,check);
                        isMatchFound = true;
                        break;
                    }
                }
                this.ishide[index] = !isMatchFound;
            }
            this.$refs.table.updateData()
        },
        tableRowClassName({ rowIndex }) {
            // console.log(rowIndex);
            
            // 根据行的索引自定义类名
            return this.ishide[rowIndex] ? 'd-none' : '';
        },
        isMobile() {
            const userAgent = navigator.userAgent;
            const isMobileDevice = /phone|pad|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone/i.test(userAgent);
            // 进一步判断 iPad
            const isTablet = /iPad/i.test(userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
            if (isMobileDevice || isTablet) {
                // console.log('移动端打开');
                this.isphone = true;
            } else {
                // console.log('PC端打开');
                this.isphone = false;
            }
        }
    },
}
</script>
<style lang="scss" scoped>
    @import '../assets/sass/global.scss';
    .container,.container-fluid {padding-top: 60px; padding-bottom: 60px;}
    font {color: rgb(136, 136, 136);}
    ::v-deep .elx-body--row .col_2 .elx-cell {padding: 0;}
    ::v-deep .elx-header--row .col_3 .elx-cell {justify-content: center;}
    ::v-deep .elx-header--row .col_5 .elx-cell {justify-content: left;}
    ::v-deep .elx-header--row .col_6 .elx-cell {justify-content: center;}
    ::v-deep .elx-table--body-wrapper {font-size: 16px; color: #000;}
    ::v-deep .col_3 .elx-cell {padding: 0; line-height: 48px;}
    ::v-deep .elx-body--column { text-align: center!important; }
    ::v-deep .col_5 { text-align: left!important; }
    ::v-deep .col_5 { padding: 8px 0!important; }
    ::v-deep .col_7 { text-align: left!important; }
    // ::v-deep .elx-table.border--full .elx-table--header-wrapper { background-color: #82959f;}
    // ::v-deep .elx-header--column .elx-cell { color: #fff; }
    ::v-deep .elx-table .elx-header--column.col--ellipsis {height: auto;padding: 5px 0;}
    .nodata {
            color: #ccc;
        }
    .NIGHT {
        .nodata {
            color: #666;
        }
    }
</style>