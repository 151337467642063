export default class TokenBucket {
    constructor(rate, capacity) {
      this.rate = rate;
      this.capacity = capacity;
      this.tokens = capacity;
      this.lastFill = Date.now();
    }
  
    consume() {
      const now = Date.now();
      this.tokens = Math.min(this.capacity, this.tokens + (now - this.lastFill) * this.rate / 1000);
      this.lastFill = now;
      if (this.tokens < 1) {
        return false;
      } else {
        this.tokens -= 1;
        return true;
      }
    }
  }