<template>
    <div>
    <div class="container py-2" v-loading="loading" :style="{height: loading || this.todayData.length === 0 ? 'calc(100vh - 129px)' : 'auto'}">
        <Nav :isDark.sync="this.isDark" @darkmode="isDark = $event"></Nav>
        <h1 class="fs-24 text-center mt-2">今日数据获取</h1>
            <div v-if="this.todayData.length === 0 && !loading" class="text-center text-secondary h-75 d-flex align-items-center justify-content-center flex-column mb-4 mt-2 rounded">
                <span class="mb-3">暂无今日联赛列表，请点击“获取联赛”按钮</span>
                <router-link type="text" :to="'/today-crawler'" class="fs-12 text-primary mb-2">转到新版</router-link>
                <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="getMatch()">获取联赛(旧)</el-button>
            </div>
        <div v-if="this.todayData.length > 1 && !loading">
            <!-- <el-button class="mb-3" type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="getMatch()">重新抓取</el-button> -->
            <div class="d-flex justify-content-between">
                <b>{{ this.dateTitle }}</b>
                <div>
                    <el-button type="text" @click="reCrawler()" class="fs-12 text-end text-primary">不满意？重新抓取比赛>></el-button>
                    <router-link :to="'/today-crawler'" class="fs-12 text-primary ps-3" style="cursor: pointer;">返回新版>></router-link>
                </div>
            </div>
            <Edite class="mt-2" :matchdata="this.todayData" :dataType="'today'"></Edite>
        </div>
    </div>
    <Footer></Footer>
    </div>
</template>
<script>
import Edite from './edite.vue';
import Nav from './comment/nav.vue'
import Footer from './comment/footer.vue'
import {changeLeagueName,changeTeamName} from '@/utils/change';
export default {
    components: { Edite, Nav,Footer },
    inject: ['reload'],
    data() {
        return {
            loading: true,
            fullscreenLoading: false,
            tips: '',
            todayData: [],
            dateTitle: '',
            isDark: false,
        }
    },
    mounted() {
        this.validatorData();
    },
    methods: {
        dateFormatted (date) {
            // const date = new Date();
            const year = date.getFullYear();
            let month = date.getMonth();
            let day = date.getDate();
            month = month < 9 ? '0'+( month + 1) : month + 1;
            day = day < 10 ? '0' + day : day;
            return year + '-' + month+'-' + day;
        },
        validatorData(){
            console.log('验证数据');
            const url = 'https://api.qsda.net/api/match/today';
            // const url = 'http://localhost:8888/api/match/today';
            fetch(url)
            .then(response => response.json())
            .then(data => {
                this.loading = false;
                const todayData = new Date();
                const yestoday = new Date(todayData.getFullYear(), todayData.getMonth(), todayData.getDate() - 1)
                const currentHours = todayData.getHours()
                const currentMin = todayData.getMinutes();
                // console.log(data.data[0].date, this.dateFormatted(todayData),this.dateFormatted(yestoday));
                // console.log(data.data);
                if (currentHours < 10 || currentHours == 10 && currentMin < 30) {
                    if(data.data[0].date == this.dateFormatted(yestoday)){
                        console.log('日期不是今日但小于10:30');
                        this.dateTitle = data.data[0].date;
                        this.todayData = data.data;
                    }
                }
                // else if (data.data[0].date == this.dateFormatted(todayData)){
                //     console.log('日期相同');
                    this.dateTitle = data.data[0].date;
                    this.todayData = data.data;
                // }else{
                //     console.log('日期不相同');
                // }
            }).catch(err=>{
                this.loading = false;
                console.log('读取数据出错');
                console.log(err);
            })
        },
        getMatch(){
            this.fullscreenLoading = true;
            const url = '/crawler/today';
            // const url = 'http://localhost:8888/api/crawler/today';
            fetch(url,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(data => {
                this.fullscreenLoading = false;
                if (data.code == 200) {
                    this.$notify({
                        title: '抓取成功',
                        message: data.data,
                        type: 'success',    
                        // duration: 0
                    });
                    this.validatorData();
                } else {
                    this.tips = '抓取失败，请检查格式是否正确！';
                    this.$notify.error({
                        title: '抓取失败',
                        message: '未知错误，请重试',
                        // duration: 0
                    });
                }
                console.log(data);
            }).catch(err=>{
                this.fullscreenLoading = false;
                this.$notify.error({
                        title: '抓取失败',
                        message: '连接超时',
                        // duration: 0
                    });
                console.error(err);
            })
        },
        reCrawler(){
            this.todayData = [];
        },
        callChangeTeamName(name){
            return changeTeamName(name);
        },
        callChangeLeagueName(name){
            return changeLeagueName(name);
        }
    }
}
</script>
<style lang="scss" scoped>
    @import '../assets/sass/global.scss';
    .container {margin-top: 56px;}
</style>