<template>
    <div class="container py-2" style="height: calc(100vh - 129px);">
        <Nav :isDark.sync="this.isDark" @darkmode="isDark = $event"></Nav>
        <h1 class="fs-24 text-center my-3">修改态况数据</h1>
        <div v-if="this.data.length>0">
            <ux-grid
             ref="plxTable"
             stripe
             show-overflow
             use-virtual
             :data = data
             :highlightCurrentRow="true"
             :checkboxConfig="{highlight: true}"
             :edit-config="{trigger: 'click', mode: 'cell'}"
             show-header-overflow="ellipsis">
             <!-- <ux-table-column type="checkbox" :width="40"/> -->
             <!-- <ux-table-column type="index" :width="40"/> -->
             <ux-table-column field="c_name" title="国家" edit-render>
                <template v-slot:edit="scope">
                    <el-input v-model="scope.row.c_name"></el-input>
                </template>
            </ux-table-column>
             <ux-table-column field="l_name" title="态况联赛名" edit-render>
                <template v-slot:edit="scope">
                    <el-input v-model="scope.row.l_name"></el-input>
                </template>
             </ux-table-column>
             <ux-table-column field="s_name" title="联赛名" edit-render>
                <template v-slot:edit="scope">
                    <el-input v-model="scope.row.s_name"></el-input>
                </template>
             </ux-table-column>
             <ux-table-column field="c_id" title="id" edit-render>
                <template v-slot:edit="scope">
                    <el-input v-model="scope.row.c_id"></el-input>
                </template>
             </ux-table-column>
             <ux-table-column title="操作" field="detail" :width="90" fixed="right">
                <template v-slot="scope">
                    <!-- <a class="text-danger me-2 pointer" @click="removeSelected(scope.row)">删除</a> -->
                    <a class="text-primary pointer" @click="insertEvent(scope.row)">插入</a>
                </template>
             </ux-table-column>
            </ux-grid>
        </div>
        <div class="bottom-btn w-100 text-end py-2">
         <el-button @click="saveChg">保存修改</el-button>
         <!-- <el-button @click="removeSel">删除选中</el-button> -->
     </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Nav from '../comment/nav.vue'
import Footer from '../comment/footer.vue'
export default {
    components: { Nav,Footer },
    data(){
        return {
            isDark: false,
            data: [],
            columns: [
                { label: '国家', prop: 'country'},
                { label: '态况联赛名', prop: 'tk-league'},
                { label: '联赛', prop: 'league'},
                { label: '联赛id', prop: 'id'},
            ],
            countries: [],
            loading: false
        }
    },
    mounted() {
        this.getTaikuangList();
    },
    methods: {
        getTaikuangList(){
            fetch('https://api.qsda.net/api/match/taikuang')
            .then(response => response.json())
            .then(res => {
                // console.log(res.data);
                this.data = res.data;
                let countriesList = this.data.map((item)=>({label: item.c_name, value: item.c_name}));
                this.countries = Array.from(new Set(countriesList.map(JSON.stringify))).map(JSON.parse);
            })
        },
        filterCountry({value, row, column}){
            const property = column['property'];
                return row[property] === value;
        },
        removeSelected(row){
            // console.log(row);
            this.$refs.plxTable.remove(row)
        },
        async insertEvent (row) {
            // console.log(row);
            let record = {
                c_id: 'id',
                c_name: '国家',
                l_name: '态况联赛名',
                s_name: '联赛名',
            }
            // 加入行, 表示加入行的row对象，row为空则插入第一行，为-1插入最后一行
            let { row: newRow } = await this.$refs.plxTable.insertRow(record, row)
            // 激活单元格编辑
            await this.$refs.plxTable.setActiveRow(newRow)
        },
        saveChg(){
            const chgData = this.$refs.plxTable.getTableData().tableData;
            chgData.map(obj => {
                delete obj._XID;
                return obj;
            });
            console.log(chgData);
            const url = 'https://api.qsda.net/api/match/changeTaikuang';
            fetch(url,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(chgData)
            })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                if (data.msg === 'success') {
                    //弹出成功提示
                    this.$alert('更新数据成功！', '', {
                        confirmButtonText: '确定',
                        callback: () => {
                            location.reload();
                        }
                    });
                }else{
                    //弹出失败提示
                    this.$alert('更新数据失败！', '', {
                        confirmButtonText: '确定',
                        callback: () => {
                            location.reload();
                        }
                    });
                }
            })
            .catch(error => {
                console.error('Error:', error);
                //弹出失败提示
                this.$alert('未知错误！更新数据失败！', '', {
                        confirmButtonText: '确定',
                });
            });
        }
    }
}
</script>
<style lang="scss" scoped>
    .container {margin-top: 56px;}
</style>