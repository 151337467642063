<template>
    <div :style="{ height: this.history.length === 0 ? 'calc(100vh - 129px)' : 'auto'}">
        <Nav :isDark.sync="this.isDark" @darkmode="isDark = $event"></Nav>
        <div class="container">
            <div class="inputDom d-flex justify-content-center">
                <div class="mx-2">
                    <p>qsda:</p>
                    <el-input type="number" placeholder="qsda" v-model="qsda" clearable></el-input>
                </div>
                <div class="mx-2">
                    <p>盘口:</p>
                    <el-input placeholder="盘口" v-model="hd" clearable></el-input>
                </div>
                <div class="mx-2">
                    <p>大小</p>
                    <el-input placeholder="大小" v-model="bs" clearable></el-input>
                </div>
                <div class="mx-2">
                    <p>&nbsp;</p>
                    <el-button class="submit-btn" type="primary" @click="submit">提交</el-button>
                </div>
            </div>
            <div class="res mt-4 h-100">
                <el-table :data="history" border stripe style="width: 100%">
                    <el-table-column prop="date" label="日期" width="120" class="fs-12"></el-table-column>
                    <el-table-column prop="league" label="时间" width="80"></el-table-column>
                    <el-table-column prop="home" label="主队" width="238"></el-table-column>
                    <el-table-column prop="score" label="比分" width="54"></el-table-column>
                    <el-table-column prop="away" label="客队" width="238"></el-table-column>
                    <el-table-column prop="qsda" label="qsda" width="60"></el-table-column>
                    <el-table-column prop="state" label="结果" width="70" column-key="state" :filters="result" :filter-method="filterHandler">
                        <template v-slot="scope">
                            <span :style="'color:'+checkcolor(scope.row.state)">{{scope.row.state}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="初/终" width="60">
                        <template>
                            <div>初盘</div>
                            <div>终盘</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="index1" label="指数1" width="60">
                        <template v-slot="scope">
                            <div>{{scope.row.h_index1}}</div>
                            <div>{{scope.row.h_index2}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="crown" label="Crown" width="70">
                        <template v-slot="scope">
                            <div>{{scope.row.init}}</div>
                            <div>{{scope.row.final}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="index2" label="指数2" width="60">
                        <template v-slot="scope">
                            <div>{{scope.row.a_index1}}</div>
                            <div>{{scope.row.a_index2}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="big" label="大球" width="60">
                        <template v-slot="scope">
                            <div>{{scope.row.big_1}}</div>
                            <div>{{scope.row.big_2}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="bigsmall" label="大小球" width="65">
                        <template v-slot="scope">
                            <div>{{scope.row.bs_init}}</div>
                            <div>{{scope.row.bs_final}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="small" label="小球" width="60">
                        <template v-slot="scope">
                            <div>{{scope.row.samll_1}}</div>
                            <div>{{scope.row.samll_2}}</div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Nav from './comment/nav.vue'
import Footer from './comment/footer.vue'
import { checkColor, numToHandicap } from '../utils/utils';
export default {
    inject: ['reload'],
    components: { Nav,Footer },
    data() {
        return {
            isDark: false,
            history: [],
            result: [
                {text: '胜',value: '胜'},
                {text: '平',value: '平'},
                {text: '负',value: '负'}
            ],
            qsda: 0,
            hd: '',
            bs: ''
        }
    },
    methods:{
        numToHandicap,
        checkcolor(key){
            return checkColor(key)
        },
        async getHistory(qsda,init,bsInit){
            const url = 'https://api.qsda.net/api/match/history?';
            // const url = 'localhost:8888/api/match/history?';
            await fetch(url + 'qsda=' + qsda + '&init=' + init + '&bsInit=' + bsInit + '&pageNum=1&pageSize=20', {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => response.json())
            .then(data => {
                    console.log(data.data.list);
                    this.history = data.data.list;
            });
            console.log(this.history);
        },
        submit(){
            let init = numToHandicap(this.hd);
            console.log(init);
            console.log(this.qsda,this.hd,this.bs);
            if (this.hd == '' || this.bs == '') {
                this.$message.error('请输入盘口或大小球值');
                return
            }
            if (this.hd !== '' && this.bs !== '') {
                this.getHistory(this.qsda,init,this.bs);
            }
            
        },
        filterHandler(value, row, column) {
            const property = column['property'];
            return row[property] === value;
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../assets/sass/global.scss';
    .container {
        height: 100%; padding-top: 66px;
    }
    @media (max-width: 1024px) {
        .inputDom {
            flex-direction: column;
            div {
                :first-child
                {
                 margin-bottom: 1rem;}
            }
            div:last-child {
                    p {display: none;}
                }
        }
        .submit-btn {width: 100%;}
    }
</style>