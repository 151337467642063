<template>
  <div :class="{'NIGHT': isDark}" v-loading="loading" :style="{height: loading ? '100vh' : 'auto'}">
    <Nav :isDark="this.isDark"></Nav>
    <el-backtop></el-backtop>
    <div class="container-fluid px-0" style="" v-if="!loading">
      <div class="d-flex justify-content-between align-items-center border-bottom py-1 px-2 bg-light">
        <el-button class="fs-12 py-0 text-primary" type="text" @click="centerDialogVisible = true">联赛选择</el-button>
        <router-link :to="'/qsda-tomorrow-m'" class="text-primary fs-12" style="cursor: pointer;">查看明日比赛>></router-link>
        <!-- <a class="text-primary fs-12" v-if="isTomorrow" @click="switeToTodayData" style="cursor: pointer;">返回今日比赛>></a> -->
      </div>
      <el-dialog title="联赛选择" :visible.sync="centerDialogVisible" width="90%" center>
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
        <el-checkbox v-model="checkMyLeague1" @change="handleCheckMyLeague1">竞足</el-checkbox>
        <el-checkbox v-model="checkMyLeague2" @change="handleCheckMyLeague2">北单</el-checkbox>
        <div style="max-height: 50vh; overflow-y: scroll;">
          <el-checkbox-group v-model="checkedLeague" @change="handleCheckedLeagueChange">
            <el-checkbox v-for="(item,index) in league" :label="item" :key="index" :checked="isHide[index]" @change="change(isHide[index],item)">{{item}}</el-checkbox>
          </el-checkbox-group></div>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
          </span>
      </el-dialog>
      <div v-if="this.matchdata.length === 0 && !loading" :style="{height: this.matchdata.length === 0 ? 'calc(100vh - 156px)' : 'auto'}">
        <div class="nodata d-flex flex-column justify-content-center align-items-center" :class="{'h-100':this.matchdata.length === 0}">
          <span class="nodata">暂无比赛数据</span>
        </div>
      </div>
      <div v-if="this.matchdata.length > 0">
        <div v-for="(item,index) in matchdata" :key="index">
          <div :class="{'d-none': isHide[index]}">
            <div class="d-flex justify-content-between align-items-center py-2 border-bottom px-2">
              <div class="d-flex flex-column">
                <div>
                  <span class="fs-10 me-3" :style="{ color: callSetColor(item.color,item.league) }">{{ item.league }}</span>
                  <span class="fs-10 time me-2">{{ formattedDateToMMDD(item.date,item.time) }}</span>
                  <span class="fs-10 time">{{ item.time }}</span>
                </div>
                <div class="d-flex">
                  <button class="btn ps-0" @click="addOrDelFav(index,item)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" v-show="!isFav[index].selected" fill="#eee" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" v-show="isFav[index].selected" fill="#ffc107" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                  </svg>
                </button>
                  <div class="d-flex flex-column">
                    <span>
                        <b class="fs-14">{{ item.home }}</b>
                        <font class="fs-12 ms-1">{{ filteredNum(item.home_rank) }}</font>
                    </span>
                    <span>
                        <b class="fs-14">{{ item.away }}</b>
                        <font class="fs-12 ms-1">{{ filteredNum(item.away_rank) }}</font>
                    </span>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-column pe-1">
                <span class="fs-10 d-flex justify-content-end align-items-center" style="height: 24px;">
                  <!-- <el-button type="text" class="p-0" @click="getHistory(item.qsda,item.handicap,item.bigsmall)">
                    <i class="el-icon-document fs-12" style="color: #ccc;"></i>
                  </el-button> -->
                  <router-link :to="{ path: '/charts/' + item.m_id, query: { teams: item.home+' VS '+item.away } }" class="text-decoration-none ms-2" tag="a" target="_blank">
                    <i class="el-icon-s-data fs-12" style="color: #ccc;"></i>
                </router-link>
                </span>
                <div class="d-flex" style="height: 48px;">
                  <div class="d-flex flex-column align-items-end justify-content-center">
                    <span class="fs-12 text-primary">{{ item.handicap }}</span>
                    <span class="fs-12 mt-1 text-primary">{{ item.bigsmall }}</span>
                  </div>
                  <a class="text-decoration-none d-flex flex-column justify-content-center align-items-end" :href="'https://m.titan007.com/Analy/ShiJian/'+item.m_id+'.htm'" target="_blank" style="width: 48px;">
                    <b class="fs-14 ps-3 qsdaColor" :style="{color: qsdaColorSet(item.handicap,item.qsda)}">{{ item.qsda }}</b>
                  </a>
                </div>
              </div>
              <!-- <div class="team d-flex align-items-center"> -->
                <!-- <button class="btn" @click="addOrDelFav(index,item)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" v-show="!isFav[index]" fill="#eee" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" v-show="isFav[index]" fill="#ffc107" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                  </svg>
                </button> -->
                <!-- <div class="flex-fill text-end d-flex flex-column">
                  <span class="fs-10" :style="{ color: callSetColor(item.color,item.name) }">{{ item.name }}</span>
                  <span>
                    <font class="fs-12 me-1">{{ filteredNum(item.homerank) }}</font>
                    <b class="fs-14">{{ item.home }}</b>
                  </span>
                    <span class="fs-10" style="color:#e6b272;">{{ item.handicap }}</span>
                </div>
              </div> -->
              <!-- <div class=" d-flex flex-column">
                <span> </span>
                <a class="text-decoration-none" :href="'https://m.nowscore.com/Analy/ShiJian/'+item.id+'.htm'" target="_blank"><b class="fs-14 px-3 qsdaColor" :style="{color: qsdaColorSet(item.handicap,item.qsda)}">{{ item.qsda }}</b></a>
                <span> </span>
              </div> -->
              <!-- <div class="team d-flex align-items-center">
                <div class="flex-fill d-flex flex-column">
                  <span class="fs-10 time">{{ item.time }}</span>
                  <span>
                    <b class="fs-14">{{ item.away }}</b>
                    <font class="fs-12 ms-1">{{ filteredNum(item.awayrank) }}</font>
                  </span>
                  <span class="fs-10" style="color:#e6b272;">{{ item.bigsmall }}</span>
                </div>
                <div style="width: 26px;height: 26px;"></div>
                <router-link :to="{ path: '/charts/' + item.id, query: { teams: item.home+' VS '+item.away } }" class="text-decoration-none" tag="a" target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="18px" viewBox="0 0 24 24" width="18px" fill="#0d6efd"><g><rect fill="none" height="18" width="18"/></g><g><g><path d="M21,8c-1.45,0-2.26,1.44-1.93,2.51l-3.55,3.56c-0.3-0.09-0.74-0.09-1.04,0l-2.55-2.55C12.27,10.45,11.46,9,10,9 c-1.45,0-2.27,1.44-1.93,2.52l-4.56,4.55C2.44,15.74,1,16.55,1,18c0,1.1,0.9,2,2,2c1.45,0,2.26-1.44,1.93-2.51l4.55-4.56 c0.3,0.09,0.74,0.09,1.04,0l2.55,2.55C12.73,16.55,13.54,18,15,18c1.45,0,2.27-1.44,1.93-2.52l3.56-3.55 C21.56,12.26,23,11.45,23,10C23,8.9,22.1,8,21,8z"/><polygon points="15,9 15.94,6.93 18,6 15.94,5.07 15,3 14.08,5.07 12,6 14.08,6.93"/><polygon points="3.5,11 4,9 6,8.5 4,8 3.5,6 3,8 1,8.5 3,9"/></g></g></svg>
                </router-link>
              </div> -->
            </div>
            <div class="history-list collapse border-bottom py-2" :id="'collapse' + index+1">
              <div v-for="(element,i) in historyList[index]" :key="i">
                <div v-if="this.historyList[index].length == 0" class="text-center">无回查数据</div>
                <div v-if="this.historyList[index].length !== 0">{{ element.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
// import jsonData from '@/data/tomorrow.json';
import Nav from './comment/nav.vue';
import Footer from './comment/footer.vue'
import {setcolor } from '@/utils/setcolor';
import { checkColor, handicapToNum, convertToReal, compare, dateFormatted } from '../utils/utils'
export default {
  components: { Nav,Footer },
  inject: ['reload'],
  data() {
    return {
      matchdata: [],
      historyList: [],
      isFav: [],
      favList: [],
      isHide: [],
      league: [],
      checkedLeague: [],
      idList: [],
      myLeague1: [],
      isDark: false,
      myLeague2: [],
      isToday: true,
      isTomorrow: false,
      loading: true,
      dateStr: '',
      dateParams: '',
      centerDialogVisible: false,
      isIndeterminate: false,
      checkAll: true,
      checkMyLeague1: false,
      checkMyLeague2: false,
    }
  },
  mounted() {
    // 设置过期时间为每天的10:30
    // var now = new Date();
    // var millisTill10 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10, 30, 0, 0) - now;
    // if (millisTill10 < 0) {
    //     millisTill10 += 86400000; // 如果时间已经过了，那么设置的是明天的10:30
    //     localStorage.removeItem('favList');
    //     localStorage.removeItem('favState');
    // }
    // const favExpStr = localStorage.getItem('favListExpirationDate')
    // const favStExpStr = localStorage.getItem('favStateExpirationDate')
    // const favExp = JSON.parse(favExpStr)
    // const favStExp = JSON.parse(favStExpStr)
    // if (favExp) {
      
    // }
    this.dateParams = this.$route.params.date;
    this.getData();
    this.getCheckLeague()
  },
  methods: {
    setcolor,
    dateFormatted,
    handicapToNum,
    checkColor,
    convertToReal,
    compare,
    callSetColor(color,league){
            return setcolor(color,league);
        },
    filteredNum(str){
      if (null == str || '' == str) {
        return '';
      }else{
        const rank = str.match(/\d+/) !==null ? '['+str.match(/\d+/)[0]+']' : '';
        return rank;
      }
    },
    getCheckLeague(){
      const url = 'https://api.qsda.net/api/league/check-league';
      fetch(url)
      .then(response => response.json())
      .then(data => {
          // console.log(data.data);
          this.myLeague1 = data.data[0].data;
          this.myLeague2 = data.data[1].data;
      }).catch(err=>{
          console.log('读取数据出错',err);
      })
    },
    getData(){
      let date = dateFormatted(new Date());
      // 设置目标时间为 10:30 AM
      let targetTime = new Date();
        let currentTime = new Date();
        targetTime.setHours(10);
        targetTime.setMinutes(35);
        targetTime.setSeconds(0);
      if (this.dateParams !== undefined) {
        date = this.dateParams;
      }else{
        // 比较当前时间和目标时间
        if (currentTime > targetTime) {
            console.log("当前时间大于 10:35 AM，读取今日数据");
            date = this.dateFormatted(currentTime);
        } else {
            console.log("当前时间小于 10:35 AM，读取昨天数据");
            // 将日期减一天
            currentTime.setDate(currentTime.getDate() - 1)
            date = this.dateFormatted(currentTime);
            // console.log(dateParams);
        }
      }
      // const url = 'https://api.qsda.net/api/match/'+date;
      const url = 'https://api.qsda.net/api/match/date/' + date;
      fetch(url)
      .then(response => response.json())
      .then(data => {
        this.dateStr = data.data[0].date;
        
        // 从 localStorage 中获取字favlist
        let favstr = localStorage.getItem('favList')
        if (favstr == '[]') {
          localStorage.removeItem('favList')
        }
        let favListStr = JSON.parse(localStorage.getItem('favList'));
        // 从 localStorage 中获取字favState
        let favStateStr = JSON.parse(localStorage.getItem('favState'));
        // let fav = []
        for (let index = 0; index < data.data.length; index++) {
          this.isFav.push({
            id: data.data[index].id,
            selected: false
          });
        }
        // console.log(data.data[0].date==favListStr[0].date);
        
        //如果收藏列表没数据,读取localStorage的
          if (this.favList.length == 0) {
            //没有收藏，添加
            if (null == favListStr) {
              console.log('favListStr收藏空,添加');
              // localStorage.setItem('favList', '[]');
            }else{
              //如果已经有收藏列表，读取localStorage的收藏列表
              if (favListStr.length !== 0 || favListStr !== '[]') {
              console.log('favListStr不空,读取值');
                //判断日期是否相同
                if (favListStr[0].date !== data.data[0].date) {
                  this.favList = []
                }else{
                  this.favList = favListStr;
                }
              }
            }
            
          }
          //如果收藏状态为空，添加初始状态
          if(null == favStateStr){
            console.log('favStateStr收藏状态空');
            // localStorage.setItem('favState', '[]');
            // for (let index = 0; index < data.data.length; index++) {
            //   this.isFav = fav;
            // }
          }else{
            //如果收藏状态列表不为空，读取localStorage的收藏状态
            if (favStateStr.length !== 0 && null !== favListStr){
            //判断日期是否相同,相同读取状态，不同设为初始
            if (favListStr[0].date !== data.data[0].date) {
                // this.isFav = fav;
              }else{
                this.isFav = favStateStr;
              }
            }
          }
          
        
                // const today = new Date();
                // const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
                // const currentHours = today.getHours()
                // const currentMin = today.getMinutes();
                // if (currentHours < 10 || currentHours == 10 && currentMin < 30) {
                //     console.log('现在时间小于10:30');
                    // 读取今日赛程
                    this.loading = false;
                    this.matchdata = data.data;
                    const leagueSet = new Set(this.matchdata.map((item) => item.league));
                    const leagueArray = Array.from(leagueSet);
                    this.league = leagueArray.map((item) => (item));
                    this.checkedLeague = this.league;
                    //联赛显示隐藏状态赋值
                    for (let index = 0; index < this.matchdata.length; index++) {
                      this.isHide.push(false);
                    }
                    
                    // for (let index = 0; index < this.league.length; index++) {
                    //   this.checkedLeague.push(true);
                    // }
                    // console.log(this.checkedLeague);
                    
                    // this.dataStr = this.data[0].date;
            //     }
            //     else if (data.data[0].date == this.dateFormatted(tomorrow)) {
            //         console.log('日期是今日');
            //         this.loading = false;
            //         this.matchdata = data.data;
            //         this.dataStr = data.data[0].date
            //     }else{
            //       console.log('没有数据');
            //       this.matchdata = [];
            //       this.loading = false;
            //       this.dataStr == ''
            //     }
            })
            .catch(error => console.error('Error:', error));
    },
    async getHistory(qsda,init,bsInit){
      let res = []
      const url = 'https://api.qsda.net/api/match/history?';
      // const url = 'localhost:8888/api/match/history?';
      await fetch(url + 'qsda=' + qsda + '&init=' + init + '&bsInit=' + bsInit + '&pageNum=1&pageSize=20', {
          method: 'get',
          headers: {
              'Content-Type': 'application/json',
          },
      })
      .then(response => response.json())
      .then(data => {
              res = data.data.list;
              this.historyList.push(res);
              console.log(res);
      });
      // console.log(res);
    },
    addOrDelFav(index,item){
      this.$set(this.isFav[index], 'selected', !this.isFav[index].selected);
      // console.log(item.id);
      // if (this.idList.length === 0) {
      //   this.idList.push({
      //     id: item.id,
      //     isFav: true
      //   })
      // }else{
      //   for (let index = 0; index < this.idList.length; index++) {
      //     if (this.idList.indexOf(item.id) == -1) {
      //       this.idList.push({
      //         id: item.id,
      //         isFav: true
      //       })
      //     }
      //   }
      // }
      // console.log(this.idList);
      
      // 设置过期时间为1天后
      // var expiration = new Date().getTime() + (24 * 60 * 60 * 1000);
      // 设置过期时间为每天的10:30
      var now = new Date();
      var millisTill10 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10, 30, 0, 0) - now;
      if (millisTill10 < 0) {
          millisTill10 += 86400000; // 如果时间已经过了，那么设置的是明天的10:30
      }
      if (this.isFav[index].selected) {
        this.favList.push(item);
        //保存到 localStorage
        let favListStr = JSON.stringify(this.favList);
        let favStateStr = JSON.stringify(this.isFav);
        localStorage.setItem('favList', favListStr);
        localStorage.setItem('favListExpirationDate', JSON.stringify({
          value: 'favListStr',
          expiration: now.getTime() + millisTill10
        }));
        localStorage.setItem('favState', favStateStr);
        localStorage.setItem('favStateExpirationDate', JSON.stringify({
          value: 'favStateStr',
          expiration: now.getTime() + millisTill10
        }));
      }else{
        this.favList.splice(this.favList.indexOf(item),1);
        //保存到 localStorage
        let favListStr = JSON.stringify(this.favList);
        let favStateStr = JSON.stringify(this.isFav);
        localStorage.setItem('favList', favListStr);
        localStorage.setItem('favState', favStateStr);
      }
      // console.log(this.favList);
    },
    qsdaColorSet(handicap,qsda){
      let qsdaColor = 'rgb(128,0,255)';
      let lowOrHight = 2;
      if (handicap!=='') {
          const handicapNum = handicapToNum(handicap);
          const realHandicapNum = convertToReal(qsda);
          // console.log(handicapNum, realHandicapNum);
          if (handicapNum === 0) {
              qsdaColor = 'rgb(128,0,255)';
              return qsdaColor
          }else{
              if (typeof handicapNum !== 'undefined' && typeof realHandicapNum !== 'undefined') {
                      lowOrHight = compare(handicapNum, realHandicapNum);
                  if(lowOrHight === 0){
                      qsdaColor = '#666';
                      return qsdaColor
                  }
                  if (lowOrHight === 1) {
                      qsdaColor = 'red';
                      return qsdaColor
                  }
                  if (lowOrHight === -1) {
                      qsdaColor = 'green';
                      return qsdaColor
                  }
              }
          }
      }
    },
    istoday(){
        const today = new Date();
        if (this.dateStr == this.dateFormatted(today)) {
        console.log('istoday true');
            return true
        }else{
            console.log('istoday false');
            return false
        }
    },
    istomorrow(){
      console.log(this.dateStr);
        const today = new Date();
        const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
        if (this.dateStr == this.dateFormatted(tomorrow)) {
        console.log('istomorrow true');
            return true
        }else{
            console.log('istomorrow false');
            return false
        }
    },
    handleCheckedLeagueChange(value) {
      // console.log(value,'lllll');
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.league.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.league.length;
      
        //   for (let index = 0; index < this.matchdata.length; index++) {
        //     const leagueName = this.matchdata[index].league;
        //     // console.log(leagueName.includes(checkElm),checkElm,leagueName);
        //     for (let i = 0; i < value.length; i++) {
        //       const checkElm = value[i];
        //       if (leagueName.includes(checkElm)) {
        //         this.isHide[index] = true;
        //         console.log('yyy',checkElm,leagueName,this.isHide[index]);
        //       }
        //   }
        // }
    },
    handleCheckAllChange(val) {
      this.checkedLeague = val ? this.league : [];
      this.checkMyLeague1 = false;
      this.checkMyLeague2 = false;
      this.isIndeterminate = false;
      //设置全选或全不选      
      for (let index = 0; index < this.isHide.length; index++) {
        if (val) {
          this.isHide[index] = false;
        }else{
          this.isHide[index] = true;
        }
      }
    },
    handleCheckMyLeague1(val) {
      // console.log(this.myLeague1,val);
      this.checkedLeague = val ? this.myLeague1 : [];
      
      if (!val) {
        this.checkAll = true;
        this.checkedLeague = this.league;
        for (let index = 0; index < this.isHide.length; index++) {
          if (!val) {
            this.isHide[index] = false;
          }else{
            this.isHide[index] = true;
          }
        }
      }else{
        this.checkAll = false;
        this.checkMyLeague2 = false;
        for (let index = 0; index < this.matchdata.length; index++) {
          const all = this.matchdata[index].league;
          let isMatchFound = false;
          for (let i = 0; i < this.myLeague1.length; i++) {
            const check = this.myLeague1[i];
            if (check === all) {
              isMatchFound = true;
      break; // 找到匹配就可以跳出循环
            }
          }
          this.isHide[index] = !isMatchFound; // 如果找到匹配值，则 isHide 为 false；否则为 true
        }
      }
    },
    handleCheckMyLeague2(val) {
      this.checkedLeague = val ? this.myLeague2 : [];
      if (!val) {
        this.checkAll = true;
        this.checkedLeague = this.league;
        for (let index = 0; index < this.isHide.length; index++) {
          if (!val) {
            this.isHide[index] = false;
          }else{
            this.isHide[index] = true;
          }
        }
      }else{
        this.checkAll = false;
        this.checkMyLeague1 = false;
        for (let index = 0; index < this.matchdata.length; index++) {
          const all = this.matchdata[index].league;
          let isMatchFound = false;
          for (let i = 0; i < this.myLeague2.length; i++) {
            const check = this.myLeague2[i];
            if (check === all) {
              isMatchFound = true;
              break; // 找到匹配就可以跳出循环
            }
          }
          this.isHide[index] = !isMatchFound; // 如果找到匹配值，则 isHide 为 false；否则为 true
        }
      }
    },
    change(val,name){
      console.log(val,name,this.matchdata.length,this.isHide.length);
      // this.isHide[index] = !this.isHide[index];
      for (let index = 0; index < this.matchdata.length; index++) {
        const elm = this.matchdata[index].league;
        // console.log('000',elm,this.isHide[index]);
        if (name === elm) {
          this.isHide[index] = !this.isHide[index];
          // console.log(index+1,elm,this.isHide[index]);
        }
      }
    },
    formattedDateToMMDD(date,time){
      const formattedDate = date.slice(5);
      if (time < '12:00') {
        const [month, day] = formattedDate.split('-').map(Number);
        // 获取当前年份
        const currentYear = new Date().getFullYear();
        // 创建一个新的 Date 对象，年份可以随意设置
        const d = new Date(currentYear, month - 1, day);
        // 日期加1天
        d.setDate(d.getDate() + 1);
        // 格式化新的日期
        const newMonth = String(d.getMonth() + 1).padStart(2, '0');
        const newDay = String(d.getDate()).padStart(2, '0');
        const newDateStr = `${newMonth}-${newDay}`;
        return newDateStr;
      }else{
        return formattedDate;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/sass/global.scss';
.team { width: 45%;}
font,.time {color: rgb(136, 136, 136);}
.container-fluid {padding-top: 56px;}
.el-checkbox {min-width: 100px; padding: 3px 0; margin-right: 5px;}
::v-deep .el-dialog--center .el-dialog__body {padding: 0 25px 0;}
@media (max-width:1024px) {
  .container-fluid {padding-top: 42px;}
  .el-checkbox {margin-right: 0;}
}
@media (max-width:391px) {
  .el-checkbox {min-width: 90px!important;}
}
</style>
