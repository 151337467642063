<template>
  <div :class="{'NIGHT': isDark}" v-loading="loading" :style="{height: loading || this.matchdata.length === 0 ? 'calc(100vh - 76px)' : 'auto'}">
    <Nav :isDark="this.isDark"></Nav>
    <div class="container-fluid px-0" style="padding-top: 56px;" v-if="!loading" :class="{'h-100':this.matchdata.length === 0}">
        <div class="d-flex justify-content-center align-items-center border-bottom py-1 bg-light">
            <router-link :to="'/qsda-today-m'" class="text-primary fs-12" style="cursor: pointer;">查看今日比赛>></router-link>
        </div>
        <div class="mb-4 text-center" :class="{'h-100':this.matchdata.length === 0}" v-if="this.matchdata.length === 0 && !loading">
            <div class="nodata d-flex flex-column justify-content-center" :class="{'h-100':this.matchdata.length === 0}">
                <span class="align-center">暂无比赛数据</span>
            </div>
        </div>
        <div v-for="(item,index) in matchdata" :key="index">
            <div>
            <div class="d-flex justify-content-between align-items-center py-3 border-bottom px-3">
                <div class="team d-flex align-items-center">
                <!-- <button class="btn" @click="addOrDelFav(index,item)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" v-show="!isFav[index]" fill="#eee" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" v-show="isFav[index]" fill="#ffc107" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                    </svg>
                </button> -->
                <div class="flex-fill text-end d-flex flex-column">
                    <span class="fs-10" :style="{ color: callSetColor(item.color,item.name) }">{{ item.name }}</span>
                    <span>
                    <font class="fs-12 me-1">{{ filteredNum(item.homerank) }}</font>
                    <b class="fs-14">{{ item.home }}</b>
                    </span>
                    <span class="fs-10" style="color:#e6b272;">{{ item.handicap }}</span>
                </div>
                </div>
                <div class=" d-flex flex-column">
                <span> </span>
                <a class="text-decoration-none" :href="'https://m.nowscore.com/Analy/ShiJian/'+item.id+'.htm'" target="_blank"><b class="fs-14 px-3 qsdaColor" :style="{color: qsdaColorSet(item.handicap,item.qsda)}">{{ item.qsda }}</b></a>
                <span> </span>
                </div>
                <div class="team d-flex align-items-center">
                <div class="flex-fill d-flex flex-column">
                    <span class="fs-10 time">{{ item.time }}</span>
                    <span>
                    <b class="fs-14">{{ item.away }}</b>
                    <font class="fs-12 ms-1">{{ filteredNum(item.awayrank) }}</font>
                    </span>
                    <span class="fs-10" style="color:#e6b272;">{{ item.bigsmall }}</span>
                </div>
                <div style="width: 26px;height: 26px;"></div>
                <!-- <button class="btn" data-bs-toggle="collapse" :data-bs-target="'#collapse' + index+1" aria-expanded="false" :aria-controls="'collapse' + index+1" @click="getHistory(item.handicap, item.handicap, item.bigsmall)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#e0e0e0" class="bi bi-file-earmark-text-fill" viewBox="0 0 16 16">
                    <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1h-4z"/>
                    </svg>
                </button> -->
                </div>
            </div>
            <div class="history-list collapse border-bottom py-2" :id="'collapse' + index+1">
                <div v-for="(element,i) in historyList[index]" :key="i">
                <div v-if="this.historyList[index].length == 0" class="text-center">无回查数据</div>
                <div v-if="this.historyList[index].length !== 0">{{ element.name }}</div>
                </div>
            </div>
            </div>
        </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Nav from './comment/nav.vue';
import Footer from './comment/footer.vue'
import {setcolor } from '@/utils/setcolor';
import { checkColor, handicapToNum, convertToReal, compare, dateFormatted } from '../utils/utils'
export default {
    components: { Nav,Footer },
    inject: ['reload'],
    data() {
        return {
        matchdata: [],
        historyList: [],
        isDark: false,
        isToday: true,
        isTomorrow: false,
        loading: true,
        isFav: [],
        favList: [],
        dateStr: ''
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        setcolor,
        dateFormatted,
        handicapToNum,
        checkColor,
        convertToReal,
        compare,
        callSetColor(color,league){
                return setcolor(color,league);
        },
        getData(){
            const url = 'https://api.qsda.net/api/match/tomorrow';
            // const url = 'localhost:8888/api/match/today';
            fetch(url)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                const today = new Date();
                const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
                const currentHours = today.getHours()
                const currentMin = today.getMinutes();
                if (currentHours < 10 || currentHours == 10 && currentMin < 30) {
                    console.log('现在时间小于10:30');
                    // 读取今日赛程
                    this.loading = false;
                    this.matchdata = data.data;
                    this.dataStr = this.dateFormatted(tomorrow)
                }
                else if (data.data[0].date == this.dateFormatted(tomorrow)) {
                    console.log('日期是今日');
                    this.loading = false;
                    this.matchdata = data.data;
                    this.dataStr = data.data[0].date
                }else{
                    console.log('不是今日，读取明日');
                            console.log('没有数据');
                            this.matchdata = [];
                            this.loading = false;
                            this.dataStr == ''
                }
            })
            .catch(error => console.error('Error:', error));
        },
        filteredNum(str){
      if (null == str || '' == str) {
        return '';
      }else{
        const rank = str.match(/\d+/) !==null ? '['+str.match(/\d+/)[0]+']' : '';
        return rank;
      }
    },
    qsdaColorSet(handicap,qsda){
            let qsdaColor = 'rgb(128,0,255)';
            let lowOrHight = 2;
            if (handicap!=='') {
                const handicapNum = handicapToNum(handicap);
                const realHandicapNum = convertToReal(qsda);
                // console.log(handicapNum, realHandicapNum);
                if (handicapNum === 0) {
                    qsdaColor = 'rgb(128,0,255)';
                    return qsdaColor
                }else{
                    if (typeof handicapNum !== 'undefined' && typeof realHandicapNum !== 'undefined') {
                            lowOrHight = compare(handicapNum, realHandicapNum);
                        if(lowOrHight === 0){
                            qsdaColor = '#666';
                            return qsdaColor
                        }
                        if (lowOrHight === 1) {
                            qsdaColor = 'red';
                            return qsdaColor
                        }
                        if (lowOrHight === -1) {
                            qsdaColor = 'green';
                            return qsdaColor
                        }
                    }
                }
            }
        },
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/sass/global.scss';
.team { width: 45%;}
font,.time {color: rgb(136, 136, 136);}
.nodata {
            color: #ccc;
        }
</style>