<template>
    <nav class="navbar fixed-top navbar-expand-lg bg-primary py-0" :data-bs-theme="isDark?'dark':'light'">
        <div class="container">
            <router-link :to="'/'" class="navbar-brand d-flex align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="30px" viewBox="0 0 24 24" width="30px" fill="#ffffff"><g><rect fill="none" height="24" width="24"/></g><g><g><path d="M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10s10-4.48,10-10C22,6.48,17.52,2,12,2z M13,5.3l1.35-0.95 c1.82,0.56,3.37,1.76,4.38,3.34l-0.39,1.34l-1.35,0.46L13,6.7V5.3z M9.65,4.35L11,5.3v1.4L7.01,9.49L5.66,9.03L5.27,7.69 C6.28,6.12,7.83,4.92,9.65,4.35z M7.08,17.11l-1.14,0.1C4.73,15.81,4,13.99,4,12c0-0.12,0.01-0.23,0.02-0.35l1-0.73L6.4,11.4 l1.46,4.34L7.08,17.11z M14.5,19.59C13.71,19.85,12.87,20,12,20s-1.71-0.15-2.5-0.41l-0.69-1.49L9.45,17h5.11l0.64,1.11 L14.5,19.59z M14.27,15H9.73l-1.35-4.02L12,8.44l3.63,2.54L14.27,15z M18.06,17.21l-1.14-0.1l-0.79-1.37l1.46-4.34l1.39-0.47 l1,0.73C19.99,11.77,20,11.88,20,12C20,13.99,19.27,15.81,18.06,17.21z"/></g></g></svg>
                <h1 class="fs-24 text-white mb-0 mx-1">足球广实</h1>
            </router-link>
            <button class="btn menu-btn p-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor02" aria-controls="navbarColor02" aria-expanded="false" aria-label="Toggle navigation">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="#ffffff" class="bi bi-list" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                </svg>
            </button>
            <div class="navbar-collapse collapse" id="navbarColor02" style="">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item mx-2 dropdown py-2">
                        <a class="nav-link text-white dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">QSDA数据</a>
                        <ul class="dropdown-menu">
                            <li>
                                <router-link :to="'/qsda-today-new'" class="dropdown-item nav-link">PC端</router-link>
                            </li>
                            <li>
                                <router-link :to="'/qsda-today-m'" class="dropdown-item nav-link">手机端</router-link>
                            </li>
                            <li>
                                <router-link :to="'/qsda-today-basketball'" class="dropdown-item nav-link">篮球</router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item mx-2 dropdown py-2">
                        <a class="nav-link text-white dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">数据抓取</a>
                        <ul class="dropdown-menu">
                            <li>
                                <router-link :to="'/today-crawler'" class="dropdown-item nav-link">今日比赛</router-link>
                            </li>
                            <li>
                                <router-link :to="'/tomorrow-crawler'" class="dropdown-item nav-link">明日比赛</router-link>
                            </li>
                            <li>
                                <router-link :to="'/getCheckback'" class="dropdown-item nav-link">今日回查</router-link>
                            </li>
                            <li>
                                <router-link :to="'/getCheckbackTomorrow'" class="dropdown-item nav-link">明日回查</router-link>
                            </li>
                            <!-- <li>
                                <router-link :to="'/edite-taikuang'" class="dropdown-item nav-link">修改态况</router-link>
                            </li> -->
                        </ul>
                    </li>
                    <li class="nav-item mx-2 dropdown py-2">
                        <a class="nav-link text-white dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">实用工具</a>
                        <ul class="dropdown-menu">
                            <!-- <li>
                                <router-link :to="'/checkback'" class="dropdown-item nav-link">大数据回查</router-link>
                            </li> -->
                            <li>
                                <router-link :to="'/tools-checkback'" class="dropdown-item nav-link">回查工具</router-link>
                            </li>
                            <li>
                                <router-link :to="'/tools-taikuang'" class="dropdown-item nav-link">态况数据</router-link>
                            </li>
                            <li>
                                <router-link :to="'/tools-qsda-to-handicap'" class="dropdown-item nav-link">QSDA转盘口</router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item mx-2 dropdown py-2">
                        <a class="nav-link text-white dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">数据修改</a>
                        <ul class="dropdown-menu">
                            <!-- <li>
                                <router-link :to="'/edite-taikuang'" class="dropdown-item nav-link">态况数据</router-link>
                            </li> -->
                            <li>
                                <router-link :to="'/edite-team-name'" class="dropdown-item nav-link">球队名</router-link>
                            </li>
                            <li>
                                <router-link :to="'/edite-league-name'" class="dropdown-item nav-link">联赛名</router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item mx-2 dropdown py-2">
                        <a class="nav-link text-white dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">大数据回查</a>
                        <ul class="dropdown-menu">
                            <li><router-link :to="'/checkback'" class="dropdown-item nav-link">今日电脑端</router-link></li>
                            <!-- <li><router-link :to="'/checkback-m'" class="dropdown-item nav-link">今日手机端</router-link></li> -->
                            <li><router-link :to="'/checkback-t'" class="dropdown-item nav-link">明日电脑端</router-link></li>
                            <!-- <li><router-link :to="'/checkback-tomorrow-m'" class="dropdown-item nav-link">明日手机端</router-link></li> -->
                            <li><router-link :to="'/checkback-list'" class="dropdown-item nav-link">列表</router-link></li>
                        </ul>
                    </li>
                </ul>
                <div class="d-flex align-items-center">
                    <button v-if="!localIsDark" type="button" class="btn btn-sm" @click="darkmode">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" class="bi bi-moon-stars-fill" viewBox="0 0 16 16">
                        <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z"/>
                        <path d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.734 1.734 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.734 1.734 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.734 1.734 0 0 0 1.097-1.097l.387-1.162zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L13.863.1z"/>
                        </svg>
                    </button>
                    <button v-if="localIsDark" type="button" class="btn btn-sm" @click="darkmode">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" class="bi bi-sun-fill" viewBox="0 0 16 16">
                        <path d="M8 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"/>
                        </svg>
                    </button>
                    <div class="dropdown">
                        <button type="button" class="ms-1 btn btn-sm"  data-bs-toggle="dropdown" aria-expanded="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#fff" class="bi bi-person-circle" viewBox="0 0 16 16">
                                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                                <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                            </svg>
                        </button>
                        <ul class="dropdown-menu" style="min-width: auto;">
                            <li><a class="dropdown-item fs-12">{{ this.editername }}</a></li>
                            <li><router-link :to="'/fav'" class="dropdown-item fs-12">我的收藏</router-link></li>
                            <li><a class="dropdown-item fs-12 pointer" @click="logOut()">退出</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Navbar content -->
    </nav>
</template>
<script>
import jwtDecode from 'jwt-decode';
export default {
    props: {
        isDark: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            localIsDark: false,
            editername: '',
            token: ''
        }
    },
    mounted() {
        this.getdarkmode();
        this.getUserInfo();
    },
    methods: {
        darkmode(){
            this.localIsDark = !this.localIsDark;
            this.$emit('darkmode', this.localIsDark);
            localStorage.setItem('darkmode', this.localIsDark);
            console.log(this.localIsDark,localStorage.getItem('darkmode'));
            if(this.localIsDark) {
                document.body.classList.add('NIGHT');
                document.body.style.backgroundColor = "#0d1421";
                // document.querySelector('table').classList.add('table-dark');
            }else {
                document.body.classList.remove('NIGHT');
                
                document.body.style.backgroundColor = "#fff";
                // document.querySelector('table').classList.remove('table-dark');
            }
        },
        getdarkmode() {
            let localIsDarkStr = localStorage.getItem('darkmode');
            if (null !== localIsDarkStr) {
                let boolValue = localIsDarkStr === "true"
                this.localIsDark = boolValue;
                this.$emit('darkmode', this.localIsDark);
                // console.log('有缓存',localIsDarkStr, boolValue, this.localIsDark);
                if(this.localIsDark) {
                    document.body.classList.add('NIGHT');
                    document.body.style.backgroundColor = "#0d1421";
                }else {
                    document.body.classList.remove('NIGHT');
                    document.body.style.backgroundColor = "#fff";
                }
            }else{
                this.localIsDark = this.isDark;
            }
        },
        getUserInfo(){
            this.token = `Bearer ${localStorage.getItem("token")}`;
            // console.log(this.token);
            if (this.token != '') {
                try {
                    const decodedToken = jwtDecode(this.token);
                    // console.log(decodedToken);
                    this.editername = decodedToken.name;
                    // console.log('用户名:', this.editername);
                } catch (err) {
                    console.log('获取用户名失败:', err);
                    this.logOut();
                }
            }
        },
        logOut(){
            // console.log('退出登录，清除保存的登录状态和 token');
            localStorage.removeItem('token');
            localStorage.removeItem('expirationDate');
            this.$router.push('/login');
        }
    },
}
</script>
<style lang="scss" scoped>
    @import '../../assets/sass/global.scss';
    body.NIGHT {
        background-color: #0d1421!important;
        color: #fff;
    }
    .nav-item {
        .dropdown-menu {
            min-width: 8rem; margin-top: 0;
            border-radius: 0 0 0.5rem 0.5rem;
            .nav-link { padding: 0.5rem 1rem;}
        }
    }
    .dropdown-item:hover, .dropdown-item:focus {background-color: transparent;}
    @media (max-width: 1024px) {
        .nav-item {
            .dropdown-menu {
                background-color: inherit;
                border: none;
                color: #fff;
                // padding: 0 15px;
            }
            
        }
    }
</style>