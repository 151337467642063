export function changeLeagueName(name){
    // console.log('1111111111');
    let newName = '';
    let nameMap = {
        //A
        '阿尔及利亚甲级联赛': '阿尔甲',
        '阿尔及利亚阿尔及利亚杯': '阿尔杯',
        '阿尔巴尼亚超级联赛':'阿尔巴超',
        '阿根廷甲级联赛': '阿甲',
        '阿根廷乙级联赛': '阿乙',
        '阿根廷阿根廷杯': '阿根廷杯',
        '阿根廷乙级大都会区联赛':'阿乙曼特',
        '阿塞拜疆超级联赛':'阿塞超',
        '阿塞拜疆杯赛':'阿塞杯',
        '阿拉伯联合酋长国超级联赛':'阿联酋超',
        '阿拉伯联合酋长国甲级联赛':'阿联酋甲',
        '阿拉伯联合酋长国总统杯':'阿联杯',
        '爱尔兰共和国超级联赛': '爱超',
        '爱尔兰共和国爱尔兰足总杯':'爱足杯',
        '爱沙尼亚杯赛': '爱沙杯',
        '爱尔兰共和国甲级联赛': '爱甲',
        '阿曼联赛': '阿曼联',
        '爱沙尼亚乙级联赛':'爱沙乙',
        '奧地利乙级联赛':'奥乙',
        '奧地利甲级联赛':'奧甲',
        '爱沙尼亚甲级联赛':'爱沙甲',
        //B
        '北美/中美洲中北美洲国家联赛': '美洲联',
        '巴西乙级联赛': '巴西乙',
        '巴西甲级联赛': '巴西甲',
        '北爱尔兰超级联赛': '北爱超',
        '波兰乙级联赛': '波兰乙',
        '波兰丙级联赛':'波兰丙',
        '保加利亚杯赛': '保杯',
        '玻利维亚甲级联赛':'玻利甲',
        '比利时甲级联赛':'比甲',
        '比利时乙级联赛':'比乙',
        '比利时比利时杯':'比利时杯',
        '保加利亚甲级联赛':'保超',
        '波兰甲级联赛':'波兰甲',
        '波兰超级联赛':'波兰超',
        '波兰波兰杯':'波兰杯',
        '白俄罗斯超级联赛':'白俄超',
        '白俄罗斯杯赛':'白俄杯',
        '保加利亚乙级联赛':'保乙',
        '巴林超级联赛':'巴林超',
        '巴林乙级联赛':'巴林甲',
        '秘鲁甲级联赛':'秘鲁甲',
        '巴拉圭甲级联赛':'巴拉甲',
        '波斯尼亚 - 黑塞哥维那超级联赛':'波斯甲',
        //D
        '德国德甲':'德甲',
        '德国乙级联赛':'德乙',
        '德国地区联赛': '德地区',
        '德国丙级联赛': '德丙',
        '德国德国杯':'德国杯',
        '丹麦甲级联赛':'丹麦甲',
        '丹麦乙级联赛': '丹麦乙',
        '丹麦超级联赛':'丹麦超',
        '丹麦杯赛':'丹麦杯',
        '大韩民国K联赛':'韩K联',
        '大韩民国国家联赛':'韩K3联',
        '大韩民国足协杯':'韩足总',
        //E
        '俄罗斯超级联赛':'俄超',
        '俄罗斯甲级联赛': '俄甲',
        '俄罗斯俄罗斯杯':'俄杯',
        '埃及超级联赛':'埃及超',
        '厄瓜多尔甲级联赛':'厄瓜甲',
        '厄瓜多尔乙级联赛':'厄瓜乙',
        //F
        '法国法甲':'法甲',
        '法国乙级联赛':'法乙',
        '法国全国联赛':'法丙',
        '法国法国杯':'法国杯',
        '非洲非洲国家杯资格赛':'非洲杯',
        '芬兰甲级联赛':'芬甲',
        '芬兰超级联赛':'芬超',
        '非洲世界杯非洲区资格赛':'非洲预选',
        '非洲非洲冠军联赛':'非冠杯',
        //G
        '国际友谊赛': '国际友谊',
        '欧洲欧洲足球锦标赛预选赛': '欧洲杯',
        '国际俱乐部友谊赛': '球会友谊',
        '哥伦比亚甲级联赛': '哥伦甲',
        '哥伦比亚哥伦比亚杯':'哥伦杯',
        '哥斯达黎加甲级联赛':'哥斯甲',
        '格鲁吉亚甲级联赛':'格鲁甲',
        '格鲁吉亚乙级联赛':'格鲁乙',
        '哥伦比亚乙级联赛':'哥伦乙',
        //H
        '大韩民国K联赛挑战组': '韩K2联',
        '荷兰乙级联赛':'荷乙',
        '洪都拉斯甲级联赛': '洪都甲',
        '荷兰甲级联赛':'荷甲',
        '荷兰荷兰杯':'荷兰杯',
        //J
        '捷克共和国甲级联赛':'捷甲',
        '捷克共和国乙级联赛':'捷乙',
        '捷克共和国丙级联赛':'捷丙',
        '捷克共和国捷克杯':'捷克杯',
        //K
        '卡塔尔星级联赛':'卡塔尔联',
        '卡塔尔星杯':'卡塔杯',
        '克罗地亚甲级联赛':'克亚甲',
        '科威特联赛':'科威特联',
        '肯尼亚超级联赛':'肯尼超',
        //L
        '立陶宛甲级联赛':'立陶甲',
        '立陶宛乙级联赛': '立陶乙',
        '罗马尼亚甲级联赛':'罗甲',
        '罗马尼亚乙级联赛':'罗乙',
        '罗马尼亚罗马尼亚杯':'罗杯',
        '拉脱维亚甲级联赛':'拉脱超',
        //M
        '墨西哥乙级联赛': '墨西甲',
        '墨西哥甲级联赛':'墨西联',
        '美国美国足球联赛': '美冠联',
        '美国职业大联盟': '美职业',
        '缅甸国家联赛':'缅甸联',
        '马来西亚甲级联赛':'马来超',
        '马来西亚马来西亚杯': '马来杯',
        '摩洛哥甲级联赛':'摩洛超',
        //N
        '挪威超级联赛':'挪超',
        '挪威甲级联赛':'挪甲',
        '挪威乙级联赛':'挪乙',
        '南非超级联赛':'南非超',
        '尼加拉瓜超级联赛':'尼拉甲',
        '南美洲南美杯':'南球杯',
        '南美洲世界杯南美区资格赛':'南美预选',
        //O
        '欧洲欧冠':'欧冠',
        '欧洲欧洲协会联赛':'欧会杯',
        '欧洲欧罗巴':'欧罗巴杯',
        //P
        '葡萄牙超级联赛':'葡超',
        '葡萄牙甲级联赛':'葡甲',
        '葡萄牙联赛杯':'葡联杯',
        '葡萄牙葡萄牙杯':'葡杯',
        //R
        '日本日本足球联赛':'日足联',
        '日本J2联赛': '日职乙',
        '日本J3联赛': '日丙',
        '日本J1联赛': '日职联',
        '瑞典乙级联赛': '瑞典乙',
        '克罗地亚乙级联赛': '克亚乙',
        '瑞典超级联赛':'瑞典超',
        '瑞典甲级联赛':'瑞典甲',
        '瑞士超级联赛':'瑞士超',
        '瑞典附加赛 1/2':'瑞典甲升',
        // '瑞士甲级联赛':'瑞士甲',
        '瑞士挑战联赛':'瑞士甲',
        //S
        '斯洛伐克甲级联赛': '斯伐甲',
        '斯洛文尼亚甲级联赛':'斯亚甲',
        '斯洛文尼亚乙级联赛': '斯亚乙',
        '斯洛文尼亚斯洛文尼亚杯':'斯尼杯',
        '斯洛伐克杯赛':'斯伐杯',
        '苏格兰超级联赛':'苏超',
        '苏格兰甲级联赛':'苏甲',
        '苏格兰乙级联赛':'苏乙',
        '苏格兰挑战杯': '苏挑杯',
        '苏格兰联赛杯': '苏联杯',
        '塞尔维亚甲级联赛': '塞尔甲',
        '萨尔瓦多超级联赛': '萨尔超',
        '苏格兰冠军联赛': '苏冠',
        '沙特阿拉伯职业足球联赛':'沙特联',
        '沙特阿拉伯甲级联赛':'沙地甲',
        '塞尔维亚超级联赛':'塞尔超',
        '斯洛伐克超级联赛':'斯伐超',
        '塞浦路斯甲级联赛':'塞浦甲',
        '塞浦路斯杯赛':'塞浦杯',
        //T
        '泰国超级联赛':'泰超',
        '泰国甲级联赛':'泰甲',
        '土耳其超级联赛':'土超',
        '土耳其甲级联赛':'土甲',
        '土耳其土耳其杯':'土杯',
        '突尼斯甲级联赛':'突尼甲',
        //W
        '乌茲別克斯坦超级联赛': '乌兹超',
        '乌拉圭甲级联赛': '乌拉甲',
        '乌拉圭乙级联赛': '乌拉乙',
        '威尔士威尔士杯': '威杯',
        '威尔士超级联赛': '威超',
        '乌克兰超级联赛':'乌克超',
        '委內瑞拉甲级联赛':'委内超',
        '危地马拉甲级联赛':'危地甲',
        //X
        '西班牙西甲':'西甲',
        '西班牙足协甲': '西协甲',
        '西班牙西乙': '西乙',
        '西班牙国王杯':'西杯',
        '希腊超级联赛 2': '希腊甲',
        '希腊超级联赛':'希腊超',
        '希腊杯赛':'希腊杯',
        '匈牙利甲级联赛':'匈甲',
        '匈牙利匈牙利杯':'匈杯',
        '香港甲级联赛':'香港甲',
        '香港菁英杯':'港菁杯',
        //Y
        '英格兰英超':'英超',
        '英格兰甲级联赛': '英甲',
        '英格兰乙级联赛': '英乙',
        '英格兰非职业 北/南': '英议南/北',
        '英格兰足球联赛战利杯': '英锦赛',
        '英格兰足总杯': '英足总杯',
        '英格兰冠军联赛':'英冠',
        '英格兰非职业联赛':'英议联',
        '英格兰联赛杯':'英联杯',
        '英格兰非联赛超级':'英南/北超',
        '英格兰战利杯':'英挑杯',
        '亚美尼亚甲级联赛': '阿美超',
        '伊朗甲级联赛': '伊朗超',
        '伊朗乙级联赛': '伊朗甲',
        '印尼超级联赛':'印尼超',
        '印尼乙级联赛':'印尼甲',
        '澳大利亚A联赛':'澳洲甲',
        '意大利意甲':'意甲',
        '意大利乙级联赛':'意乙',
        '意大利丙级联赛':'意丙',
        '意大利意大利杯':'意杯',
        '意大利丙级意大利杯':'意丙杯',
        '越南V联赛':'越南联',
        '越南杯赛':'越南杯',
        '印度超级联赛':'印度超',
        '印度I联赛':'印度甲',
        '亚洲亚冠':'亚冠杯',
        '亚洲亚洲足协杯':'亚协杯',
        '亚洲世界杯亚洲区资格赛':'亚洲预选',
        '约旦联赛':'约超联',
        '牙买加超级联赛':'牙买超',
        '约旦甲级联赛':'约旦甲',
        '以色列甲级联赛':'以甲',
        '以色列超级联赛':'以超',
        //Z
        '中国中超': '中超',
        '中国中甲': '中甲',
        '智利甲级联赛':'智利甲'
    }
    newName = nameMap[name] || name;
    return newName;
}

export function changeTeamName(name){
    // console.log('22222222');
    let newName = ''
    let nameMap = {
        'Pogoń Szczecin II': '沙斯辛II队',
        '普洛尼亚比托姆': '比托姆',
        'Chacaritas': '查卡里塔斯',
        '查克永恒': '查科',
        '哥达拿查拉大学':'雷昂內斯',
        /**A**/
        '爱尔兰共和国':'爱尔兰',
        '新西兰':'纽西兰',
        //阿甲
        '阿根廷独立': '独立队',
        '圣洛伦索': '圣罗伦素',
        '萨斯菲尔德':'沙士菲',
        '班菲尔德':'班菲特',
        '巴拉卡斯中央队':'巴洛克斯',
        '拉普拉塔体操':'甘拿斯亚',
        '蒙特维多竞技':'竞赛会',
        '博卡青年':'小保加(博卡青年)',
        '科尔多瓦学院':'联合队',
        '罗萨里奥中央':'罗沙里奧中央',
        '阿根廷青年人':'小阿根廷人',
        '飓风队':'乌拉坎',
        '萨兰迪兵工厂':'沙兰迪兵工厂',
        '图库曼竞技':'图库曼体育会',
        '塔勒瑞斯':'塔勒雷斯',
        '科尔多瓦中央SDE': '科尔多瓦圣地亚哥中央队',
        '拉尼洛斯': '拉尼罗斯',
        //阿尔甲
        '帕拉多':'巴拉特',
        'CS康斯坦丁':'CS康桑汰',
        'ES Sétif':'瑟特福',
        '卡比利':'卡比利亚',
        'NC Magra':'NC马格拉',
        '本阿科诺':'ES本阿克诺',
        'Saoura':'绍乌拉',
        'El Bayadh':'巴亚兹',
        'MC阿尔及':'MC阿尔及尔',
        //阿塞超
        '萨姆加耶特':'苏姆盖特',
        'FK吉兰': '卡巴拉',
        'Araz': '阿塞纳希切万',
        '甘亚':'卡帕',
        //阿塞杯
        'Araz Saatlı':'阿拉兹萨特利',
        'İrəvan':'伊尔万FK',
        //爱尔兰
        'Kerry': '凯瑞',
        '韦克斯福德青年':'韦克斯福德',
        '阿斯隆城':'亚隆城',
        '沃特福德联队':'瓦特福德',
        //爱沙
        'Slavoj Trebišov': '斯拉沃',
        'FC Tallinn': '塔林',
        'Viimsi': '维玛斯',
        'Laagri': '哈留足球学院',
        '诺梅卡柳': '卡里鲁',
        'Paide II':'派德 U21',
        //阿美超
        'West Armenia':'西亚美尼亚',
        '艾拉华特': '阿拉特',
        '叶里温凤凰':'佩历克',
        '艾拉斯克特':'阿拉斯克',
        'BKMA':'BKMA叶里温',
        '斯拉克': '舒拉克',
        '诺亚FC': '诺亚',
        //阿联酋超
        'Al Bataeh': '巴塔',
        '阿积曼': '阿基曼',
        '迪拜哈塔': '哈塔',
        '华斯尔': '瓦斯尔杜拜',
        //阿曼联
        '阿尔维达': '艾尔维达',
        //爱超
        '谢尔伯恩': '舒尔本',
        //奥乙
        'SV霍恩': '霍恩',
        '多恩比纳': '多恩比恩',
        '阿姆斯特顿': '阿姆施泰滕',
        '卡芬堡': '卡芬堡',
        '斯特利费宁':'斯崔普费宁魏登',
        '格拉茨飓风B队':'格拉茨风暴 (青年)',
        /**B**/
        //巴西甲
        '巴拉纳竞技':'红牛伯拉根森SP',
        '巴甘蒂诺':'红牛伯拉根森SP',
        '弗鲁米嫩塞':'富明尼斯RJ',
        '戈伊亚斯':'奎亚斯GO',
        '克鲁塞罗':'高士路MG',
        '巴伊亚':'巴海亚BA',
        '帕尔梅拉斯':'彭美拉斯SP',
        '圣保罗':'圣保罗SP',
        '奎尔巴':'古亚伯MT',
        '科林蒂安':'哥连泰斯SP',
        '格雷米奥':'甘美奥RS',
        '弗拉门戈':'法林明高RJ',
        '瓦斯科达伽马': '斯泰伦博斯',
        '巴西国际': '国际体育会RS',
        //巴西乙
        'ABC纳泰': 'ABC RN',
        '奥瓦': '阿瓦伊SC',
        '塞阿拉': '卡尔拉CE',
        '累西腓体育': '利斯菲体育会PE',
        '沙佩科恩斯': '乔沛高恩斯SC',
        '托姆本斯': '通布斯MG',
        //保杯
        'Spartak Pleven': '多布鲁札赫',
        'Hebar 1918': '赫巴',
        //保超
        'Levski Krumovgrad':'利夫斯基克鲁莫夫格勒',
        'Etar':'伊塔尔',
        '普罗夫迪夫博特夫': '贝迪夫',
        '贝尔罗': '比罗',
        '卢多格德斯': '卢多戈雷茨',
        '赫巴': '赫贝尔帕扎尔吉克',
        '阿尔艾利': '阿尔艾利开罗',
        '巴拉迪亚特': '巴拉蒂耶',
        //保乙
        '多布鲁扎1919':'多布鲁札赫',
        '洛维治':'列迪斯洛维治',
        //保乙
        'CSKA 1948 Sofia II': '索菲亚1948中央陆军II队',
        "布丁维丁": "泊汀维登",
        'Chernomorets Burgas': '布尔加斯',
        'Ludogorets II': '卢多格德斯II队',
        '贝拉斯萨': 'PFC比拉斯萨',
        '多布鲁察': '多布鲁札赫',
        '马雷克': '马历克',
        '巴尔齐克切尔诺莫雷茨': '贝尔奇克',
        '马里萨': '马里萨',
        '斯沃格体育者': '斯普地特',
        '严特拉': '延特拉',
        '斯特鲁姆斯卡': '斯图玛斯卡萨拉瓦',
        '塞普特姆里': '塞普特姆里',
        '蒙塔纳': 'PFC蒙大拿',
        'Dunav': '多恼',
        //巴林超
        'Khalidiya':'哈尔迪亚',
        '穆哈拉克':'慕哈瑞克',
        '麦纳麦阿尔艾利':'阿希利',
        '普雷斯科夫':'朴卢斯高',
        '麦纳麦艾尔纳吉马': '拿加马麦纳麦',
        '哈尔迪亚': '卡利迪亚',
        //巴林甲
        '夸拉利':'加拉利',
        '马尔基亚':'马勒基亚',
        '麦纳麦巴林FC':'巴林队',
        "A'Ali":'阿阿里',
        //巴拉甲
        'Resistencia':'雷西斯坦西亚',
        'General Caballero JLM':'卡瓦耶罗JLM',
        '自由队':'利伯泰德',
        'Sportivo Ameliano':'阿梅里亚诺体育',
        '特里尼登斯体育':'泰里尼登斯',
        '亚松森瓜拉尼':'瓜拉尼',
        '亚松森奥林匹亚':'奥林匹亚',
        '波特诺山丘': '波特诺',
        //比乙
        '标准列日 II': '标准列治 (青年)',
        'KMSK丹泽': '丹尼兹',
        //'比利时杯
        'Knokke': '科若克',
        //波兰
        'Lech Poznań II':'波兹南波兰II',
        'Radunia Stężyca': '拉杜尼亚河',
        '莱克普斯纳':'波兹南莱希',
        //波兰超
        '哥罗纳': '科罗纳',
        //波兰甲
        'GKS卡托威斯': '卡托华斯',
        '华沙普洛尼亚': '华沙普隆尼亚',
        //波兰乙
        '鲁宾B队': '路宾II队',
        'GKS贝查特': 'GKS贾特雷比',
        '图伦努库斯': '图兰',
        //'波兰杯
        '勒基亚华沙B队': '华沙莱吉亚II队',
        //波斯甲
        '图兹拉市': '图兹拉城',
        '加贝拉': '加比拉',
        //北爱超
        'Newry City': '纽维城',
        //白俄超
        '鲍里索夫巴特':'波里索夫巴特',
        '贝尔辛纳': '伯希纳博布鲁伊斯克',
        '高美尔': '戈梅利',
        //白俄甲
        'Ostrovets FC':'奥斯特罗维茨',
        /**C**/
        /**D**/
        //丹麦甲
        'B 93': 'B93哥本哈根',
        '桑德捷斯基': '桑德捷斯基',
        '奈斯维德': '奈司特维德',
        '文德斯尔': '文德斯尔',
        //'丹麦杯
        '希尔星格': '赫尔辛格',
        '利恩比': '宁比',
        //德甲
        '柏林联合':'柏林联',
        '那不勒斯':'拿玻里',
        //德乙
        '布伦斯维克 ': '布轮斯维克',
        '威恒威斯巴登': '韦恩威斯巴登',
        '罗斯托克': '汉莎罗斯托克',
        '马德堡': '马格德堡',
        '艾尔维斯堡': '艾禾斯堡',
        //德丙
        '翁特哈辛': '翁特哈兴',
        '弗莱堡业余队':'费雷堡 (青年)',
        '霍勒思切尔':'哈雷斯彻',
        //'德国杯
        '洪堡': '侯姆堡',
        //德地区
        '诺德斯特德':'诺德施泰特',
        '维切福伦斯堡':'维切弗伦斯堡',
        '科隆B队':'科隆 (青年)',
        '施威恩福特':'斯科维夫',
        '法兰克福业余队':'法兰克福 (青年)',
        '弗雷堡':'SGV弗莱伯',
        '门兴格拉德巴赫业余队':'门兴格拉德巴赫 (青年)',
        '巴姆堡':'古特斯劳',
        '圣保利业余队':'圣保利 (青年)',
        '奥尔登伯格':'奥尔登堡',
        '爱伦堡':'艾伦堡',
        'Altglienicke':'阿格利尼克',
        '利浦斯塔德': '利普施塔特',
        '勒丁豪森': '洛顶豪森',
        '拜仁慕尼黑业余队': '拜仁慕尼黑 (青年)',
        '伯格豪森': '堡格侯森',
        'BFC迪纳摩': '柏林迪那摩',
        'Viktoria Berlin': '维多利亚1889柏林',
        '黑森卡塞尔': '赫森卡瑟',
        '奥芬巴赫踢球者': '奥芬巴查踢球者',
        '弗图纳科隆': '科隆福图纳',
        '洛特维斯亚伦': '艾伦',
        'BW Lohne': '布劳韦斯洛恩',
        'Phönix Lübeck': '凤凰吕贝克',
        'Teutonia Ottensen': '特乌托尼亚奥藤森',
        'Spelle-Venhaus': '施佩莱文浩斯',
        'Gütersloh': '古特斯劳	',
        'Düren Merzenich': '迪伦',
        'Aubstadt': '奥布斯塔特',
        'Augsburg II': '奥斯堡 (青年)',
        '格雷特霍夫业余队': '安斯巴赫',
        'Vilzing': '维勒兹',
        'Würzburger Kickers': '维尔茨堡踢球者',
        'Türkgücü München': '特库古库慕尼黑',
        '沙尔丁': '斯切丁喜宁',
        'Schott Mainz': '肖特美因茨',
        'Paderborn II':'派特博恩 (青年)',
        '施泰因巴赫':'TSV施泰因巴赫',//捷豹
        '斯图加特业余队':'史特加青年队',//捷豹
        '鸟特斯克腾':'尤特斯科腾',
        '霍芬海姆业余队':'霍芬海姆 (青年)',
        'Barockstadt Fulda-Lehn.':'巴洛克斯塔特',
        '梅塞尔维茨': '马瑟维兹英',
        '兹维斯考': '茨维考',
        /**E**/
        //俄甲
        'Leningradets': '列宁格雷德',
        '辛尼克':'辛历克',
        //'俄杯
        '巴蒂卡': '巴提卡',
        '格罗兹尼特里克': '格罗兹尼艾哈迈德',
        'Forte Taganrog':'福特塔甘罗格',
        //厄瓜甲
        '基多天主大学':'卡托利卡基多',
        'Libertad': '利伯泰迪',
        'Gualaceo': '瓜拉塞奥',
        '巴西瓜拉尼': '古兰尼SP',
        '博塔弗戈SP': '博塔福格SP',
        '新奥里藏蒂诺': '甘美奥诺瓦里桑蒂诺SP',
        '庞特普雷塔': '邦迪比达SP',
        'EL国民队': '利伯泰德',
        '瓜亚基尔城': '雷西斯坦西亚',
        '西雅图音速': '西雅图海湾人',
        '达拉斯FC': '达拉斯',
        //厄瓜乙
        'Independiente Juniors': '独立 (青年)',
        'Cuniburo': '库尼布洛',
        '桑托斯': '山度士SP',
        '科里蒂巴': '哥里迪巴PR',
        '国民竞技': '国家体育会',
        //埃及超
        'Ceramica Cleopatra':'切拉米卡克里奥帕特拉',
        'Pyramids': '金字塔',
        '艾尔马斯里': '马萨雷',
        '盖斯': '塔拉雅',
        '艾尔达克耶赫': '达克尔野',
        '伊斯迈利': '依斯麦里',
        /** F **/
        //非洲预选
        'São Tomé e Príncipe':'圣多美和普林西比',
        'South Sudan':'南苏丹',
        //非冠杯
        '辛巴':'辛巴SC',
        'ASEC Mimosas':'ASEC密模沙',
        //法甲
        '巴黎圣日尔曼':'巴黎圣日耳曼',
        '克莱蒙': '克莱蒙特',
        //法丙
        'Marignane Gignac CB':'吉尼亚克',
        '维尔塞勒斯':'凡尔赛',
        '阿泽古斯':'莫提斯朵尔',
        '尼奥特':'尼奥尔',
        //芬超
        '塞那乔其':'施拿钟基',
        '奥卢':'AC奥卢',
        /** G **/
        //格鲁甲
        '加格拉': '葛吉拉',
        '古泰斯鱼雷': '古泰斯拖比度',
        //格鲁乙
        'Dinamo Tbilisi II':'提比利锡戴拿模II队',
        'Spaeri':'斯帕尔',
        'Gareji':'加雷捷',
        '梅拉尼':'玛瑞尼马尔特维利',
        '诺奇迪纳摩利':'玛瑞尼第比利斯',
        '柯尔克迪':'科克禾迪',
        //哥伦甲
        '托利马体育':'托利马',
        '佩雷拉体育':'佩雷拉',
        '巴兰基亚青年':'小巴兰哈拉',
        '百万富翁':'米伦拿列奥',
        '茨高':'奇科',
        '肯萨斯竞技':'堪萨斯城体育会',
        '圣何塞地震':'圣荷西地震',
        '云斯卡尔德斯': '卡尔打斯',
        '拉努斯': '拉鲁斯',
        '泰格雷': '堤格雷',
        '纽维尔老男孩': '纽维尔旧生',
        '戈多伊克鲁斯': '葛度尔古斯',
        //哥伦乙
        'Llaneros':'拉尼洛斯',
        '巴兰奎拉':'巴瑞库拉',
        '阿瓜布兰卡': '卡利竞技',
        '古古达体育': '库库塔',
        '萨尔米安杜': '萨尔米安杜竹宁',
        '森塔罗斯': '卡利博卡 (青年)',
        '里奥内格罗体育': '利昂内斯',
        '尤文图德': '尤文都德RS',
        '皇家卡塔根纳': '喀他基那',
        //哥斯甲
        '桑托DG': '山度士DG',
        '肯塔吉内斯': '肯塔吉內斯',
        '阿吉拉斯': '幕尼西波利比利亚',
        '圣何塞体育': '体育圣何西',
        '萨普里萨体育': '沙普利萨',
        '森卡洛斯': '珊卡洛斯',
        /** H **/
        //'荷兰杯
        'FC利塞': '利斯',
        'RKSV格罗尼斯特尔': 'RKSV海勒希德',
        '海伦芬': '海伦维恩',
        '海尔德费嫩达尔': 'GVVV云尼达尔',
        '罗斯马伦OJC': '洛斯马伦',
        '阿尔梅勒城': '阿尔默勒城',
        '迅速男孩': '迅速青年队',
        //荷甲
        '尼美根': '奈梅亨',
        //'荷乙
        '乌德勒支青年队': '乌德勒支 (青年)',
        //洪都甲
        'Olancho':'奥兰乔FC',
        'CD维多利亚':'维多利亚拉塞巴',
        'Génesis':'吉恩希斯',
        //韩K2联
        '尚州尚武': '金泉尚武',
        '金浦市民': '金浦',
        '首尔埃兰':'首尔衣恋',
        '城南FC':'城南足球俱乐部',
        '大田市民':'大田韩亚市民',
        '江原FC':'江原',
        //韩K3联
        'Busan Transportation':'釜山运输',
        'Gangneung City': '江陵',
        'Gimhae City': '金海市',
        'Hwaseong': '华城',
        'Yangpyeong':'杨平',
        //韩K联
        '大邱FC':'大邱',
        '光州FC':'光州',
        /** I **/
        /** J **/
        //捷丙
        'Karviná II':'卡尔维纳B队',
        '哲诺伊摩':'兹诺伊摩',
        'Frýdek-Místek':'弗雷德克',
        '斯洛瓦克B队':'斯洛瓦科B队',
        'Uherský Brod':'乌赫尔布罗德',
        'Hodonín':'赫杜尼',
        'Start Brno':'斯达布尔诺',
        '兹林B队':'辛宁B队',
        'Tatran Brno Bohunice':'TJ塔特拉',
        '特里内茨':'特里耐克',
        'Frýdlant':'弗里德兰特',
        //捷乙
        '伊赫拉瓦': '伊希拉瓦',
        '普日布拉姆': '普利布兰',
        //捷克杯
        'Slovan Velvary':'斯拉夫韦尔瓦力',
        '奥帕瓦':'欧帕华',
        /** K **/
        //肯尼超
        'Bidco United':'比迪科联',
        '图斯克':'图斯科',
        //科威特联
        '艾沙比KUW': '沙巴伯科威特',
        '萨尔米亚': '沙米亚',
        //克亚甲
        '奥斯杰克':'奥斯积克',
        '斯普利特海杜克':'夏积杜克',
        //克亚乙
        'Sesvete':'塞瓦特',
        'Bijelo Brdo':'比耶洛布尔多',
        'Jarun':'贾伦',
        'Dubrava Zagreb': '杜布拉瓦汀卡伯',
        'Orijent 1919': '奥洁特',
        '贾伦': '加伦',
        'Zrinski Jurjevac': '吉尔杰耶瓦克',
        'Croatia Zmijavci': '兹米加维兹',
        /** L **/
        //立陶甲
        'FA Šiauliai': '斯奥利埃',
        '巴提加': '帕纳瓦兹',
        'National Bank of Egypt': '埃及国家银行体育会',
        'Future': '富图雷',
        //立陶乙
        'FA Šiauliai II':'斯奥利艾B队',
        '扎基里斯B队':'萨尔基利斯B队',
        'Riteriai II': '瑞特瑞埃B队',
        'Be1 NFA': 'NFA卡奥拿斯',
        'Garliava':'加尔利阿瓦',
        'Panevėžys II':'帕纳瓦兹B队',
        '伊克兰纳斯':'伊兰拿斯',
        'Neptūną Klaipėda':'海王星克莱佩达',
        '列塔瓦':'约纳瓦',
        'Kauno Žalgiris II':'考诺萨基列斯B队',
        '萨尔基利斯B队':'萨基列斯B队',
        //罗甲
        '维托鲁康斯坦萨': '法乌尔',
        '阿拉德联队': 'UTA亚拉德',
        '沃伦塔利': '沃伦塔里',
        '圣格奥尔基塞普西': '圣格奥尔基塞普西',
        'CS卡拉奥华大学': '卡拉奥华1948',
        'UTA亚拉德': 'UTA亚拉德',
        //罗乙
        'CSA布加勒斯特星': 'CSA布格勒斯特星队',
        'CSKA索菲亚': '索菲亚1948中央陆军',
        'Slatina': 'CSM斯拉蒂纳',
        '斯洛博齐亚': '尤尼史洛波西亚',
        '辛迪泰哥维斯特':'辛迪亚泰哥维斯特',
        '米奥文尼':'米文尼',
        /** N **/
        //尼拉甲
        '沃尔特费雷迪': '沃特尔菲尔蒂',
        'H&H Export': '艾斯波特塞瓦科',
        'Diriangén': '迪利安格恩',
        'Real Estelí': '艾斯特利',
        //挪乙
        'Junkeren': '祖克伦',
        '史卓加斯特B队': '斯托姆加斯特B队',
        /** M **/
        //缅甸联
        '伊洛瓦底': '阿耶亚瓦底联',
        //南非超
        '阿玛祖鲁':'艾玛祖鲁',
        '超级体育联队': '超级联队',
        'Royal AM': '皇家阿姆',
        '德比尔图斯': '恩特拉',
        '雷卡纳特斯': '热坎特瑟',
        'Potenza Calcio': '波坦察',
        '萨格勒布迪纳摩': '萨格勒布戴拿模',
        '博多格林特': '博德闪耀',
        '贝西克塔斯': '贝西克塔斯',
        '萨连斯基': '瑞尼斯基',
        '帕纳辛纳科斯': '帕纳辛奈科斯',
        '谢里夫': '舒列夫',
        '塞尔维特': '塞维特',
        'Sekhukhune United':'瑟库库内联',
        'Chippa United':'茨普帕',
        '姆普马兰加黑王牌':'开普敦城',
        'Richards Bay':'理查兹湾',
        '华斯高RJ':'斯泰伦博斯',
        '莫罗卡':'燕子',
        'TS Galaxy':'TS银河',
        //美洲联
        '圣卢西亚':'圣露西亚',
        'Bonaire': '博奈尔',
        '圣马丁':'荷属圣马丁',
        'Eimsbütteler TV': '恩斯巴特勒',
        '德罗科特森': '德罗赫特森阿塞尔',
        '圣文森特':'圣文森及格瑞那丁',
        '伯利兹':'贝里斯',
        //美职业
        '辛辛那提': '辛辛那堤',
        //美冠联
        'Hartford Athletic': '哈特福德竞技',
        'FC Tulsa': '塔尔萨奥克拉荷马',
        'New Mexico': '新墨西哥联',
        'United	Memphis 901': '孟菲斯901',
        'Detroit City': '底特律市',
        'Birmingham Legion': '伯明翰军团',
        'Louisville City': '路易斯维尔城',
        'Colorado Springs': '科罗拉多斯普林斯',
        'Phoenix Rising': '凤凰重生',
        'Rio Grande Valley': '里奥格兰德河谷',
        'Loudoun United': '劳顿联',
        'San Antonio': '圣安东尼奥',
        'Indy Eleven': '印地十一',
        'Monterey Bay': '蒙特雷湾',
        'Orange County SC': '橘郡',
        'Oakland Roots':'奥克兰根',
        'El Paso Locomotive':'巴索火车头',
        'Sacramento Republic':'萨克拉门托',
        'Miami FC':'迈阿密FC',
        'Las Vegas Lights':'拉斯维加斯光',
        'San Diego Loyal':'圣地亚哥忠诚',
        //美冠联
        'New Mexico United': '新墨西哥联',
        'Memphis 901': '孟菲斯901',
        '橘郡':'奥兰治县布鲁斯',
        '巴索火车头':'埃尔帕索机车',
        //'墨西联
        '托卢卡': '托拉卡',
        //墨西甲
        'CA La Paz': 'CA拉巴斯',
        '莱昂':'利昂',
        '阿特拿斯':'艾特莱',
        '扎卡特卡斯':'麦拉斯萨卡特卡斯',
        '帕蒂特兰德莫雷洛斯':'特帕蒂特兰',
        '瓦哈卡':'奥哈卡',
        '特拉斯卡拉FC': '提拉卡拉',
        //秘鲁甲
        '水晶竞技队':'水晶竞技',
        'ADT': 'AD塔尔马',
        '卡洛斯曼努西': '曼努次',
        'Atlético Grau': '格拉乌',
        'Unión Comercio': '科美西欧',
        'Deportivo Garcilaso': '加西拉索',
        '利马联盟': '阿里萨利马',
        '阿利亚加': '阿里萨竞技',
        //马来超
        '皇家警察': '皇家警察足球俱乐部',
        '吉隆坡': '吉隆坡市',
        'Kelantan United':'吉兰丹联',
        /** O **/
        //欧洲杯
        '亚美尼亚':'阿美尼亚',
        '威尔士':'威尔斯',
        //欧会杯
        '卡拉卡斯维克': 'KI凯拉维克',
        '巴利卡尼': '巴尔干尼',
        '阿斯坦纳': '艾斯坦拿',
        '艾克马亚': '阿尔克马尔',
        //欧罗巴
        '巴奇卡托波拉': 'TSC巴奇卡托波拉',
        '弗赖堡': '费雷堡',
        '艾里斯利马斯素尔': '艾里斯',
        '皇家贝蒂斯': '皇家贝迪斯',
        /** P **/
        //葡杯
        '卡马查':'加马查',
        'Canelas 2010':'卡内拉斯',
        'FC Serpa':'塞尔帕',
        'Atlético Malveira':'马尔韦拉竞技',
        '帕雷德斯':'帕瑞德斯',
        'Dumiense':'杜米恩斯',
        //葡超
        '艾路卡': '阿鲁卡',
        '摩里伦斯': '莫雷伦斯',
        '维薛拿': '维赛拉',
        //葡甲
        '佩纳菲尔': '彭拿费尔',
        '维拉维登斯': '维拉云登斯',
        /** Q **/
        /** R **/
        //日丙
        'Nara Club': '奈良俱乐部',
        'Imabari': '今治',
        'YSCC': '横滨体育',
        'Tegevajaro Miyazaki': '特格瓦嘉洛宫崎',
        'Nagano Parceiro': '长野琶扼搂',
        'Kamatamare Sanuki': '赞岐',
        'Osaka': '大阪俱乐部',
        'Vanraure Hachinohe': '万劳雷八户',
        'Kagoshima United': '鹿儿岛联',
        'Azul Claro Numazu': '浅蓝沼津',
        //日职联
        '名古屋鲸八': '名古屋鲸鱼',
        'FC东京':'东京',
        '札幌冈萨多':'北海道札幌冈萨多',
        '横滨FC':'横滨',
        '湘南比马':'湘南海洋',
        //日职乙
        '山口雷诺':'山口雷法',//瑞士超
        '卢森': '卢塞恩',
        '斯卢萨可': '斯鲁特斯克',
        //瑞士甲
        '尼昂': '尼昂奈斯',
        '贝林佐纳': '贝林佐纳',
        '锡昂': '锡永',
        '纳沙泰尔': '纳沙泰尔',
        'LKS洛迪兹': 'LKS罗兹',
        //瑞典
        'Stockholm Inter':'斯德哥尔摩国际',
        'Olympic': '奥林匹克',
        'Vänersborgs IF': '维安斯博格斯',
        'Torns': '托纳斯',
        'United Nordic': '北欧联',
        'IF Karlstad': 'IF卡尔斯塔德',
        'Hammarby Talang': '哈马比TFF',
        'Örebro Syriansk': '奥雷布洛西里安斯卡',
        'Ängelholm': '安吉尔霍姆斯',
        'Ariana': '阿里安娜',
        'Eskilsminne': '艾斯基斯迈',
        'Tvååker': '特瓦克斯',
        'Umeå FC': '于默奥',
        'Stocksund': '斯托克松德',
        'Täby': '泰比',
        '乌特斯克腾':'乌席登斯',
        '奥雷布洛':'奧雷布洛',
        '松兹瓦尔':'桑斯瓦尔',
        //瑞典超
        '哥德堡': 'IFK哥德堡',
        /** S **/
        //沙特联
        '德咸科': '达马克',
        '艾尔阿克霍德': '奥克杜德',
        '阿尔卡利杰赛哈特': '卡赫利塞哈特',
        '塔亚文': '塔亚文布莱达',
        '拉德': '雷德',
        '艾维赫达': '艾尔维达',
        '阿尔阿赫利': '巴柏阿尔艾利杜拜',
        '纳萨': '纳萨杜拜',
        //斯亚甲
        '佩利根': '采列',
        '多明萨尔': '多姆扎莱',
        '弗拉德': '弗兰候萨斯坦',
        'Caspian Qazvin': '加兹温',
        //斯亚乙
        'Bilje':'比列',
        'Brinje Grosuplje':'布林杰格罗苏普列',
        'Bistrica':'比斯特里察',
        'Fužinar':'福兹纳',
        '纳夫塔':'南塔',
        'Ilirija':'伊利利甲',
        //斯伐超
        '伯德布雷佐夫': '波德布雷佐瓦',
        'Dukla Banská Bystrica': '拜斯迪卡',
        //斯伐杯
        'Lokomotíva DNV':'德文斯卡火车头',
        '利帕尼':'里帕尼',
        '米加瓦斯巴达':'迈加华斯巴达',
        'Skalica':'斯卡利卡',
        'Galanta':'斯洛云加兰塔',
        '克曼尼':'FK霍曼尼',
        '兹利纳B队':'斯利纳B队',
        '马尔泽尼斯':'马勒泽尼斯',
        //斯亚乙
        'Primorje':'比摩治',
        'Beltinci':'贝尔廷齐',
        '特里格拉夫':'拖里格拉瓦',
        '德拉维亚':'德拉维尼查',
        '克尔卡':'科瑞卡',
        '贾德兰德卡尼':'加德兰代卡尼',
        //斯伐甲
        'Dolný Kubín': '库宾',
        'Spišská Nová Ves': '诺瓦维斯',
        'Humenné': '克曼尼',
        'Malženice': '马尔泽尼斯',
        'Považská Bystrica': '波瓦茲斯卡',
        // 'Komárno': '科马尔诺',
        'Petržalka': '阿特梅迪亚',
        '诺瓦维斯':'斯比锡卡',
        '普卓夫':'贝塞夫',
        '沙莫林':'萨蒙林',
        //苏格兰
        'East Kilbride': '东基尔布莱德',
        'Kelty Hearts': '凯尔蒂赫斯',
        '斯登后塞穆尔':'士登后斯莫亚',
        '邓迪FC':'邓迪',
        //塞尔超
        'Železničar Pančevo':'泽伦斯尼卡潘切沃',
        'IMT Novi Beograd':'诺维贝尔格莱德',
        '伊万尼卡贾沃尔': '渣华尔',
        //塞尔甲
        'Mladost Novi Sad': '马拉多特诺维萨德',
        'OFK Vršac': '维尔沙克',
        'Radnički Sr. Mitrovica': '米特罗维察',
        'Smederevo 1924':'斯梅德列沃',
        'FK梅塔拉卡': '梅塔拉',
        '多波尼卡': '杜博西卡',
        //'塞浦甲
        '奥特罗斯': '阿西埃努',
        //塞浦杯
        'Omonia 29is Maiou':'奥摩尼亚29M',
        //萨尔超
        'Dragón':'德拉贡',
        'Fuerte San Francisco':'旧金山富尔特',
        '梅塔帕':'艾息多',
        'FC阿里安沙':'阿利安萨圣萨尔瓦多',
        //苏冠
        '女王公园': '京士柏',
        '摩顿': '格里诺克慕顿',
        '拉茨流浪者': '威夫流浪队',
        '艾尔基尔联': '艾积尔奈恩斯',
        'Cacereño': '卡些连奴',
        //沙地甲
        '瓜达席亚':'吉达',
        '阿尔拉比亚':'瓜达席亚',
        'Al Bukayriyah':'布凯里耶',
        '阿尔夸索马':'卡索马',
        // '阿尔阿拉比':'阿拉毕KSA',
        'Al Jandal':'阿尔赞达尔',
        'Al Taraji':'塔拉基',
        'Al-Ain':'艾因巴哈',
        '哈杰尔':'哈哲尔',
        '阿尔纳吉马':'阿尔纳泽马',
        'Al Kholood':'科路德',
        '艾尔夸迪斯亚':'瓜达席亚',
        //沙地
        // '阿拉毕KSA': '阿拉毕安萘哉',
        '阿尔阿达尔': '亚达拉',
        'Brann II': '白兰恩B队',
        '斯托曼': '史托曼',
        /** T **/
        //土甲
        '桑利尤法斯普尔': '山路法斯堡',
        '吉里森士堡': '吉里森士邦',
        '杜塞尔多夫B队': '杜塞尔多夫 (青年)',
        '乌珀塔尔': '胡派达尔',
        'Bodrumspor':'博德鲁姆士邦',
        //'土杯
        '文尼沙BB': '马尼沙',
        '帕扎尔斯普': '帕萨士邦',
        'Stal Brzeg': '布热格',
        '瓦塔波兹南': '波兹南瓦尔塔',
        'Afjet Afyonspor': '阿菲永士邦',
        'Muş Menderesspor': '摩丝体育',
        //泰超
        '特罗萨萨纳': '特罗警察',
        'Trat': '塔特',
        '巴蜀基利': '巴蜀',
        'Uthai Thani':'乌泰他尼',
        'Lamphun Warrior':'南奔战士',
        //土超
        '彭迪克斯普尔': '佩迪卡斯堡',
        '安卡拉古库':'安卡拉高古',
        '安塔利亚体育':'安塔利亚',
        //突尼甲
        '比泽尔丁':'比瑟汀',
        'ES Tunis':'艾斯柏兰斯',
        /** U **/
        /** V **/
        /** W **/
        //乌拉甲
        '防卫者体育': '德丰索体育队',
        '达奴比奥':'丹纽比奥',
        '竞技俱乐部':'竞赛会',
        '漫游者':'万特雷斯',
        '蒙得维的':'利物浦蒙特维多',
        '竞赛会':'蒙特维多竞赛会',
        //乌拉乙
        'Oriental': '奥瑞多拉巴斯',
        'Albion':'阿尔比恩',
        '乌拉圭蒙特':'乌拉圭蒙特维多竞',
        '尤文都德':'尤文都德彼德拉斯',
        '塔卡伦博':'塔夸雷博',
        '布卡拉曼加竞技':'布卡拉曼加体育会',
        '米拉马':'米斯奥尼斯队',
        'Potencia':'波特尼西亚',
        //乌兹超
        '塔什干棉农':'帕克塔科',
        '特尔梅兹': '社空特么斯',
        '曼干纳弗巴霍': '比克波德',
        '布克索罗': '布哈拉',
        '帕克塔科': '帕克塔科',
        //委内超
        '港发院':'卡贝略港学院',
        //威超
        '佩尼邦': '佩尼邦特',
        '艾伯利斯特夫': '艾伯里斯特夫',
        /** X **/
        //西班牙
        'Linares Deportivo': '力纳雷斯体育会',
        'Tarazona': '塔拉佐纳',
        'Mérida': '梅里达AD',
        'Granada II': '格拉那达B队',
        //希腊超
        'OFI克里特': 'OFI克雷迪',
        '潘塞莱科斯': '邦萨拉高斯',
        //希腊甲
        'Kampaniakos Chalastra':'坎巴尼克斯',
        '伊拉克里斯':'伊拿克里斯',
        '卡拉玛里亚斯':'阿波罗波恩特欧',
        '阿纳格尼斯':'阿纳格尼斯',
        '莱瓦贾科斯':'莱瓦迪亚克斯',
        '伊奥尼科斯':'伊安尼高斯',
        '卡利迪亚':'卡利地亚',
        'Chania': '干尼亚',
        '艾加莱奥':'艾加里奥',
        'Olympiakos Piraeus II':'奥林匹亚科斯II队',
        '布拉布兰德':'巴布安特',
        '阿尔胡斯弗雷玛德':'阿尔胡斯费马',
        'Panathinaikos II':'帕纳辛奈科斯B队',
        'Iraklis 1908':'伊拉克里斯',
        '阿波罗卡拉马里亚':'卡拉玛里亚斯',
        '艾斯尼科斯':'艾治拿高斯',
        'Giouchtas':'吉乌崔庭斯',
        '奥力克斯':'伊奥里科斯',
        '尼基沃洛斯':'尼基沃罗',
        '利瓦迪亚科斯':'莱瓦贾科斯',
        //'西杯
        'Varea': '瓦瑞',
        '莱万特': '利云特',
        //西协甲
        '莱昂尼萨':'利安尼沙',
        '巴塞罗那B队':'巴塞罗那竞技',
        'Sanluqueño':'善鲁玖诺',
        'Intercity':'英特茨缇',
        'SD Logroñés':'SD洛哥尼斯',
        '皇家社会B队':'皇家苏斯达B队',
        '皇家马德里B队':'皇家马德里卡斯蒂亚',
        '阿格希拉斯':'艾基史拉斯',
        '休达':'薛奥泰',
        '格拉那达B队':'瑞克迪沃格拉纳达',
        //港菁杯
        '香港FC':'港会',
        'Sham Shui Po':'深水埗体育会',
        '冠忠南区':'南区足球会',
        '晋峰足球会':'晋峰',
        'North District':'北区',
        'HK U23':'香港 U23',
        '和富大埔':'大埔',
        /** Y **/
        //以甲
        '塞克特兹亚':'耐斯茨奥纳',
        '特拉维夫叶胡达':'耶胡达',
        //约旦甲
        // '阿拉毕KSA':'阿拉毕伊尔比德',
        '卡索姆':'卡法苏姆',
        'Sama':'萨马',
        'El Alia':'阿利亚体育',
        'Um Al Qotain':'阿尔科坦',
        '巴卡':'巴夸',
        '沙汉':'沙马沙尔汗',
        '艾沙赫':'阿尔萨瑞',
        //伊朗超
        'Havadar':'哈瓦达尔',
        '佐伯阿汉': '扎巴汉',
        '特拉克托': '泰拉克托',
        '可拉拉': '克瑞拉巴拉斯特斯',
        '马拉文': '玛拉宛',
        //伊朗甲
        'Mes Shahr-e Babak':'沙赫尔巴巴克',
        'Darya Babol':'达里亚巴博尔',
        'Mes Soongoun Varzaghan FC':'瓦尔扎甘',
        'Naft Gachsaran':'加奇萨兰',
        'Ario Eslamshahr':'埃斯兰沙尔',
        'Khooshe Talaee Saveh':'塔拉依萨纳萨维',
        'Al Sareeh':'艾沙赫',
        'Al Tora':'阿尔托拉',
        'Sama Al Sarhan':'沙汉',
        'Amman':'安曼足球俱乐部',
        'Chadormalu SC':'查多马卢阿尔达坎',
        'Kheybar Khorramabad':'霍拉马巴德',
        'Shahre Raz':'沙赫尔拉兹',
        'Damash Gilanian':'达玛希吉兰',
        'Esteghlal Mollasani':'艾斯迪格拿墨拉沙尼',
        'Shahrdari Astara':'沙赫达里阿斯塔拉',
        'Pars Jam Bushehr':'帕尔斯布什尔',
        //苏甲
        '科沃流浪者':'科夫流浪者',
        '安南竞技':'安南体育会',
        '哈密尔顿':'汉密尔顿',
        '蒙特罗斯':'蒙察斯',
        '法尔科克':'福尔柯克',
        //印度超
        '法特海德拉巴德':'海德拉巴',
        '泰坦':'钦奈',
        '邦加罗尔':'班加鲁鲁',
        '高阿':'果阿',
        //印度甲
        'Namdhari': '南德里',
        '斯里尼迪德干':'斯雷尼迪德干',
        //越南联
        '越电信':'维特尔',
        'Quang Nam': '广南',
        '胡志明市': '胡志明市足球俱乐部',
        //越南杯
        'Bóng đá Huế':'顺化',
        'Binh Thuan':'',
        //英超
        '纽卡斯尔联':'纽卡斯尔',
        //英甲
        '卡利斯尔联':'卡莱尔联',
        '伯顿':'保顿艾尔宾',
        //英议联
        '哈利法克斯':'夏利法斯',
        '弗莱德AFC':'菲尔德',
        '维尔德斯顿':'威尔德斯通',
        '阿尔特林查姆':'艾青洽',
        '伊斯特莱治':'伊斯特雷格',
        '沃京':'禾京',
        '索利赫尔':'索利哈尔',
        '格茨海德':'凯兹海得',
        // '切斯特菲尔德':'车士打菲特',
        '哈特利浦联':'哈特利浦',
        '巴内特':'巴尼特',
        '布罗姆里':'布罗雷',
        '多尔金流浪者':'多克林',
        '索斯安特联':'绍森德联',
        '埃布斯福利特联':'艾贝斯费特联',
        '格斯海德':'格茨海德',
        '波尔汉姆':'博雷汉姆',
        '迈登海德':'麦德黑联',
        '阿尔德索特':'艾迪索特',
        '京达米斯特':'京达米士特',
        '南安联':'绍森德联',
        '石威德':'威尔德斯通',
        '达根汉姆':'杜根咸',
        //英议南北
        '布克斯顿':'布顿',
        '鲁绍尔奥林匹克':'卢沙奥林',
        '斯卡布罗竞技':'斯卡波罗',
        '库尔宗阿什顿':'柯尔松阿什顿',
        '法斯利凯尔特人':'法斯利',
        '达灵顿':'达宁顿',
        '金斯林镇':'京斯莱恩城',
        '阿尔弗雷顿':'阿尔费登',
        '赫里福德':'荷利霍特',
        '格鲁切斯特城':'高士打',
        '威灵联':'威林联',
        '巴斯城':'贝斯城',
        '汉普顿里奇蒙德':'汉普顿列治文',
        '同布里奇天使':'唐布里基',
        '荷美尔':'赫默尔亨普斯特德城',
        '卓雷':'乔勒伊',
        '南港':'南方港',
        '切斯特':'车士打足球俱乐部',
        '班伯里':'班布里',
        '瓦林顿镇':'沃灵顿城',
        '谭沃夫':'谭禾夫',
        '拜斯斯巴坦斯':'布莱斯史巴登斯',
        '斯托特福德主教':'比夏史托福',
        '达特福德':'达特福特',
        '斯洛格镇':'史洛治',
        '梅德斯通联':'梅德斯通',
        '汤顿镇':'湯顿',
        '法恩堡':'法保罗夫',
        '威茅斯':'威茂斯',
        '布兰特里':'布伦特里',
        '布拉克利镇':'巴克利城',
        '斯坎索普':'斯肯索普',
        '斯彭尼穆尔镇':'史佩尼摩尔',
        '耶奥维尔':'约维尔',
        '布伦特里':'布瑞特伊',
        '韦斯顿':'威斯通',
        '哈文特沃特路维尔':'哈文特',
        '阿维利':'艾维烈',
        '史洛治':'斯洛格镇',
        '特鲁罗城':'特鲁罗市',
        //英冠
        '加的夫城':'卡迪夫城',
        '莱切斯特城':'莱斯特城',
        '西布罗姆维奇':'西布朗',
        '普利茅斯':'普利茅夫',
        //英甲
        '弗利特伍德':'富力特城',
        //英乙
        '史托克港':'史托港',
        '科切斯特联':'科尔切斯特联',
        '斯托克港':'格林斯比',
        '诺斯郡':'诺士郡',
        '纽波特':'纽波特郡',
        '雷克斯汉姆':'威斯汉姆',
        '瑟顿联':'萨顿联',
        '莫雷坎比':'莫康比',
        '特兰米尔':'特兰米尔流浪者',
        '格兰森林流浪':'格兰森林流浪者',
        '巴洛':'贝罗',
        '斯文登':'斯温登',
        '唐卡斯特':'唐卡斯特流浪者',
        '索尔福德市':'莎尔福德城',
        'AFC温布尔登':'温布尔登',
        '阿克灵顿':'艾宁顿',
        'Liverpool U21':'利物浦 U21',
        'Everton U21':'埃弗顿 U21',
        'Brighton U21':'布莱顿 U21',
        'Crystal Palace U21':'水晶宫 U21',
        'Fulham U21':'富勒姆 U21',
        //'英锦赛
        '彼得堡联': '彼德堡',
        'Newcastle United U21':'纽卡斯尔 U21',
        'Nottingham Forest U21':'诺定咸森林 U21',
        'Tottenham Hotspur U21': '托特纳姆热刺 U21',
        'RAAL La Louvière': '拉路维尔',
        '特斯体育': '特汉斯',
        '沙勒罗瓦': '沙勒罗瓦',
        '比勒菲尔德': '比勒费尔德',
        'Manchester City U21':'曼联 U21',
        'West Ham United U21':'西汉姆联 U21',
        'Aston Villa U21':'阿斯顿维拉 U21',
        '克劳利':'克拉雷镇',
        'Leicester City U21':'莱切斯特 U21',
        //'英联杯
        '埃克塞特城': '艾斯特城',
        '曼斯菲特': '曼斯菲尔德城',
        'Lorca Deportiva': '洛卡',
        '塔拉维拉': '达拉维拉瑞纳',
        '拉斯帕尔马斯': '拉斯彭马斯',
        //意甲
        '热那亚': '热拿亚',
        '萨勒尼塔纳': '沙兰力坦拿',
        //意乙
        '希塔德拉': '奇塔代拉',
        '克雷莫纳': '克雷莫内塞',
        //意丙杯
        '尼阿波利斯体育':'图里斯',
        '史泰比亚':'祖华史塔比亚',
        //意丙
        'Nuova Monterosi':'蒙泰罗西',
        '弗兰卡维拉维图斯':'弗兰卡维拉',
        'Ancona 1905': 'US安科纳',
        '阿雷佐': '阿雷素',
        '卡拉雷塞': '凯勒雷斯',
        'Pineto': '匹呐图',
        '彭特德拉1912': '邦特德拉',
        '佩萨罗': '比沙奴',
        '墨西拿': 'ACR麦斯纳',
        '布林迪斯': '比林迪西',
        'Arzignano Valchiampo':'阿尔齐尼亚诺',
        '卢梅扎尼':'隆美辛尼',
        'Legnago Salus':'尼亚戈萨卢斯',
        '特里埃斯蒂纳':'泰利斯天拿',
        '普罗塞斯托':'塞斯托',
        '维德思维罗纳':'维图斯维罗纳',
        '帕特里亚':'柏迪亚',
        '贾纳埃米尼奥':'加纳埃米尼奥',
        '阿尔比诺勒夫':'艾宾奴列夫',
        '阿莱桑德里亚':'亚历山德利亚',
        '皮奇哥托尼':'贝格勒特瑟',
        'Atalanta II':'亚特兰大U23',
        '雷纳特':'AC雷纳特',
        '普罗维尔塞利':'韦尔切利',
        '费奥伦佐拉':'菲奥伦佐拉',
        '维琴察':'LR维琴察',
        '诺瓦拉':'诺和华',
        '艾尔真城':'艾珍城',
        '塞斯特里勒万特': '塞斯特里莱万泰',
        '贝内文托': '班尼文托',
        'Juventus II':'尤文图斯 U23',
        //约超联
        '阿喀巴': '沙巴柏阿卡巴',
        '活赛恩': '胡森伊尔比德',
        //印尼超
        '佩斯克': '佩斯凯迪瑞',
        'Cilegon United': '努萨塔拉',
        //印尼甲
        'Persewar':'珀塞瓦瓦魯本',
        'Sulut United':'苏鲁特联',
        'Persipal':'波斯帕尔',
        'PSBS Biak Numfor':'PSBS巴克伦佛',
        '佩西巴':'巴厘巴板',
        '查亚普拉':'查亚普拉佩西普拉',
        'Persikab Bandung':'波斯卡布万隆',
        'PSKC Cimahi':'西马希',
        'Semen Padang':'塞曼巴东',
        'Nusantara United':'努桑塔拉联',
        'Perserang':'帕尔西冷',
        'Persekat':'帕森卡特加',
        'Persipa Pati':'波斯帕蒂',
        '德利塞尔当':'德里沙登',
        'PSMS':'PSMS棉兰',
        //'牙买超
        'Molynes United': '默里斯联',
        '阿内特花园': '阿内特加登斯',
        'Treasure Beach':'瑰宝海滩',
        
        //亚协杯
        'Jabal Al Mukaber':'贾巴尔艾尔姆卡贝',
        '伊蒂哈德':'伊蒂哈德阿勒颇',
        '海防水泥队':'海防队',
        'PSM马卡萨':'马卡萨',
        '中部海岸海员': '中央海岸水手',
        '巴厘联': '巴厘联',
        'Stallion':'公马吉利根',
        'Ulaanbaatar':'乌兰巴托',
        'CPK':'邹北记',
        'Abdish-Ata':'阿布迪什阿塔坎特',
        'Ravshan':'瑞山库伯',
        'Altyn Asyr':'阿尔丁阿斯尔',
        '梅尔':'梅尔瓦',
        //亚冠杯
        '艾纳斯':'纳撒利雅德',
        'Ahal':'阿哈尔',
        '艾杜哈尼':'杜海勒',
        '莫亨巴根':'ATK莫亨巴根',
        'Bashundhara Kings':'巴斯顺达拉国王',
        '阿尔维达特':'艾华达安曼',
        'Al卡拉巴':'卡拉巴',
        '浙江队':'浙江',
        'Kaya':'卡雅',
        /** Z **/
        //中超
        '深圳队':'深圳',
        '沧州雄狮': '沧州雄狮',
        //智利甲
        '库里科':'克里科',
        '圣地亚哥马加拉尼斯':'麦哲伦',
        '科比亚波':'科皮亚波',
        '奴伯伦斯':'纽柏莱斯',
        
        //'西杯
        'Atlético Lugones': '卢贡内斯体育会',
        'Azuaga': '阿苏阿加',
        '卡塔赫纳': '卡塔捷拿',
        'Llerenense': '勒任恩涩',
        '莱加内斯': '雷加利斯',
        'Náxara': '拿萨拉',
        '梅利利亚': '美利拉',
        'Peña Deportiva': '彭拿迪坡缇瓦',
        '瓦拉多利德': '巴拉多利德',
        '圣罗克': '桑罗奎莱佩',
        '巴巴斯特罗': '巴尔巴斯特罗',
        '巴达洛纳': '巴达隆拿',
        '康波斯特拉': '甘普斯特拉',
        '穆尔西亚天主教大学': 'UCAM梅西亚',
        '纳瓦尔卡内罗': '纳华尔卡内罗',
        //'匈杯
        'Unione': '尤尼内',
        '华达塞': '基斯华达',
        //'罗杯
        'SCM Zalău': '扎拉尤',
        'Dunaföldvár': '杜纳福尔德瓦',
        'ESMTK': '艾尔瑟贝堤SMTK',
        'MTK匈格利亚': 'MTK布达佩斯',
        'Mezőörs': '梅佐尔斯',
        '卡波斯瓦里': '卡伯司瓦',
        'Szentlőrinc SE': '辛特罗尼克',
        'Kelen': '科伦SC',
        '布达弗基隆巴德': '布达富科MTE',
        //'俄杯
        '罗迪纳明斯克': '福特塔甘罗格',
        '切诺莫雷茨': 'SKA哈巴罗夫斯克',
        'Progresul Pecica': '布若格瑞苏佩慈卡',
        //'沙地甲
        '欧鲁巴赫': '阿尔欧鲁巴赫塞卡凯',
        //'保杯
        '瓦尔纳斯巴达': '华纳斯巴达',
        '图纳里': '图纳日',
        //'阿尔巴超
        'KF地拉那': 'KF特拉拿',
        '图塔杜雷斯': '图达杜利斯',
        '拉奇': 'KF拉斯',
        '地拉拿柏迪辛尼': '帕提萨尼',
        'Kozármisleny': '寇萨米斯兰尼',
        '卡辛巴西卡': '巴拉西卡',
        '佩帕里米':'库克斯',
        '地拉拿戴拿摩':'特拉拿戴拿模',
        //'印度超
        '詹谢普尔': '贾姆谢德布尔',
        '加巴莱恩': '查巴莱',
        'Valle Egüés': '瓦勒伊古斯',
        '特鲁艾尔': '特鲁埃尔',
        '安托尼亚诺': '安东亚诺',
        '卢高': '卢戈',
        '阿斯托加竞技': '阿斯托尔加体育会',
        '安道尔CF': 'FC安道尔',
        //'立陶乙
        'BFA': 'BFA维尔纽斯',
        'TransINVEST Vilnius': '特兰斯因维斯特',
        //'意丙
        '卡塔尼亚': '卡坦尼亚',
        'Real Avilés': '皇家阿维勒斯',
        'Arenteiro': '阿仁特若',
        '哈恩': '皇家哈恩',
        '艾尔登斯': '艾尔德斯',
        //'爱沙甲
        '塔林利瓦迪亚': 'FCI雷瓦狄亚',
        '帕尔努瓦普鲁斯': '派尔努瓦普斯',
        '潭美卡': '塔马卡',
        'Ursaria': '乌尔萨利亚',
        'Cayón': '卡恩',
        '科瓦东加': '科瓦栋卡',
        'Siófok': '斯欧福克',
        '迪欧斯吉奥里VTK': '迪欧斯捷尔',
        '瓦萨斯': '华萨斯',
        '蒂萨克斯克': '蒂萨凯奇凯',
        '阿吉卡': '阿贾克',
        '维拉斯尼亚': '维拉尼亚',
        '厄尔泽尼': '厄泽尼',
        '哈拉达斯': '哈拉德斯',
        '萨拉格斯基': '沙勒格斯基',
        'Manchego': '曼彻高雷阿尔城',
        '安特奎拉': '安特雀拉',
        '古杰罗': '圭久洛',
        '阿兰迪纳': '阿伦迪娜',
        '穆尔西亚': '梅西亚',
        '奥利胡埃拉': '奥利维拿',
        '巴拉卡尔多': '巴拉卡度',
        '杰尔尼卡': '格尼卡',
        '萨拉曼卡联': '尤尼恩斯塔斯',
        '欧罗巴': 'CE优蹂帕',
        '特雷萨': '泰拿沙',
        '阿尔瓦塞特': '阿尔巴塞特',
        //'德国杯
        '基尔高士丁': '荷尔斯泰因',
        //'丹麦杯
        '维堡': '域堡',
        '塔兰托体育': '塔拉恩托',
        'Boiro': '波若',
        '马洛卡': '马略卡',
        'Buñol': '布尼奥尔',
        '皇家社会': '皇家苏斯达',
        'Águilas': '阿吉拉斯',
        'Szeged-Csanád': '塞格德',
        '克斯克梅迪': '科斯克米提',
        'AB哥本哈根': 'AB格莱萨克瑟',
        '维积利': '瓦埃勒',
        '安德拉特克斯': '安德拉特斯',
        '玛贝拉': '玛贝亚',
        '费洛尔竞技': '费路尔',
        'Hernán Cortés': '荷南考特斯',
        //'苏超
        '哈茨': '赫斯',
        '利云斯顿': '利文斯顿',
        '圣约翰斯通': '圣约翰斯顿',
        '科隆胜利': '维多利科隆',
        '萨尔布吕肯': '赛瑞贝肯',
        //'意杯
        '乌迪内斯': '乌甸尼斯',
        'Rubí': '鲁比',
        //'巴西甲
        '博塔弗戈': '博塔福格RJ',
        '福塔雷萨': '福塔雷萨CE',
        //'墨西甲
        '坎昆FC': '坎昆',
        //'土杯
        'Orduspor':'奥度斯普',
        'Isparta 32 Spor': '厄斯帕尔塔',
        'İçel İdmanyurdu Spor': '艾塞尔艾得曼尤尔杜',
        'Somaspor': '索马士邦',
        'Talasgücü Belediyespor': '塔拉斯古库',
        '卡斯塔莫努斯普尔': '加斯塔摩奴士邦',
        'Ayvalıkgücü Belediyespor': '阿华利古奇',
        'Çankaya FK': '坎卡亚',
        '杜克泽斯普尔': '杜茲塞',
        'Kuşadasıspor': '库萨达西斯',
        'Nevşehir Belediyespor': '新森海斯堡',
        'Altındağ Spor': '哈斯特帕1945',
        '费蒂耶斯普尔': '费迪耶斯堡',
        'Tokat Bld Plevnespor': '托卡特',
        'Aliağa FAŞ': '阿利亚加AS',
        '梅内门':'梅内门士堡',
        '新森海斯堡':'内维瑟赫尔',
        'Kestelspor':'凯斯特尔士邦',
        'Kepez Belediyespor':'凯佩兹比勒迪亚士邦',
        'Beyoğlu Yeni Çarşı':'贝伊奥卢耶尼恰尔西',
        'Artvin Hopaspor':'阿尔特温',
        '76 Iğdır Belediyespor':'伊迪尔',
        //'乌兹超
        '奥马利克': 'AGMK',
        //'比利时杯
        '利尔斯': '利亚斯肯潘佐宁',
        'URSL Visé': '维塞',
        //'西杯
        '瓦勒伊古斯': '瓦勒伊古斯',
        //'乌克超
        '鲁克维尼基': '鲁克利沃夫',
        'SK迪尼普': 'SC迪尼普',
        '桑坦德竞技': '桑坦德',
        'LNZ Cherkasy':'LNZ切尔卡瑟',
        '禾斯克拉':'沃斯卡拉',
        '米尼':'米奈',
        '克里夫巴斯':'卡夫巴斯',
        //'约超联
        '艾沙尔特': '阿尔萨特',
        //'保超
        '埃尔塔': '阿尔达克尔贾利',
        '邓达': '邓打',
        '马斯梅赫伦': '帕特罗艾斯登马斯梅赫伦',
        '华斯兰德': '贝弗伦',
        '韦斯特鲁': '韦斯特洛',
        //'荷兰杯
        '伊塞尔梅尔沃格尔斯': '伊素美禾祖斯',
        '尼克尔克斯巴达': '尼卡克斯巴达',
        '福图纳锡塔德': '锡塔德命运',
        '林斯伯格男孩': '雷恩堡斯',
        '阿姆斯特丹': '阿姆斯特丹斯彻',
        '泽沃勒': '兹沃勒',
        '比斯查': '比尔肖特',
        //'巴西乙
        '戈亚尼亚竞技': '戈亚尼恩斯',
        //'厄瓜甲
        '利加大学': '奎托',

        //'印尼超
        '马兰': '阿雷玛',
        'Dewa United': '德瓦',
        '斯乐门':'斯莱曼',
        //'印度甲
        'Delhi FC': '德里联',
        'Rajasthan': '拉贾斯坦邦联',
        //'缅甸联
        'Kachin United': '克钦联',
        'Myawady': '米亚瓦迪',
        'GFA': '钦区',
        //'伊朗超
        '沙禾达利亚拉克': '阿拉克',
        ///'土杯
        '加辛塔BB': '加斯安泰普',
        'Efeler 09 Spor': '埃费勒',
        //'罗杯
        'FCM亚历山大里亚': '亚历山大',
        '巴杨卡拉泗水联': '巴扬卡拉',
        '三宝珑': 'PSIS叁宝珑',
        '普萨马尼亚': '婆罗洲',
        //'阿联酋超
        '亚吉拉': '阿尔贾兹拉',
        '沙瑞加': '沙迦',
        //阿联酋甲
        '阿尔拉姆斯':'拉姆斯',
        '阿尔加兹拉':'詹辛拉阿尔哈姆拉',
        '度巴阿尔胡桑':'迪巴阿辛',
        '阿尔哈姆利亚':'哈瑞亚',
        '富基拉':'阿尔富吉拉杜拜',
        'Masfut':'马斯富阿基曼',
        // '阿拉毕KSA':'阿拉毕欧姆古温',
        '迪哈夫拉':'德哈法',
        '阿尔塔伊德':'泰德',
        '奥鲁巴':'阿尔欧鲁巴富吉拉',
        'Al Taawon':'泰阿沃足球俱乐部',
        //'俄杯
        '阿克伦托格里蒂': '阿克伦',
        '阿斯特拉罕沃尔加': '沃尔加阿斯特拉罕',
        '塞帕汉': '沙巴罕',
        //'马来杯
        '柔佛': '柔佛达罗塔晋',
        '帕拉克': '霹雳',
        //'约超联
        '阿洛顿': '沙巴阿罗登',
        '沙巴柏阿卡巴': '沙巴柏阿卡巴',
        '干亚斯堡': '科尼亚体育',
        'Erbaaspor': '埃尔巴斯堡',
        //'卡塔尔联
        '米瑟尔': '穆艾搭罗',
        '乌姆沙拉尔': '沙拉尔',
        '阿尔赖扬':'艾尔雷恩',
        //'科威特联
        '纳沙': '阿尔纳赛尔费尔瓦尼耶',
        '阿拉比科威特': '阿拉毕科威特',
        '哥利亚布沙': '布沙',
        //'巴林超
        '艾尔里法': '阿尔瑞法',
        '麦纳麦艾尔沙巴布': '沙巴柏麦纳麦',
        '阿尔费萨里': '阿尔费萨里安曼',
        '加里里': '阿尔加利尔',
        //'意杯
        '森索罗': '萨索洛',
        //'丹麦杯
        'Ishøj': '伊绍伊 IF',
        //'西杯
        '塞戈维亚那体育': '施哥维安纳',
        '塞斯塔奥': '施斯达奥',
        '齐克拉纳': '奇克拉纳',
        '比利亚雷亚尔': '维拉利尔',
        //'匈杯
        '汉维特': '布达佩斯洪韦德',
        '保克什': '帕克斯',
        '克卢日大学': '古积大学',
        'Atzeneta': '阿齐纳塔',
        '洛格罗': '洛哥尼斯',
        '巴伦西亚': '华伦西亚',
        //'荷兰杯
        '诺德维克': 'SJC诺德维克',
        '兰德斯': '兰讷斯',
        '维拉诺文思': '维兰洛云斯',
        '赫库斯': '赫古拉斯',
        //'萨尔超
        '圣塔特卡拉': '圣特克拉',

        //'韩K3联
        '仁川地铁': '大田地铁',
        '木浦市': '木浦',
        //'越南联
        'SQC平定': '平定',
        '黄安嘉莱': '归仁',
        'Công An Nhân Dân': '河内公安',
        '河内': '河内足球俱乐部',
        //'埃及超
        '史莫哈': '索莫哈',
        '艾尔伊蒂哈德': '伊蒂哈德亚历山大',
        //'卡塔尔联
        '多哈艾阿里': '阿尔艾利多哈',
        '舒马尔': '夏马尔',
        '威柯拉': '瓦卡拉',
        '卡塔尔SC': '卡达尔竞技',
        //'伊朗超
        '胡齐斯坦独立': '艾斯迪格拿候萨斯坦',
        //'立陶甲
        '班加': '邦加',
        //'斯伐超
        'FC Košice': '科希策',
        '加拉法': '阿尔葛拉法',
        '阿拉比SC': '阿拉毕多哈',
        //'土甲
        '萨卡亚斯普尔': '沙卡亚斯普',
        'Çorum FK':'科鲁姆',
        '阿尔塔伊':'阿勒泰',
        //'波兰甲
        '马特斯': '莱格尼察',
        //'塞浦甲
        '扎卡吉奥': '扎卡基乌',
        '阿奇纳': '艾治拿斯',
        //'德丙
        '奥厄': '厄尔士奥厄',
        '维尔': 'SC维尔',
        //'丹麦乙
        '斯凯夫': '斯基夫',
        '蒂斯特德': '齐斯泰兹',
        //'德地区
        '柏林安卡拉体育': '柏林讷AK07',
        '卡尔蔡司耶拿': '卡尔斯耶拿',
        '基尔业余队':'荷尔斯泰因 (青年)',
        '哈维尔斯':'哈韦尔斯',
        //'丹麦甲
        '靴尔科治': '科治',
        //'沙特联
        '阿尔沙巴布': '沙巴柏利雅德',
        //'法丙
        '尼姆': '奈梅斯',
        '埃皮纳勒': '埃皮内尔',
        '沙托鲁': '沙特鲁',
        '鲁昂': '卢昂',
        '维尔弗兰切': '维利法兰',
        '马蒂格斯': '马提克',
        '维格伯格': '韦格堡贝克',
        '威尔伯特': '维伯特',
        //'罗杯
        '比霍尔': '比霍尔',
        //'比乙
        '亨克 II': '亨克青年',
        'RFC列日': 'RFC 列治',
        '威尔郡': '祖尔特瓦雷根',
        //'摩洛超
        'JS Soualem': '苏阿莱姆',
        '莫哈梅迪亚': '查巴穆罕默迪耶',
        //'爱超
        '德里城': '德利城',
        '波希米亚人': '布咸美恩斯',
        //'意丙
        '特伦托1921': '特伦托',
        //'西乙
        '西班牙人': '爱斯宾奴',
        //'巴西乙
        '米拉索尔': '迈拉索尔SP',
        //'阿乙
        '利斯特雷': '瑞斯塔',
        '伊图阿诺': '伊图阿诺SP',
        '隆迪那': '隆迪那PR',

        //'墨西甲
        '莫雷利亚': '莫雷利亚竞技',
        //'印度甲
        'Sreenidi Deccan': '斯里尼迪德干',
        'Inter Kashi': '喀什国际',
        'Real Kashmir':'皇家克什米尔',
        //'乌兹超
        '尼菲治': '费尔干纳夫兹',
        '奥林匹克': 'FK奥林匹克(UZB)',
        '莫哈梅丹': '莫哈末丹',
        '拉琼': '西隆拉庄',
        //'斯伐甲
        '波罗尼': '波赫隆尼',
        'Šamorín':'沙莫林',
        '波赫隆尼':'杜纳达纳',
        '阿特梅迪亚':'柏沙卡',
        '斯洛文布拉迪斯拉发B队':'布拉迪斯拉发B队',
        //斯尼杯
        'Šencur':'辛库尔',
        //'约超联
        '连姆沙': '蓝慕沙',
        '马恩': '马安',
        //'波兰乙
        '维斯拉普拉维': '华沙普拉维',
        //'瑞典乙
        '奥德沃尔德': '奥迪沃德',
        '阿赫拉夫斯': '阿拉佛斯',
        //'法丙
        '阿维兰奇斯': '艾夫兰治斯',
        '勒芒': '利文斯',
        //'英足总杯
        '谢佩岛联': '谢佩联',
        '克雷山谷造纸厂':'克雷山谷文米尔斯',
        //'巴西乙

        //'日足联
        'Biwako Shiga':'瑞拉滋贺',
        '平田町':'钛阿莫枚方',
        'Maruyasu Okazaki': '冈崎丸山',
        'Criacao Shinjuku': '新宿创造',
        'Urayasu': '布瑞欧碧卡浦安',
        'Reinmeer Aomori': '润梅尔青森',
        'Veertien Mie': '威蒂恩三重',
        'Verspah Oita': '伟士帕大分',
        '仙台索尼': '仙台新力',
        'Okinawa SV': '冲绳SV',
        '武藏野横河': '东京武藏野',
        'Suzuka Point Getters': '鈴鹿泊格特斯',
        'TIAMO Hirakata': '平田町',
        //'韩K3联
        'Siheung Citizen': '始兴市民',
        //'日职乙
        '金泽': '金泽萨维根',
        '清水鼓动': '清水心跳',
        '班迪特斯磐城': '磐城',
        '磐田喜悦': '磐田山叶',
        'Paju Citizen': '坡州市民',
        'Yangju Citizen': '扬州市民',
        '大田水利': '庆州KHNP',
        //'日丙
        'FC岐阜': '岐阜',
        //'印尼超
        '蒂拉佩斯卡保': '伯希卡波',
        '琉球FC': '琉球',
        //'缅甸联
        '伊斯佩FC': '伊斯佩',
        'Dagon': '达贡',
        //'白俄超
        '纳夫坦': '纳夫田',
        //'越南联
        '平阳': '贝卡麦克斯',
        'Sanna Khanh Hoa':'庆和',
        //'乌兹超
        //'瑞典甲
        '奥斯特桑斯': '奧斯特桑斯',
        //'印度超
        '海德拉巴': '海得拉巴',
        //'斯亚甲
        'Rogaška': '罗加什卡',
        '科珀尔': '高柏',
        //'意乙
        '卡坦扎罗': '卡坦萨罗',
        //'德丙
        '吕贝克': '路比克',
        '艾森': '埃森',
        //'土超
        '萨姆松珀': '萨姆士邦',
        //'奥乙
        '圣珀尔滕': '圣普尔顿',
        //'印度甲
        'Aizawl': '艾藻尔',
        '丘吉尔兄弟': '巴得斯尤',
        //'瑞典超
        '瓦纳默': '渥那模',
        //'波兰甲
        '阿尔卡': '阿卡',
        //'捷甲
        '帕尔杜比斯': '帕都拜斯',
        '兹林': '辛宁',
        '斯洛瓦科': '斯洛瓦茨科',
        '赫拉德茨克拉洛韦': '贺拉戴克',
        '特普利斯': '特普利采',
        '博雷斯拉夫': '博莱斯拉夫',
        'FC鱼雷': '佐迪诺鱼雷',
        //'苏冠
        '阿布罗斯': '亚布岳夫',
        //'比乙
        '安德莱赫特 II': 'RSCA富图雷斯',
        //'北爱超
        '格伦杜兰': '格伦托兰',
        '卡域克流浪': '卡里克流浪者',
        '罗佳尔': '罗夫加尔',
        '巴利米纳联队': '巴利米纳',
        //'沙特联
        '艾伯哈': '亚哈',
        //'摩洛超
        'Renaissance Zemamra': '泽马拉',
        '特托安': '莫利比',
        //'格鲁甲
        '第比利斯迪纳摩': '提比利锡戴拿模',
        '桑普多利亚': '森多利亚',
        //'//西乙
        '维拉雷尔B队': '维拉利尔B队',
        //'荷甲
        '赫拉克莱斯': '赫拉克勒斯',
        '埃因霍温': 'PSV埃因霍温',
        //'葡甲
        '比伦塞斯': 'CF Os比兰尼塞斯',
        //'奧甲
        '华顿斯': '蒂罗尔',
        '萨尔茨堡': '萨尔茨堡红牛',
        '沃尔夫斯伯格': '沃尔夫斯贝格',
        '奥地利克拉根福': '奧地利克拉根福',
        //'波兰超
        '维德祖罗茲': '维泽夫洛兹',
        //'意甲
        '亚特兰大': '阿特兰大',
        //'瑞士超
        '洛桑': '洛桑体育',
        //'塞浦甲
        '奥莫尼亚': '奧摩尼亚',
        '阿波罗利马索尔': '阿波罗利马素尔',
        //'埃及超
        '阿尔莫考隆': '阿拉伯建筑',
        //'比甲
        '奥德赫维里': '奥哈瓦里',
        'RSB贝尔卡尼': '贝尔卡勒',
        '阿加迪尔': '哈斯沙尼亚',
        //'西甲
        '塞尔塔': '维戈塞尔塔',
        'SBV精英': 'SBV精英队',
        '瓦尔韦克': '瓦尔维克',
        //'法乙
        '克维伊': '奎维利鲁昂',
        '安格斯': '昂热',
        '康纳奴': '孔卡诺',
        '格勒诺布尔': '格勒诺布',
        '阿讷西': '安尼茨',
        '拉瓦勒 ': '拉华尔',
        '顿克奎': 'USL敦刻尔克',
        //'葡超
        '法马利卡奥': '费马利卡奥',
        '吉维森特': '吉尔维森特',
        //'希腊超
        '基菲萨': '基菲西亚',
        '葡萄牙国民': '国民队',
        '辛达卡拉': '圣塔克莱拉',
        '伊蒂法克': '伊地法格',
        '布苏': '弗朗西斯博兰斯',
        //'玻利甲
        'Vaca Díez': '瓦卡迪兹',
        '瓜比拉': '葛布莉蒙泰罗',
        '阿拉维准备': '阿拉维预备',
        '圣克鲁斯': '皇家圣塔库鲁',
        //'罗甲
        '拉巴特': '拉巴特皇家武装力量',
        'CAYB贝雷奇德': '优素菲耶',
        //'乌拉甲
        '塞罗拉尔': '切洛拉高',
        '图尔克': '蒙特维多城图尔克',
        '佩莱扎':'佩拉扎科朗尼亚',
        //'厄瓜甲
        '雷西斯坦西亚': '瓜亚基尔城',
        '埃梅莱克': '尹美利克',
        //'委内超
        'FC波图加沙': '波图加沙足球俱乐部',
        //'巴西甲
        '米内罗美洲': '阿美利加MG',
        //'巴西乙
        '森柏欧': '森柏欧MA',
        '奥罗拉': '欧若拉',
        '史庄格斯': '史庄格斯特',
        //'墨西联
        '泰格雷斯': '塔格雷斯',
        'FC河床': '蒙特维多河床队',
        '乌拉圭民族': '蒙特维多国民队',
        '墨西哥美洲': '阿美利加会',

        //'日足联
        'Kochi United': '高知联合',
        //'日丙
        '爱媛FC': '爱媛',
        '宫崎特格瓦雅罗':'特格瓦嘉洛宫崎',
        //韩K3联
        'Pocheon': '抱川FC',
        'Ulsan Citizen': '蔚山市民',
        //拉脱超
        '瓦尔米耶拉':'瓦尔麦斯',
        '里加FC':'里加',
        //'拉脱甲
        'FS Jelgava': '叶尔加瓦',
        //'俄甲
        '马哈奇卡拉':'戴拿模马卡马哈奇卡拉',
        '下卡姆斯克石油':'内弗特赫米克',
        //'印度甲
        'Gokulam': '歌库拉姆喀拉拉',
        'NEROCA': '尼罗卡',
        //'瑞典超
        '哈尔姆斯达斯': '哈尔姆斯塔德',
        //'沙特联
        '阿勒汉斯': '艾哈斯姆',
        '艾尔瓦赫达': '瓦赫达麦加',
        //'摩洛超
        '谭戈尔': '利替翰丹吉尔',
        '费斯': '马格里布菲斯',
        //'意乙
        '奥迪亚斯': '雷吉亚那',
        '勒科': '莱可',
        //'瑞士超
        '乌契': '洛桑奥奇',
        '圣加仑': '圣加伦',
        //'德丙
        '蒙斯特': '普鲁士明斯特',
        '乌尔姆1846': 'SSV乌尔姆',
        //'挪超
        '布兰': '白兰恩',
        '奥德格伦兰': '奥特',
        //'塞浦甲
        'AEP帕福斯': '帕福斯',
        '阿诺索西斯': '安罗科萨斯',
        //'土甲
        '伊尤斯堡': '伊约普',
        '艾丹亚斯普': '艾丹拿斯堡',
        'AEL利马索尔': 'AEL利马斯素尔',
        'Karmiotissa': '卡米奥提萨',
        '欧吉达': '莫罗迪亚',
        '萨菲': '奥林比亚萨非',
        //'厄瓜甲
        '穆苏克鲁纳': '穆苏鲁纳',
        '特克尼克': '泰尼科',
        //'葡甲
        '唐迪拉': '托德拉',
        '托伦塞': '图伦斯',
        '吉达阿赫利': '阿尔艾利吉达',
        '艾尔利雅得': '阿利雅德',
        //'牙买超
        'Lime Hall Academy': '里梅禾',
        '水房': '沃特豪斯',
        //'罗甲
        '布加勒斯特快速': '布格勒斯特迅速',
        'Cumbayá': '昆巴亚',
        '奥伦斯CS': '奥伦塞',
        'Mount Pleasant Academy': '芒特普莱森特学院',
        'Dunbeholden': '敦伯霍登',
        '塞巴联队': '蒙特哥湾联',
        'Cavalier': '卡瓦立尔',
        //'哥斯甲
        'AD格雷西亚': '格雷西亚',
        '希雷迪亚诺': '希尔迪奥诺',
        //'巴西乙
        '维多利亚': '维多利亚BA',
        '维拉诺瓦': '维拉诺瓦GO',
        '瓜亚基尔': '巴塞隆拿SC',
        '利伯泰德': '国家报队',
        //'玻利甲
        '布鲁明': '邦明',
        '奥利恩特': '欧里恩特',
        'Universitario de Vinto':'宾托',
        //'洪都甲
        'CD奥林匹亚': 'CD奧林匹亚',
        '洛沃斯墨': '罗伯士UPNFM',
        //危地甲
        'Coatepecano IB':'科特佩克',
        'Zacapa Tellioz':'砸凯帕泰洛兹',
        '马拉卡特高':'马拉卡达高',
        '米克斯克':'米克斯高',
        '安提瓜GFC':'安提瓜',
        '西纳巴祖尔':'希纳巴祖',
        //'沙地甲
        'Al Safa': '萨法',
        //'亚协杯
        '富托阿': '福托华',
        '拿达': '纳达艾布赖米',
        '阿尔内泽莫': '阿尔内泽默',
        '扎瓦拉': '扎乌拉',
        //'意丙
        '莫诺伯里': '摩诺波利',
        'Audace Cerignola': '奥达彻切里尼奥拉',
        //'玻利甲
        '国民波托斯': '波托西',
        '棕榈竞技': '帕马科亚',
        //'牙买超
        '蒂沃利花园': '蒂沃利加登斯',
        'Vere United': '维瑞联'
    }
    newName = nameMap[name] || name;
    return newName;
}