<template>
    <div>
        <div class="container py-2" v-loading="loading" :style="{height: loading || this.historyList.length < 10 ? 'calc(100vh - 129px)' : 'auto'}">
            <Nav :isDark.sync="this.isDark" @darkmode="isDark = $event"></Nav>
            <div class="my-4" v-if="this.historyList.length > 0">
                <table :class="{'table-dark': isDark}" class="table table-bordered w-100 font-weight-bold mb-0">
                    <tbody class="">
                        <tr class="fw-bold">
                            <td width="136" class="text-white text-center align-middle" :style="'background:'+ this.color">{{league}}</td>
                            <td width="82" class="text-center align-middle">{{this.time}}</td>
                            <td class="text-center">
                                <a :href="'https://zq.titan007.com/analysis/'+this.id+'cn.htm'" target='_blank' class="text-decoration-none">
                                <div class="match-team">
                                    <span>{{this.home}}</span>
                                    <span class="mx-4 px-4" :style="{color: qsdaColorSet(this.handicap,this.qsda)}">{{this.qsda}}</span>
                                    <span>{{this.away}}</span>
                                </div></a>
                            </td>
                            <td width="135"  class="text-center align-middle">{{this.handicap}}</td>
                            <td width="69"  class="text-center align-middle">{{this.bigsmall}}</td>
                        </tr>
                    </tbody>
                </table>
                <ux-grid
                ref="plxTable"
                show-overflow
                use-virtual
                stripe
                :data = this.historyList
                :highlightCurrentRow="true"
                show-header-overflow="ellipsis"
                style="width: 100%"
                :cell-style="columnStyle"
                :edit-config="{trigger: 'click', mode: 'cell'}"
                :header-cell-class-name="headerClass">
                    <ux-table-column row-class-name="bgdark" field="date" title="日期" :width="140" ></ux-table-column>
                    <ux-table-column field="league" title="联赛" :width="80" column-key="league" :filters="leagueList" :filter-method="filterLeague">
                        <template v-slot="scope">
                            <span>{{scope.row.league}}</span>
                        </template>
                    </ux-table-column>
                    <ux-table-column field="home" title="主队" show-overflow-tooltip></ux-table-column>
                    <ux-table-column field="score" title="比分" :width="59"></ux-table-column>
                    <ux-table-column field="away" title="客队" show-overflow-tooltip></ux-table-column>
                    <ux-table-column field="qsda" title="qsda" :width="60"></ux-table-column>
                    <ux-table-column field="state" title="结果" :width="70" column-key="state" :filters="result" :filter-method="filterHandler">
                        <template v-slot="scope">
                            <span>{{scope.row.state}}</span>
                        </template>
                    </ux-table-column>
                    <ux-table-column title="初/终" :width="60">
                        <template>
                            <div>初盘</div>
                            <div>终盘</div>
                        </template>
                    </ux-table-column>
                    <ux-table-column field="index1" title="指数1" :width="60">
                        <template v-slot="scope">
                            <div>{{scope.row.h_index1}}</div>
                            <div>{{scope.row.h_index2}}</div>
                        </template>
                    </ux-table-column>
                    <ux-table-column field="crown" title="澳/Crown" :width="100">
                        <template v-slot="scope">
                            <div>{{scope.row.init}}</div>
                            <div>{{scope.row.final}}</div>
                        </template>
                    </ux-table-column>
                    <ux-table-column field="index2" title="指数2" :width="60">
                        <template v-slot="scope">
                            <div>{{scope.row.a_index1}}</div>
                            <div>{{scope.row.a_index2}}</div>
                        </template>
                    </ux-table-column>
                    <ux-table-column field="big" title="大球" :width="60">
                        <template v-slot="scope">
                            <div>{{scope.row.big_1}}</div>
                            <div>{{scope.row.big_2}}</div>
                        </template>
                    </ux-table-column>
                    <ux-table-column field="bigsmall" title="大小球" :width="60">
                        <template v-slot="scope">
                            <div>{{scope.row.bs_init}}</div>
                            <div>{{scope.row.bs_final}}</div>
                        </template>
                    </ux-table-column>
                    <ux-table-column field="small" title="小球" :width="60">
                        <template v-slot="scope">
                            <div>{{scope.row.samll_1}}</div>
                            <div>{{scope.row.samll_2}}</div>
                        </template>
                    </ux-table-column>
                    <ux-table-column title="操作" field="detail" :width="60">
                        <template v-slot="scope">
                            <a class=" me-2 pointer fs-12" @click="removeSelected(scope.row)" style="text-decoration: none; color: #bbb">删</a>
                        </template>
                    </ux-table-column>
                </ux-grid>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Nav from './comment/nav.vue'
import Footer from './comment/footer.vue'
import { handicapToNum,checkColor,convertToReal,compare } from '../utils/utils'
import { checkbackResUrl } from '../constants/http.config'
export default {
    components: { Nav,Footer },
    data() {
        return {
            loading: true,
            fullscreenLoading: false,
            isDark: false,
            league: '',
            home: '',
            away: '',
            color: '',
            qsda: '',
            handicap: '',
            bigsmall: '',
            time: '',
            id: '',
            historyList: [],
            columns: [
                { label: '日期', prop: 'date'},
                { label: '联赛', prop: 'league'},
                { label: '主队', prop: 'home'},
                { label: '比分', prop: 'score'},
                { label: '客队', prop: 'away'},
                {label: '结果', prop: 'state'},
                { label: 'qsda', prop: 'qsda'},
                { label: '指数1', prop: 'index1'},
                {label: '澳/Crown', prop: 'init'},
                {label: '指数2', prop: 'index2'},
                {label: '大球', prop: 'big'},
                {label: '大小球', prop: 'bigsmall'},
                {label: '小球', prop: 'small'},
            ],
            result: [
                {label: '胜',value: '胜'},
                {label: '平',value: '平'},
                {label: '负',value: '负'}
            ],
            leagueList:[]
        }
    },
    mounted() {
        document.title = this.$route.query.home + ' VS ' + this.$route.query.away + '--回查';
        this.id = this.$route.params.id;
        this.qsda = this.$route.query.qsda;
        this.handicap = this.$route.query.handicap.replace('受', '*');
        this.bigsmall = this.$route.query.bigsmall;
        this.color = this.$route.query.color;
        this.league = this.$route.query.league;
        this.time = this.$route.query.time;
        this.home = this.$route.query.home;
        this.away = this.$route.query.away;
        // console.log(this.qsda,this.handicap,this.bigsmall,this.color,this.league,this.home,this.away);
        this.getHistory(this.qsda,this.handicap,this.bigsmall)
    },
    methods: {
        handicapToNum,
        checkColor,
        compare,
        convertToReal,
        async getHistory(qsda,init,bsInit){
            const url = checkbackResUrl + 'qsda=' + qsda + '&init=' + init + '&bsInit=' + bsInit + '&pageNum=1&pageSize=50';
            // const url = 'localhost:8888/api/match/history?';
            await fetch(url, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => response.json())
            .then(data => {
                this.loading = false;
                this.historyList = data.data.list;
                let leaguelist = [];
                for (let index = 0; index < data.data.list.length; index++) {
                    const element = data.data.list[index];
                    leaguelist.push({label: element.league, value: element.league})
                }
                this.leagueList = leaguelist.reduce((acc, cur) => {
                    if (!acc.some(obj => obj.value === cur.value)) {
                        acc.push(cur);
                    }
                    return acc;
                }, [])
            });
        },
        filterHandler({value, row,column}) {
            const property = column['property'];
            return row[property] === value;
        },
        filterLeague({value, row,column}){
            const property = column['property'];
            console.log(property);
            return row[property] === value;
        },
        headerClass({ columnIndex }){
            if (columnIndex>=0) {
                if (columnIndex >=5) {
                    return 'p-0 fs-12 text-center'
                }
                return 'p-0 fs-12'
            }
        },
        columnStyle({ row,columnIndex }){
            if(columnIndex > 0){
                if (columnIndex === 6) {
                // console.log('ssssss',row);
                    return 'background:'+this.checkColor(row.state)+'; text-align: center; color: #000'
                }
                if (columnIndex >= 5) {
                    return 'text-align: center; padding: 0;'
                }
                return 'padding: 0;'
            }
        },
        qsdaColorSet(handicap,qsda){
            let qsdaColor = 'rgb(128,0,255)';
            let lowOrHight = 2;
            if (handicap!=='') {
                const handicapNum = handicapToNum(handicap);
                const realHandicapNum = convertToReal(qsda);
                if (handicapNum === 0) {
                    qsdaColor = 'rgb(128,0,255)';
                    return qsdaColor
                }else{
                    if (typeof handicapNum !== 'undefined' && typeof realHandicapNum !== 'undefined') {
                            lowOrHight = compare(handicapNum, realHandicapNum);
                        if(lowOrHight === 0){
                            qsdaColor = this.isDark ? '#ccc' : 'black';
                            return qsdaColor
                        }
                        if (lowOrHight === 1) {
                            qsdaColor = 'red';
                            return qsdaColor
                        }
                        if (lowOrHight === -1) {
                            qsdaColor = 'green';
                            return qsdaColor
                        }
                    }
                }
            }
        },
        removeSelected(row){
            console.log(row);
            this.$refs.plxTable.remove(row);
        },
    },
}
</script>
<style lang="scss" scoped>
    @import '../assets/sass/global.scss';
    .container {margin-top: 56px;}
</style>