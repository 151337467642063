//设置胜平负颜色
export function checkColor(key) {
    let color = '';
    switch (key) {
        case '胜':
            color = '#ff0000'
            break;
        case '平':
            color = '#19f933'
            break;
        case '负':
            color = '#3bf2f9'
            break;
        default:
            color = 'black'
            break;
    }
    return color;
}

//盘口转换成数字
export function handicapToNum(str) {
    const handicapMap = {
      '平手': 0,
      '平/半': 0.25,
      '受平/半': -0.25,
      '半球': 0.5,
      '受半球': -0.5,
      '半/一': 0.75,
      '受半/一': -0.75,
      '受一球': -1,
      '一球': 1,
      '一/球半': 1.25,
      '受一/球半': -1.25,
      '球半': 1.5,
      '受球半': -1.5,
      '球半/两': 1.75,
      '受球半/两': -1.75,
      '两球': 2,
      '受两球': -2,
      '两/两球半': 2.25,
      '受两/两球半': -2.25,
      '两球半': 2.5,
      '受两球半': -2.5,
      '两球半/三': 2.75,
      '受两球半/三': -2.75,
      '三球': 3,
      '受三球': -3,
      '三/三球半': 3.25,
      '受三/三球半': -3.25,
      '三球半': 3.5,
      '受三球半': -3.5,
      '三球半/四': 3.75,
      '受三球半/四': -3.75,
      '四球': 4,
      '受四球': -4,
      '四/四球半': 4.25,
      '受四球/四球半': -4.25,
      '四球半': 4.5,
      '受四球半': -4.5,
      '四球半/五': 4.75,
      '受四球半/五': -4.75,
      '五球': 5,
      '受五球': -5,
      '五/五球半': 5.25,
      '受五球/五球半': -5.25,
      '五球半': 5.5,
      '受五球半': -5.5,
      '五球半/六': 5.75,
      '受五球半/六': -5.75,
      '六球': 6,
      '受六球': -6,
      '六/六球半': 6.25,
      '受六球/六球半': -6.25,
      '六球半': 6.5,
      '受七球半/七': -6.5,
      '六球半/七': 6.75,
      '受六球半/七': -6.75,
      '七球': 7,
      '受七球': -7,
    };
    return handicapMap[str];
}

//数字转换成盘口
export function numToHandicap(num) {
  let numStr = num.toString();
  let numMap = {
    '0': '平手',
    '0.25': '平/半',
    '-0.25': '*平/半',
    '0.5': '半球',
    '-0.5': '*半球',
    '0.75': '半/一',
    '-0.75': '*半/一',
    '-1': '*一球',
    '1': '一球',
    '1.25': '一/球半',
    '-1.25': '*一/球半',
    '1.5': '球半',
    '-1.5': '*球半',
    '1.75': '球半/两',
    '-1.75': '*球半/两',
    '2': '两球',
    '-2': '*两球',
    '2.25': '两/两球半',
    '-2.25': '*两/两球半',
    '2.5': '两球半',
    '-2.5': '*两球半',
    '2.75': '两球半/三',
    '-2.75': '*两球半/三',
    '3': '三球',
    '-3': '*三球',
    '3.25': '三/三球半',
    '-3.25': '*三/三球半',
    '3.5': '三球半',
    '-3.5': '*三球半',
    '3.75': '三球半/四',
    '-3.75': '*三球半/四',
    '4': '四球',
    '-4': '*四球',
    '4.25': '四/四球半',
    '-4.25': '*四/四球半',
    '4.5': '四球半',
    '-4.5': '四球半',
    '4.75': '四球半/五',
    '-4.75': '*四球半/五',
    '5': '五球',
    '-5': '*五球',
    '-5.25': '五/五球半',
    '5.25': '*五/五球半',
    '5.5': '五球半',
    '-5.5': '五球半',
    '5.75': '五球半/六',
    '-5.75': '*五球半/六',
    '6': '六球',
    '-6': '*六球',
    '-6.25': '六/六球半',
    '6.25': '*六/六球半',
    '6.5': '六球半',
    '-6.5': '六球半',
    '6.75': '六球半/七',
    '-6.75': '*六球半/七',
    '7': '七球',
    '-7': '*七球',
  }
  return numMap[numStr] || '无效值';
}

//qsda转换成实际数字盘口
export function convertToReal(number) {
    // console.log(number);
    const rules1 = [
      { min: 0, max: 25, result: [0.25] },//平/半
      { min: 26, max: 50, result: [0.5] },//半球
      { min: 51, max: 75, result: [0.75] },//半/一
      { min: 76, max: 100, result: [1] },//一球
      { min: 101, max: 125, result: [1.25] },//一球/球半
      { min: 126, max: 150, result: [1.5] },//球半
      { min: 151, max: 175, result: [1.75] },//球半/两球
      { min: 176, max: 200, result: [2] },//两球
      { min: 201, max: 225, result: [2.25] },//两球/两球半
      { min: 226, max: 250, result: [2.5] },//两球半
      { min: 251, max: 275, result: [2.75] },//两球半/三球
      { min: 276, max: 300, result: [3] },//三球
      { min: 301, max: 325, result: [3.25] },//三球/三球半
      { min: 326, max: 350, result: [3.5] },//三球半
      { min: 351, max: 375, result: [3.75] },//三球半/四球
      { min: 376, max: 400, result: [4] },//四球
      { min: 401, max: 425, result: [4.25] },//四球/四球半
      { min: 426, max: 450, result: [4.5] },//四球半
      { min: 451, max: 475, result: [4.75] },//四球半/五球
      { min: 476, max: 500, result: [5] },//五球
      { min: 501, max: 525, result: [5.25] },//五球/五球半
      { min: 526, max: 550, result: [5.5] },//五球半
      { min: 551, max: 575, result: [5.75] },//五球半/六球
      { min: 576, max: 600, result: [6] },//六球
      { min: 601, max: 625, result: [6.25] },//六球/六球半
      { min: 626, max: 650, result: [6.5] },//六球半
      { min: 651, max: 675, result: [6.75] },//六球半/七
      { min: 676, max: 700, result: [7] },//七球
    ];
    const rules2 = [
      { max: 0, min: -25, result: [0] },//平手
      { max: -26, min: -50, result: [-0.25] },//受平/半
      { max: -51, min: -75, result: [-0.5] },//受半球
      { max: -76, min: -100, result: [-0.75] },//受半/一
      { max: -101, min: -125, result: [-1] },//受一球
      { max: -126, min: -150, result: [-1.25] },//受一球/球半
      { max: -151, min: -175, result: [-1.5] },//受球半
      { max: -176, min: -200, result: [-1.75] },//受球半/两球
      { max: -201, min: -225, result: [-2] },//两球/两球半
      { max: -226, min: -250, result: [-2.25] },//两球半
      { max: -251, min: -275, result: [-2.5] },//两球半/三球
      { max: -276, min: -300, result: [-2.75] },//三球
      { max: -301, min: -325, result: [-3] },//三球/三球半
      { max: -326, min: -350, result: [-3.25] },//三球半
      { max: -351, min: -375, result: [-3.5] },//三球半/四球
      { max: -376, min: -400, result: [-3.75] },//四球
      { max: -401, min: -425, result: [-4] },//四球/四球半
      { max: -426, min: -450, result: [-4.25] },//四球半
      { max: -450, min: -475, result: [-4.5] },//四球半/五球
      { max: -476, min: -500, result: [-4.75] },//五球
      { max: -501, min: -525, result: [-5] },//五球/五球半
      { max: -526, min: -550, result: [-5.25] },//五球半
      { max: -551, min: -575, result: [-5.5] },//五球半/六球
      { max: -576, min: -600, result: [-5.75] },//六球
      { max: -601, min: -625, result: [-6] },//六球/六球半
      { max: -626, min: -650, result: [-6.25] },//六球半
      { max: -651, min: -675, result: [-6.5] },//六球半/七
      { max: -676, min: -700, result: [-6.75] },//七球
    ];
    
      let results;
    
      if (number >= 0) {
        // console.log('qsda > 0');
        for (const rule of rules1) {
          if (number >= rule.min && number <= rule.max) {
            results = rule.result;
          }
        }
      }
    
      if (number < 0) {
        // console.log('qsda < 0');
        for (const rule of rules2) {
        // console.log(number, '>=', rule.min,number >= rule.min, number, '<=', rule.max,number<= rule.max);
          if (number >= rule.min && number <= rule.max) {
            results = rule.result;
          }
        }
      }
    
    // console.log('result',results);
      if (results == null || typeof results == 'undefined') {
        // console.log('无');
        return 'undefind';
      }
      return results;
}

//判断高低开
export function compare(handicap, realHandicap) {
    if (Math.abs(handicap) === Math.abs(realHandicap)) {
        if (handicap < realHandicap) {
          // console.log(handicap, '<', realHandicap,'高开');
          return 1;
        } else if (handicap > realHandicap) {
          // console.log(handicap, '>', realHandicap,'高开');
          return 1;
        } else {
          // console.log(handicap, '=', realHandicap,'实开');
          return 0;
        }
      } else if (Math.abs(handicap) < Math.abs(realHandicap)) {
        // console.log(handicap, '<', realHandicap,'低开');
        // if (realHandicap < 0) {
        //     //应该客让，他是主让，对于让球方来说是高开
        //     console.log('高开');
        //     return 1;
        //   }
        return -1;
      } else if (Math.abs(handicap) > Math.abs(realHandicap)) {
        // console.log(handicap, '>', realHandicap,'高开');
        // if (handicap<0) {
        //     //应该客队让球，他反而主队让球，说明主队是强行让盘 0.5 > 0.25 -0.5 0.25 主让变客让
        //     console.log('低开');
        //     return -1;
        //   }
        return 1;
      }
}
//日期格式化
export function dateFormatted (date) {
  // const date = new Date();
  const year = date.getFullYear();
  let month = date.getMonth();
  let day = date.getDate();
  month = month < 9 ? '0'+( month + 1) : month + 1;
  day = day < 10 ? '0' + day : day;
  return year + '-' + month+'-' + day;
}

//球队排名简化纯数字
export function filteredNum(str){
  if (null == str || '' == str) {
    return '';
  }else{
    const rank = str.match(/\d+/) !==null ? '['+str.match(/\d+/)[0]+']' : '';
    return rank;
  }
}