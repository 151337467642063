<template>
    <div>
    <div class="container py-2" v-loading="loading" :style="{height: loading || this.todayData.length === 0 ? 'calc(100vh - 129px)' : 'auto'}">
        <Nav :isDark.sync="this.isDark" @darkmode="isDark = $event"></Nav>
        <h1 class="fs-24 text-center mt-2">今日回查数据获取</h1>
            <div v-if="this.todayData.length === 0 && !loading" class="text-center text-secondary h-75 d-flex align-items-center justify-content-center flex-column mt-2 rounded mb-4">
                <span class="mb-3">暂无今日回查数据，请点击“获取回查”按钮</span>
                <!-- <el-button type="text" class="fs-12" v-show="isnew" @click="switchButton">返回旧版</el-button> -->
                <el-button type="text" class="fs-12 ms-0" v-show="!isnew" @click="switchButton">使用新版</el-button>
                <!-- <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading" v-show="!isnew" @click="getMatch('old')">获取回查(旧)</el-button> -->
                <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading" v-show="isnew" @click="getMatch()">获取回查(新)</el-button>
            </div>
        <div v-if="this.todayData.length > 0 && !loading">
            <!-- <el-button class="mb-3" type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="getMatch()">重新抓取</el-button> -->
            <div class="d-flex justify-content-between">
                <b>{{ this.dateTitle }}</b>
                <div>
                    <a href="javarscrip:()" @click="reCrawler()" class="fs-12 text-end text-primary">不满意？重新抓取比赛>></a>
                    <!-- <a class="fs-12 text-end text-primary ms-2" v-if="isnew" @click="switchNewOrOld('old')">返回旧版>></a> -->
                    <a class="fs-12 text-end text-primary ms-2" v-if="!isnew" @click="switchNewOrOld()">使用新版>></a>
                </div>
            </div>
            <Edite class="mt-2" :matchdata.sync="this.todayData" :dataType="'checkback'"></Edite>
        </div>
    </div>
    <Footer></Footer>
    </div>
</template>
<script>
import Edite from './edite.vue';
import Nav from './comment/nav.vue'
import Footer from './comment/footer.vue'
import { dateFormatted } from '../utils/utils'
export default {
    components: { Edite, Nav,Footer },
    inject: ['reload'],
    data() {
        return {
            loading: true,
            fullscreenLoading: false,
            tips: '',
            todayData: [],
            dateTitle: '',
            isDark: false,
            isnew: true
        }
    },
    mounted() {
        this.validatorData();
        // this.loading = false;
    },
    methods: {
        dateFormatted,
        switchButton(){
            this.isnew = !this.isnew
        },
        switchNewOrOld(type){
            this.loading = true
            if (type == 'old') {
                this.isnew = false
                this.todayData = []
                this.validatorData(type);
            }else{
                this.isnew = true
                this.todayData = []
                this.validatorData();
            }
        },
        getData(url){
            fetch(url, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => response.json())
            .then(data => {
                this.loading = false;
                // const todayDate = new Date();
                // console.log(data.data[0].date, this.dateFormatted(todayDate));
                // if (data.data[0].date == this.dateFormatted(todayData)){
                //     console.log('日期相同');
                    this.dateTitle = data.data[0].date;
                    this.todayData = data.data;
                    
                // }else{
                //     console.log('日期不相同');
                // }
            }).catch(err=>{
                this.loading = false;
                console.log('读取数据出错');
                console.log(err);
            })
        },
        validatorData(type){
            console.log('验证数据',type);
            // const url = 'https://api.qsda.net/api/match/checkback';
            let url = '';
            if (type == 'old') {
                console.log('olddd');
                url = 'https://api.qsda.net/api/match/checkback';
                this.getData(url);
            }else{
                console.log('newwww');
                url = 'https://api.qsda.net/api/match/checkback';
                this.getData(url);
            }
        },
        getMatch(type){
            this.fullscreenLoading = true;
            let url = ''
            if (type == 'old') {
                url = 'https://api.qsda.net/api/crawler/checkback';
            }else{
                const todayData = this.dateFormatted(new Date());
                url = 'https://api.qsda.net/api/crawler/checkback/' + todayData;
            }
            // const url = 'https://api.qsda.net/api/crawler/checkback';
            fetch(url,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(data => {
                this.fullscreenLoading = false;
                if (data.code == 200) {
                    this.$notify({
                        title: '抓取成功',
                        message: data.data,
                        type: 'success',    
                        // duration: 0
                    });
                    this.validatorData();
                } else {
                    this.tips = '抓取失败，请检查格式是否正确！';
                    this.$notify.error({
                        title: '抓取失败',
                        message: '未知错误，请重试',
                        // duration: 0
                    });
                }
                console.log(data);
            }).catch(err=>{
                this.fullscreenLoading = false;
                this.$notify.error({
                        title: '抓取失败',
                        message: '连接超时',
                        // duration: 0
                    });
                console.error(err);
            })
        },
        reCrawler(){
            this.todayData = [];
        },
    }
}
</script>
<style lang="scss" scoped>
    @import '../assets/sass/global.scss';
    .container {margin-top: 56px;}
</style>