<template lang="">
    <div class="text-center border-top py-4">
        <a :href="'https://beian.miit.gov.cn/#/home'" target="_blank" class="text-secondary text-decoration-none">ICP: {{this.icp}}</a>
    </div>
</template>
<script>
export default {
    data(){
        return {
            icp: '粤ICP备2023149227号',
        }
    },
    methods:{
        
    },
    created(){
        
    },
    mounted(){
        
    }
}
</script>
<style lang="scss" scoped>
    .NIGHT {
        .text-dark {color: #fff!important; text-decoration: none;}
    }
</style>