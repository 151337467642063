<template>
    <div class="container py-2" style="height: calc(100vh - 129px);">
        <Nav :isDark.sync="this.isDark" @darkmode="isDark = $event"></Nav>
        <h1 class="fs-24 text-center my-3">修改联赛名</h1>
        <div>
            <!-- <form class="d-flex mb-3 justify-content-center" role="search">
                <input v-model="input" class="form-control me-2 w-50" type="search" placeholder="国家搜索" aria-label="Search">
                <button class="btn btn-outline-primary" type="submit">搜索</button>
            </form> -->
            <el-input placeholder="搜索国家名..." v-model="input" class="input-with-select mb-3" clearable>
                <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
            </el-input>
            <el-form class="d-flex demo-ruleForm my-3" ref="form" :rules="rules" :model="form" label-width="80px">
                <el-form-item label="id" prop="id">
                    <el-input v-model="form.id"></el-input>
                </el-form-item>
                <el-form-item label="国家" prop="c_name">
                    <el-input v-model="form.c_name"></el-input>
                </el-form-item>
                <el-form-item label="态况名" prop="l_name">
                    <el-input v-model="form.l_name"></el-input>
                </el-form-item>
                <el-form-item label="捷报名" prop="s_name">
                    <el-input v-model="form.s_name"></el-input>
                </el-form-item>
                <el-form-item label="联赛颜色">
                    <el-input v-model="form.color"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit('form')">添加联赛</el-button>
                </el-form-item>
            </el-form>
            <ux-grid
             ref="plxTable"
             stripe
             show-overflow
             use-virtual
             :data = data
             :highlightCurrentRow="true"
             :checkboxConfig="{highlight: true}"
             :edit-config="{trigger: 'click', mode: 'cell'}">
             <ux-table-column type="checkbox" :width="40"/>
             <!-- <ux-table-column type="index" :width="40"/> -->
             <ux-table-column field="id" title="联赛id" edit-render>
                <template v-slot:edit="scope">
                    <el-input v-model="scope.row.id"></el-input>
                </template>
            </ux-table-column>
             <ux-table-column field="c_name" title="国家" :filters="filters" :filter-method="filterCountry" edit-render>
                <template v-slot:edit="scope">
                    <el-input v-model="scope.row.c_name"></el-input>
                </template>
            </ux-table-column>
             <ux-table-column field="l_name" title="态况联赛名" edit-render>
                <template v-slot:edit="scope">
                    <el-input v-model="scope.row.l_name"></el-input>
                </template>
            </ux-table-column>
             <ux-table-column field="s_name" title="捷报联赛名" edit-render>
                <template v-slot:edit="scope">
                    <el-input v-model="scope.row.s_name"></el-input>
                </template>
             </ux-table-column>
             <ux-table-column field="color" title="联赛颜色" edit-render>
                <template v-slot:edit="scope">
                    <el-input v-model="scope.row.color"></el-input>
                </template>
             </ux-table-column>
             <!-- <ux-table-column title="操作" field="detail" :width="90" fixed="right">
                <template v-slot="scope">
                    <a class="text-primary pointer" @click="insertEvent(scope.row)">保存</a>
                </template>
             </ux-table-column> -->
            </ux-grid>
        </div>
        <div class="bottom-btn w-100 text-end py-2">
         <el-button @click="saveChange">保存修改</el-button>
         <!-- <el-button @click="removeSel">删除选中</el-button> -->
     </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Nav from '../comment/nav.vue'
import Footer from '../comment/footer.vue'
import { searchLgByCnameUrl, createLeagueUrl, updateLeagueUrl } from '../../constants/http.config'
export default {
    components: { Nav,Footer },
    data(){
        return {
            isDark: false,
            input: '',
            data: [],
            filters: [],
            countries: [],
            loading: false,
            columns: [
                { label: '国家', prop: 'country'},
                { label: '态况联赛名', prop: 'tk-league'},
                { label: '联赛', prop: 'league'},
                { label: '联赛id', prop: 'id'},
            ],
            form: {
                id: '',
                c_name: '',
                l_name: '',
                s_name: '',
                color: '#000',
            },
            rules: {
                id: [
                    { required: true, message: '请输入id', trigger: 'blur' },
                ],
                c_name: [
                    { required: true, message: '请输入国家名', trigger: 'blur' }
                ],
                l_name: [
                    { required: true, message: '请输入态况名', trigger: 'blur' },
                ],
                s_name: [
                    { required: true, message: '请输入捷报名', trigger: 'blur' },
                ]
            }
        }
    },
    mounted() {
        // this.getLeagueList();
    },
    methods: {
        filterCountry({value, row, column}){
            const property = column['property'];
            return row[property] === value;
        },
        updateFilterEvent(){
            const column = this.$refs.plxTable.getColumnByField('c_name')
            const countrySet = new Set(this.data.map((item) => item.c_name));
            const countryArray = Array.from(countrySet);
            this.filters = countryArray.map((item) => ({ label: item, value: item }));
            this.$refs.plxTable.setFilter(column, this.filters)
            this.$refs.plxTable.updateData()
        },
        removeSelected(row){
            // console.log(row);
            this.$refs.plxTable.remove(row)
        },
        async insertEvent (row) {
            // console.log(row);
            let record = {
                // c_id: 'id',
                name: '态况联赛名',
                rename: '捷报联赛名',
                color: '#000',
            }
            // 加入行, 表示加入行的row对象，row为空则插入第一行，为-1插入最后一行
            let { row: newRow } = await this.$refs.plxTable.insertRow(record, row)
            // 激活单元格编辑
            await this.$refs.plxTable.setActiveRow(newRow)
        },
        async updateLeague(url,chgData){
            fetch(url,{
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(chgData)
                    })
                    .then(response => response.json());
        },
        saveChg(){
            const chgData = this.$refs.plxTable.getTableData().tableData;
            chgData.map(obj => {
                delete obj._XID;
                delete obj.detail;
                return obj;
            });
            // console.log(chgData);
            // const url = '/league/update-list';
            fetch(updateLeagueUrl,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(chgData)
            })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                if (data.msg === 'success') {
                    //弹出成功提示
                    this.$alert('更新数据成功！', '', {
                        confirmButtonText: '确定',
                        callback: () => {
                            location.reload();
                        }
                    });
                }else{
                    //弹出失败提示
                    this.$alert('更新数据失败！', '', {
                        confirmButtonText: '确定',
                        callback: () => {
                            location.reload();
                        }
                    });
                }
            })
            .catch(error => {
                console.error('Error:', error);
                //弹出失败提示
                this.$alert('未知错误！更新数据失败！', '', {
                        confirmButtonText: '确定',
                });
            });
        },
        async saveChange(){
            const res = this.$refs.plxTable.getCheckboxRecords();
            if (res.length === 0) {
                console.log(0);
                this.$message({
                    showClose: true,
                    type: 'warning',
                    message: '请选中至少一条数据!'
                });
            }else{
                // console.log(res);
                let countSuccess = 0;
                let countFail = 0;
                const requests = [];
                for (let index = 0; index < res.length; index++) {
                    const chgData = res[index];
                    // const url = '/league/update';
                    const request = fetch(updateLeagueUrl,{
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(chgData)
                    })
                    .then(response => response.json())
                    .then(data => {
                        console.log(data);
                        if (data.msg == 'success') {
                            countSuccess++;
                        }else{
                            countFail++;
                        }
                    }).catch(error => {
                        console.error('Error:', error);
                    });
                    requests.push(request);        
                }
                Promise.all(requests).then(() => {
                    this.$notify.info({
                        title: '消息',
                        message: `更新${countSuccess}条成功，${countFail}条失败`
                    });
                }).catch(error => {
                    console.error('Error:', error);
                });
            }
        },
        search(){
            if (this.input !== '') {
                // const url = '/league/search?name=' + this.input;
                fetch(searchLgByCnameUrl + this.input).then(response => response.json())
                .then(res => {
                    // console.log(res.data);
                    this.data = res.data;
                    this.$refs.plxTable.updateData()
                }).catch(error => console.error('Error:', error));
            }else{
                this.$message.error('搜索的内容不能为空!');
            }
        },
        onSubmit(formName){
            // console.log(this.form);
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$message.error('添加的id、国家、态况名、捷报名不能为空!');
                    // const url = '/league/create';
                    fetch(createLeagueUrl,{
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(this.form)
                    })
                    .then(response => response.json())
                    .then(data => {
                        // console.log(data);
                        if (data.msg == 'success') {
                            this.$notify({
                                title: '成功',
                                message: '添加联赛成功，' + data.data,
                                type: 'success'
                            });
                        }else{
                            this.$notify.error({
                                title: '失败',
                                message: data.data
                            });
                        }
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        this.$notify.error({
                            title: '错误',
                            message: '添加联赛出错'
                        });
                    });
                } else {
                    this.$message.error('添加的id、国家、态况名、捷报名不能为空!');
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
    .container {margin-top: 56px;}
</style>