<template>
  <div v-loading="loading" :style="{height: this.data.length === 0 ? 'calc(100vh - 76px)' : 'auto'}">
    <div class="container" :class="{'h-100':this.data.length === 0}" :style="{height: loading ? '100vh' : 'auto'}">
        <Nav :isDark.sync="this.isDark" @darkmode="isDark = $event"></Nav>
        <div class="d-flex justify-content-between align-items-center" style="width: 950px;margin: 0 auto;">
            <span class="fs-4">{{ this.dateStr }}</span>
            <router-link :to="'/qsda-today'" class="text-primary" style="cursor: pointer;">查看今日比赛>></router-link>
        </div>
        <div class="mb-4 text-center" :class="{'h-100':this.data.length === 0}" v-if="this.data.length === 0 && !loading">
            <div class="nodata d-flex flex-column justify-content-center" :class="{'h-100':this.data.length === 0}">
                <span class="align-center">暂无比赛数据</span>
            </div>
        </div>
        <table :class="{'table-dark': this.isDark}" class="table table-striped border" ref="table" v-if="this.data.length !== 0" style="width: 950px;margin: 0 auto;">
                <thead class="fs-12" style="background-color: #82959F!important;">
                    <tr class="font-weight-normal py-0 text-white">
                        <th class="border-end font-weight-normal py-1" width='40'>#</th>
                        <th class="border-end text-center font-weight-normal py-1">联赛</th>
                        <th class="border-end text-center font-weight-normal py-1" width='80'>时间</th>
                        <th class="border-end text-end font-weight-normal py-1">主队</th>
                        <th class="border-end text-center font-weight-normal py-1" width='80'>qsda</th>
                        <th class="border-end text-left font-weight-normal py-1">客队</th>
                        <th class='border-end text-center font-weight-normal py-1' v-if="isToday == true">指数1</th>
                        <th class='border-end text-center font-weight-normal py-1'>Crow*</th>
                        <th class='border-end text-center font-weight-normal py-1' v-if="isToday == true">指数2</th>
                        <th class='text-center font-weight-normal py-1' v-if="isToday == false">大小</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in data" :key="index">
                        <td class="border-end align-middle" width='40'>{{index+1}}</td>
                        <td :style="{'background':callSetColor(item.color,item.name)}" class='border-end td2 text-white align-middle text-center py-0' width='100'>{{ item.name }}</td>
                        <td class='border-end text-center align-middle' width='80'>{{item.time}}</td>
                        <td class='border-end text-end align-middle'>
                            <font>{{ item.homerank }}</font>
                            {{ item.home }}
                        </td>
                        <td class='text-center font-weight-bold border-end align-middle qsda qsdaColor' width='80' :style="{color: qsdaColorSet(item.handicap,item.qsda)}"><a :href="'https://zq.titan007.com/analysis/'+item.id+'cn.htm'" target='_blank' class="text-decoration-none">{{ item.qsda }}</a>
                        </td>
                        <td class='border-end text-left align-middle'>
                            {{ item.away }}
                            <font>{{ item.awayrank }}</font>
                        </td>
                        <td class='border-end text-center' width="80" v-if="isToday == true">
                            <div style='color:#880000;'>{{ item.index1 }}</div>
                            <div>{{ item.index2 }}</div>
                        </td>
                        <td class='border-end text-center' width="120">
                            <div style='color:#880000;'>{{ item.handicap }}</div>
                            <div v-if="isToday == true">{{ item.bigsmall }}</div>
                        </td>
                        <td class='border-end text-center' width="80" v-if="isToday == true">
                            <div style='color:#880000;'>{{ item.index1 }}</div>
                            <div>{{ item.index2 }}</div>
                        </td>
                        <td class='border-end text-center' v-if="isToday == false">{{ item.bigsmall }}</td>
                    </tr>
                </tbody>
            </table>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import {setcolor } from '@/utils/setcolor';
import { checkColor, handicapToNum, convertToReal, compare, dateFormatted } from '../utils/utils'
import Nav from './comment/nav.vue'
import Footer from './comment/footer.vue'
export default {
    inject: ['reload'],
    components: { Nav,Footer },
    data() {
        return {
            data: [],
            isDark: false,
            isToday: true,
            isTomorrow: false,
            loading: true,
            dateStr: '',
            currentDate: 0
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        setcolor,
        dateFormatted,
        handicapToNum,
        checkColor,
        convertToReal,
        compare,
        callSetColor(color,league){
            return setcolor(color,league);
        },
        qsdaColorSet(handicap,qsda){
            let qsdaColor = 'rgb(128,0,255)';
            let lowOrHight = 2;
            if (handicap!=='') {
                const handicapNum = handicapToNum(handicap);
                const realHandicapNum = convertToReal(qsda);
                if (handicapNum === 0) {
                    qsdaColor = 'rgb(128,0,255)';
                    return qsdaColor
                }else{
                    if (typeof handicapNum !== 'undefined' && typeof realHandicapNum !== 'undefined') {
                            lowOrHight = compare(handicapNum, realHandicapNum);
                        if(lowOrHight === 0){
                            qsdaColor = '#666';
                            return qsdaColor
                        }
                        if (lowOrHight === 1) {
                            qsdaColor = 'red';
                            return qsdaColor
                        }
                        if (lowOrHight === -1) {
                            qsdaColor = 'green';
                            return qsdaColor
                        }
                    }
                }
            }
        },
        getData(){
            // const today = new Date();
            // const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
            // const currentHours = today.getHours()
            // const currentMin = today.getMinutes();
            const tomorrowUrl = 'https://api.qsda.net/api/match/tomorrow';
            fetch(tomorrowUrl)
            .then(response => response.json())
            .then(data => {
                // if (currentHours < 10 || currentHours == 10 && currentMin < 30) {
                //     console.log('日期不是今日但小于10:30');
                    this.dateStr = data.data[0].date
                    this.data = data.data;
                    this.loading = false;
                // }else if (data.data[0].date == this.dateFormatted(tomorrow)) {
                //     console.log('日期是明日');
                    // this.dateStr = this.dateFormatted(tomorrow)
                    // this.data = data.data;
                    this.isToday = false;
                    // this.loading = false;
                // }else{
                //     console.log('没有数据');
                //     this.data = [];
                //     this.dateStr = ''
                //     this.loading = false;
                //     this.isToday = false;
                // }
            })
            .catch(error => console.error('Error:', error));
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../assets/sass/global.scss';
    .container{padding-top: 60px; padding-bottom: 15px;}
    font {color: rgb(136, 136, 136);}
    .table {
        --bs-table-bg: none;
        --bs-table-color: none;
        td {padding: 0.75rem;}
        .td2 {--bs-table-bg-type: inherit;}
    }
    .nodata {
            color: #ccc;
        }
</style>