const apiDomainPrefix = 'https://api.qsda.net/api/'

const crawlerTomorrowUrl = apiDomainPrefix + 'crawler/tomorrow'
const crawlerTodayUrl = apiDomainPrefix + 'crawler/today'
const crawlerLeagueUrl = apiDomainPrefix + 'crawler/league_list'
const loginUrl = apiDomainPrefix + 'users/editer/login'
//登录
const logoutUrl = apiDomainPrefix + ''
//抓取明日捷报联赛列表
const crawlerLeagueListUrl = apiDomainPrefix + 'crawler/tomorrow_jiebao'
//抓取明日比赛
const crawlerTomorrowMatchUrl = apiDomainPrefix + 'crawler/tomorrow_tk'
//读取明日捷报联赛列表
const leagueListTomorrowUrl = apiDomainPrefix + 'match/tomorrow_jiebao'
//读取某日期比赛
const getMatchUrl = apiDomainPrefix + 'match/date/'
//删除比赛
const deleteMatchUrl = apiDomainPrefix + 'match/delete?id='
//按联赛查找球队
const searchTimByLidUrl = apiDomainPrefix + 'team/search-by-lid?id='
//按态况队名查找球队
const searchTimByNameUrl = apiDomainPrefix + 'team/search?name='
//获取所有联赛列表
const leagueListUrl = apiDomainPrefix + 'league/list'
//更新球队信息
const updateTeamUrl = apiDomainPrefix + 'team/update'
//按国家名查找联赛
const searchLgByCnameUrl = apiDomainPrefix + 'league/search?name='
//添加联赛
const createLeagueUrl = apiDomainPrefix + 'league/create'
//修改联赛保存(旧)
// const updateLeagueUrl = apiDomainPrefix + 'league/update-list'
//修改联赛保存(新)
const updateLeagueUrl = apiDomainPrefix + 'league/update'
//读取凯利数据
const kailiUrl = apiDomainPrefix + 'match/kaili/'
//抓取凯利数据
const kailiCrawlerUrl = apiDomainPrefix + 'crawler/kaili/'
//回查数据结果
const checkbackResUrl = apiDomainPrefix + 'match/history?'
//今日回查
const checkbackListUrl = apiDomainPrefix + 'match/checkback'
//明日回查
const checkBackListTomorrowUrl = apiDomainPrefix + 'match/checkback-t'
//抓取回查
const checkbackCrawler = apiDomainPrefix + 'crawler/checkback/'

export {
    crawlerTomorrowUrl,
    crawlerTodayUrl,
    crawlerLeagueUrl,
    loginUrl,
    logoutUrl,
    leagueListTomorrowUrl,
    crawlerLeagueListUrl,
    getMatchUrl,
    crawlerTomorrowMatchUrl,
    checkbackResUrl,
    deleteMatchUrl,
    searchTimByLidUrl,
    leagueListUrl,
    updateTeamUrl,
    searchLgByCnameUrl,
    createLeagueUrl,
    updateLeagueUrl,
    searchTimByNameUrl,
    kailiUrl,
    kailiCrawlerUrl,
    checkbackListUrl,
    checkBackListTomorrowUrl,
    checkbackCrawler
}