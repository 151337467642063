<template>
    <div>
    <div class="container py-2" v-loading="loading" :style="{height: loading || this.todayData.length === 0 ? 'calc(100vh - 129px)' : 'auto'}">
        <Nav :isDark.sync="this.isDark" @darkmode="isDark = $event"></Nav>
        <h1 class="fs-24 text-center mt-2">今日数据获取</h1>
            <div v-if="this.todayData.length === 0 && !loading" class="text-center text-secondary h-75 d-flex align-items-center justify-content-center flex-column mb-4 mt-2 rounded">
                <span class="mb-3">暂无今日联赛列表，请点击“获取联赛”按钮</span>
                <!-- <router-link type="text" :to="'/today'" class="fs-12 text-primary mb-2">返回旧版</router-link> -->
                <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="getMatch()">获取联赛(新)</el-button>
            </div>
        <div v-if="this.todayData.length > 1 && !loading">
            <!-- <el-button class="mb-3" type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="getMatch()">重新抓取</el-button> -->
            <div class="d-flex justify-content-between">
                <b>{{ this.dateTitle }}</b>
                <div>
                    <el-button type="text" class="fs-12 text-primary me-2" @click="checkLeagueCrawler">抓取国彩联赛列表>></el-button>
                    <el-popover placement="bottom" trigger="click" content="把所有比赛先全选删除即可再抓取">
                        <el-button slot="reference" type="text" class="fs-12 text-primary">重新抓取比赛>></el-button>
                    </el-popover>
                    <!-- <el-button type="text" class="fs-12 text-end text-primary">重新抓取比赛>></el-button> -->
                    <!-- <router-link :to="'/today'" class="fs-12 text-primary ps-3" style="cursor: pointer;">返回旧版>></router-link> -->
                </div>
            </div>
            <Edite class="mt-2" :matchdata="this.todayData" :dataType="'today-new'"></Edite>
        </div>
    </div>
    <Footer></Footer>
    </div>
</template>
<script>
import Edite from './edite.vue';
import Nav from './comment/nav.vue'
import Footer from './comment/footer.vue'
import { dateFormatted } from '../utils/utils'
export default {
    components: { Edite, Nav,Footer },
    inject: ['reload'],
    data() {
        return {
            loading: true,
            fullscreenLoading: false,
            tips: '',
            todayData: [],
            dateTitle: '',
            isDark: false,
            checkLeague: []
        }
    },
    mounted() {
        this.validatorData();
        this.getCheckLeague();
    },
    methods: {
        dateFormatted,
        // dateFormatted (date) {
        //     // const date = new Date();
        //     const year = date.getFullYear();
        //     let month = date.getMonth();
        //     let day = date.getDate();
        //     month = month < 9 ? '0'+( month + 1) : month + 1;
        //     day = day < 10 ? '0' + day : day;
        //     return year + '-' + month+'-' + day;
        // },
        checkDate(){
            // 设置目标时间为 10:30 AM
            let targetTime = new Date();
            let currentTime = new Date();
            targetTime.setHours(10);
            targetTime.setMinutes(35);
            targetTime.setSeconds(0);
            // 比较当前时间和目标时间
            if (currentTime > targetTime) {
                console.log("当前时间大于 10:35 AM，读取今日数据");
                return this.dateFormatted(currentTime);
            } else {
                console.log("当前时间小于 10:35 AM，读取昨天数据");
                // 将日期减一天
                currentTime.setDate(currentTime.getDate() - 1)
                return this.dateFormatted(currentTime);
                // console.log(dateParams);
            }
        },
        validatorData(){
            console.log('验证数据');
            let date = this.checkDate();
            const url = 'https://api.qsda.net/api/match/date/' + date;
            // const url = '/match/date/' + date;
            fetch(url)
            .then(response => response.json())
            .then(data => {
                this.loading = false;
                this.dateTitle = data.data.length > 0 ? data.data[0].date : '';
                this.todayData = data.data;
            }).catch(err=>{
                this.loading = false;
                console.log('读取数据出错');
                console.log(err);
            })
        },
        getMatch(){
            this.fullscreenLoading = true;
            const url = 'https://api.qsda.net/api/crawler/today_match';
            // const url = '/crawler/today_match';
            fetch(url,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(data => {
                this.fullscreenLoading = false;
                if (data.code == 200) {
                    this.$notify({
                        title: '抓取成功',
                        message: data.data,
                        type: 'success',    
                        // duration: 0
                    });
                    this.validatorData();
                } else {
                    this.tips = '抓取失败，请检查格式是否正确！';
                    this.$notify.error({
                        title: '抓取失败',
                        message: '未知错误，请重试',
                        // duration: 0
                    });
                }
                console.log(data);
            }).catch(err=>{
                this.fullscreenLoading = false;
                this.$notify.error({
                        title: '抓取失败',
                        message: '连接超时',
                        // duration: 0
                    });
                console.error(err);
            })
        },
        reCrawler(){
            this.todayData = [];
        },
        checkLeagueCrawler(){
            this.loading = true;
            const today = this.dateFormatted(new Date())
            if (this.checkLeague.length > 0 && this.checkLeague[0].date == today) {
                this.loading = false;
                this.$notify.error({
                    title: '今日数据已经有了',
                    message: `数据日期:${this.checkLeague[0].date}`,
                });
                return
            }
            // const url = '/crawler/check-league';
            const url = 'https://api.qsda.net/api/crawler/check-league';
            fetch(url,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(data => {
                this.loading = false;
                this.fullscreenLoading = false;
                if (data.code == 200) {
                    this.$notify({
                        title: '抓取成功',
                        message: data.data,
                        type: 'success',    
                        // duration: 0
                    });
                } else {
                    this.tips = '抓取失败，请检查格式是否正确！';
                    this.$notify.error({
                        title: '抓取失败',
                        message: '未知错误，请重试',
                        // duration: 0
                    });
                }
                console.log(data);
            }).catch(err=>{
                this.loading = false;
                this.$notify.error({
                        title: '抓取失败',
                        message: '连接超时',
                        // duration: 0
                    });
                console.error(err);
            })
        },
        getCheckLeague(){
            // const url = '/league/check-league';
            const url = 'https://api.qsda.net/api/league/check-league';
            fetch(url)
            .then(response => response.json())
            .then(data => {
                this.checkLeague = data.data;
            }).catch(err=>{
                console.log('读取数据出错',err);
            })
        }
    }
}
</script>
<style lang="scss" scoped>
    @import '../assets/sass/global.scss';
    .container {margin-top: 56px;}
</style>