<template>
    <div v-loading="loading" :style="{height: this.data.length === 0 ? '100vh' : 'auto'}">
        <Nav :isDark.sync="this.isDark" @darkmode="isDark = $event"></Nav>
        <div class="container" style="height: calc(100% - 100px);">
            <div v-if="this.data.length === 0 && !this.loading" class="text-center text-secondary d-flex flex-column justify-content-center align-items-center h-75">
                <h4 class="my-3">{{ this.team }}</h4>
                <h5 class="my-3">离散度</h5>
                <span class="mb-3">暂无数据</span>
                <hr class="w-100">
                <h5 class="my-3">当前凯利指数</h5>
                <span class="mb-3">暂无数据</span>
                <el-button type="primary" @click="fetchChartData()">获取数据</el-button>
            </div>
            <div class="text-center" v-if="this.data.length > 0">
                <div class="fs-14 mt-3">
                    <span class="me-3">{{ this.league }}</span>
                    <span>{{ this.date }}</span>
                </div>
                <h4 class="my-3">{{ this.team }}</h4>
                <h5 class="mt-3">离散度</h5>
                <div ref="myChart" style=" height: 500px; margin: 0 auto;"></div>
                <h5 class="mt-4">当前凯利指数</h5>
                <div ref="myBarChart" :style="this.isMobile ? 'height: 850px; margin: 0 auto;':'height: 350px; margin: 0 auto;'"></div>
                <div></div>
                <el-button type="text" @click="fetchChartData()">刷新数据</el-button>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import Nav from './nav.vue'
import Footer from './footer.vue'
import {kailiUrl, kailiCrawlerUrl} from '../../constants/http.config'
export default {
    inject: ['reload'],
    components: { Nav,Footer },
    data() {
        return {
            team: '',
            isDark: false,
            loading: true,
            data: [],
            kailiData: [],
            id: '',
            league: '',
            date: '',
            isMobile: false
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        this.team = this.$route.query.teams;
        document.title = this.team + ' - 离散度';
        // console.log(this.team);
        this.checkDevice();
        this.getChartData(this.id);
    },
    methods: {
        ///判断设备
        checkDevice() {
            const userAgent = window.navigator.userAgent;
            const mobileAgents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad","iPod"];
            this.isMobile = mobileAgents.some(agent => userAgent.includes(agent));
            console.log(this.isMobile);
        },
        ///抓取数据
        fetchChartData(){
            this.loading = true
            // const apiUrl = 'https://api.qsda.net/api/crawler/kaili/' + this.id;
            // console.log(apiUrl);
            fetch(kailiCrawlerUrl + this.id, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                if (data.code == 200) {
                    this.loading = false;
                    // this.$notify({
                    //     title: '抓取成功',
                    //     message: data.data,
                    //     type: 'success',    
                    //     // duration: 0
                    // });
                    this.$alert('更新数据成功！', '', {
                        confirmButtonText: '确定',
                        callback: () => {
                            location.reload();
                        }
                    });
                } else {
                    this.$notify.error({
                        title: '抓取失败',
                        message: '未知错误，请重试'
                    });
                }
            });
        },
        ///读取数据
        getChartData(id) {
            // this.loading = false
            // const url = 'https://api.qsda.net/api/match/kaili/'+id;
            fetch(kailiUrl + id)
            .then(response => response.json())
            .then(data => {
                // console.log(data);
                let chartData = data.data.data.reverse();
                this.data = chartData;
                this.kailiData = data.data.kaili
                if (chartData.length > 0) {
                    this.loading = false;
                }
                this.team = data.data.team;
                document.title = this.team;
                this.league = data.data.league;
                this.date = data.data.date;
                console.log(this.league,this.date);
                //x轴数据
                var xData = chartData.map((item) => {
                    let date = new Date(item.time);
                    let hours = date.getHours();
                    let minutes = date.getMinutes();
                    // 如果小时或分钟小于10，前面添加一个'0'
                    if (hours < 10) hours = '0' + hours;
                    if (minutes < 10) minutes = '0' + minutes;
                    let timeStr = hours + ':' + minutes;
                    return timeStr;
                });
                //y轴数据
                var win = chartData.map((item) => {
                    return item.win;
                })
                var draw = chartData.map((item) => {
                    return item.draw;
                });
                var lose = chartData.map((item) => {
                    return item.lose;
                });
                var companyList = this.kailiData.list.map((item) => {
                    return item.company;
                });
                var w = this.kailiData.list.map((item) => {
                    return item.w;
                });
                var d = this.kailiData.list.map((item) => {
                    return item.d;
                })
                var l = this.kailiData.list.map((item) => {
                    return item.l;
                })
                // var r = this.kailiData.list.map((item) => {
                // return item.d_rate;
                // })
                this.$nextTick(() => {
                    this.initChart(xData, win, draw, lose);
                    this.initBarChart(companyList, w, d, l);
                });
            }).catch((err) => {
                console.log(err);
                this.$message.error('获取数据失败');
                this.loading = false;
                this.chartData = [];
                this.kailiData = [];
            });
        },
        ///离散线图
        initChart(xData, win, draw, lose) {
            const myChart = echarts.init(this.$refs.myChart);
            myChart.setOption({
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['主胜', '平局', '客胜']
                },
                grid: {
                    left: '5%',
                    right: '5%',
                    bottom: '8%'
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: xData,
                    axisTick: {
                        interval: 1
                    },
                    axisLabel: {
                        interval: 1
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        inside: true,
                        margin: 0,
                        verticalAlign: "bottom",
                        color: '#ccc'
                    }
                },
                series: [
                    {
                    name: '主胜',
                    type: 'line',
                    itemStyle: {
                        color: "#ee6666"
                    },
                    data: win
                    },
                    {
                    name: '平局',
                    type: 'line',
                    data: draw
                    },
                    {
                    name: '客胜',
                    type: 'line',
                    data: lose
                    }
                ]
            });
        },
        ///凯利指数柱形图
        initBarChart(x,w,d,l) {
            const myChart = echarts.init(this.$refs.myBarChart);
            let xOption = {}
            let yOption = {}
            if (this.isMobile) {
                // console.log('11111');
                xOption = {
                    type: 'value',
                    min: function(value){
                        return (value.min - 0.05).toFixed(2)
                    },
                    max: function(value){
                        return value.max
                    },
                }
                yOption = {
                    type: 'category',
                    data: x,
                    axisLabel: {
                            rotate: -35 // 设置X轴文字旋转角度为-45度
                        }
                }
            }else{
                console.log('22222');
                xOption = {
                    type: 'category',
                    data: x,
                    axisLabel: {
                            rotate: -35 // 设置X轴文字旋转角度为-45度
                        }
                };
                yOption = {
                    type: 'value',
                    min: function(value){
                        return (value.min - 0.05).toFixed(2)
                    },
                    max: function(value){
                        return value.max
                    },
                }
            }
            myChart.setOption({
                tooltip: {
                    trigger: 'axis',
                },
                legend: {},
                grid: {
                    left: this.isMobile ? '10%' : '5%',
                    right: this.isMobile ? '1%' : '5%',
                    // bottom: '3%',
                },
                yAxis: yOption,
                xAxis: xOption,
                series: [
                    {
                        name: '主胜',
                        type: 'bar',
                        data: w,
                        itemStyle: {
                            color: "#ee6666"
                        },
                        label: {
                            show: true,
                            verticalAlign: 'middle',
                            position: this.isMobile ? 'right' : 'top',
                        },
                        markLine: {
                            data: [{ type: 'average', name: 'wAvg' }]
                        }
                    },
                    {
                        name: '平',
                        type: 'bar',
                        data: d,
                        label: {
                            show: true,
                            verticalAlign: 'middle',
                            position: this.isMobile ? 'right' : 'top',
                        },
                        markLine: {
                            data: [{ type: 'average', name: 'dAvg' }]
                        }
                    },
                    {
                        name: '客胜',
                        type: 'bar',
                        data: l,
                        label: {
                            show: true,
                            verticalAlign: 'middle',
                            position: this.isMobile ? 'right' : 'top',
                        },
                        markLine: {
                            data: [{ type: 'average', name: 'lAvg' }]
                        }
                    }
                ]
            });
        }
    }
}
</script>
<style lang="scss" scoped>
    @import '../../assets/sass/global.scss';
    .container{padding-top: 60px;}
</style>