export function setcolor(color,league){
    var colordata = [
        //a
        {
            "name": "阿甲",
            "color": "#00CCCC"
        },
        {
            "name": "阿美超",
            "color": "#5F9292"
        },
        {
            "name": "爱甲",
            "color": "#CC66FF"
        },
        {
            "name": "阿塞超",
            "color": "#7a5c42"
        },
        //b
        {
            "name": "巴西甲",
            "color": "#996600"
        },
        {
            "name": "保超",
            "color": "#6C6C00"
        },
        {
            "name": "波兰超",
            "color": "#5ca39a"
        },
        {
            "name": "保乙",
            "color": "#993300"
        },
        {
            "name": "巴拉甲",
            "color": "#CD5E31"
        },
        {
            "name": "波兰乙",
            "color": "#5F9200"
        },
        //d
        {
            "name": "德地区",
            "color": "#FF6699"
        },
        //e
        {
            "name": "厄瓜甲",
            "color": "#9e9400"
        },
        //g
        {
            "name": "哥伦甲",
            "color": "#993333"
        },
        //h
        {
            "name": "洪都甲",
            "color": "#442f14"
        },
        //o
        {
            "name": "欧冠",
            "color": "#f75000"
        },
        {
            "name": "欧会杯",
            "color": "#D078D8"
        },
        {
            "name": "欧罗巴",
            "color": "#6F00DD"
        },
        //r
        {
            "name": "瑞典甲",
            "color": "#0054A8"
        },
        //s
        {
            "name": "斯亚乙",
            "color": "#66CCFF"
        },
        {
            "name": "沙特甲",
            "color": ""
        },
        {
            "name": "苏超",
            "color": "#57A87B"
        },
        {
            "name": "苏乙",
            "color": "#9df25e"
        },
        //y
        {
            "name": "亚冠杯",
            "color": "#0000DB"
        },
        {
            "name": "亚协杯",
            "color": "#D09C31"
        },
        {
            "name": "意乙",
            "color": "#66CCFF"
        },
        {
            "name": "意丙",
            "color": "#A4DFFF"
        },
        {
            "name": "英冠",
            "color": "#cc3300"
        },
        {
            "name": "英甲",
            "color": "#FF850B"
        },
        {
            "name": "英乙",
            "color": "#FFBA75"
        },
        {
            "name": "英议联",
            "color": "#FF9999"
        },
        {
            "name": "英议南/北",
            "color": "#996733"
        },
        {
            "name": "英议南/北",
            "color": "#FA619A"
        },
        //x
        {
            "name": "希腊甲",
            "color": "#666666"
        },
        {
            "name": "南非超",
            "color": "#111999"
        },
        {
            "name": "乌拉甲",
            "color": "#CC6666"
        },
        {
            "name": "墨西联",
            "color": "#098000"
        },
        {
            "name": "墨西甲",
            "color": "#999011"
        },
        {
            "name": "塞尔超",
            "color": "#996600"
        },
        {
            "name": "印度超",
            "color": "#9DA8A0"
        },
        {
            "name": "沙地甲",
            "color": "#2cb116"
        },
        {
            "name": "芬超",
            "color": "#003db9"
        },
        {
            "name": "立陶甲",
            "color": "#990000"
        },
        {
            "name": "白俄超",
            "color": "#6969e0"
        },
        {
            "name": "塞浦甲",
            "color": "#cc9900"
        },
        {
            "name": "乌拉乙",
            "color": "#94240a"
        },
        {
            "name": "委内超",
            "color": "#2A7F10"
        },
        {
            "name": "美职业",
            "color": "#660033"
        },
        {
            "name": "缅甸联",
            "color": "#56b70f"
        },
        {
            "name": "乌兹超",
            "color": "#64ba1e"
        },
        {
            "name": "罗乙",
            "color": "#6b4806"
        },
        {
            "name": "约超联",
            "color": "#110066"
        },
        {
            "name": "沙特联",
            "color": "#8d8abd"
        },
        {
            "name": "罗甲",
            "color": "#339966"
        },
        {
            "name": "巴西乙",
            "color": "#B5B500"
        },
        {
            "name": "厄瓜乙",
            "color": "#B5A150"
        },
        {
            "name": "印尼超",
            "color": "#5F9203"
        },
        {
            "name": "澳洲甲",
            "color": "#FF7000"
        },
        {
            "name": "韩K3联",
            "color": "#15DBAE"
        },
        {
            "name": "日职联",
            "color": "#009900"
        },
        {
            "name": "格鲁乙",
            "color": "#696900"
        },
        {
            "name": "塞尔甲",
            "color": "#0000db"
        },
        {
            "name": "泰超",
            "color": "#7A355E"
        },
        {
            "name": "马来超",
            "color": "#5d5abc"
        },
        {
            "name": "越南联",
            "color": "#3C5975"
        },
        {
            "name": "阿联酋超",
            "color": "#8e9cdf"
        },
        {
            "name": "阿曼联",
            "color": "#8e9cdf"
        },
        {
            "name": "克亚乙",
            "color": "#FF850B"
        },
        {
            "name": "俄甲",
            "color": "#8d5abf"
        },
        {
            "name": "伊朗超",
            "color": "#0099cc"
        },
        {
            "name": "捷乙",
            "color": "#00CCFF"
        },
        {
            "name": "斯伐超",
            "color": "#BEB9B9"
        },
        {
            "name": "斯亚甲",
            "color": "#666699"
        },
        {
            "name": "波兰甲",
            "color": "#5F9297"
        },
        {
            "name": "俄超",
            "color": "#006699"
        },
        {
            "name": "科威特联",
            "color": "#882266"
        },
        {
            "name": "克亚甲",
            "color": "#CC6600"
        },
        {
            "name": "奥乙",
            "color": "#4756D8"
        },
        {
            "name": "德乙",
            "color": "#CA00CA"
        },
        {
            "name": "丹麦超",
            "color": "#6969e3"
        },
        {
            "name": "丹麦甲",
            "color": "#7C7CFF"
        },
        {
            "name": "丹麦乙",
            "color": "#8C8CFF"
        },
        {
            "name": "德丙",
            "color": "#56b70f"
        },
        {
            "name": "葡甲",
            "color": "#0099CC"
        },
        {
            "name": "土超",
            "color": "#996600"
        },
        {
            "name": "土甲",
            "color": "#B5A152"
        },
        {
            "name": "瑞士甲",
            "color": "#00D200"
        },
        {
            "name": "比乙",
            "color": "#FFCC00"
        },
        {
            "name": "荷甲",
            "color": "#FF6699"
        },
        {
            "name": "荷乙",
            "color": "#FFC4E1"
        },
        {
            "name": "德甲",
            "color": "#990099"
        },
        {
            "name": "爱超",
            "color": "#9933FF"
        },
        {
            "name": "比甲",
            "color": "#FC9B0A"
        },
        {
            "name": "苏冠",
            "color": "#56b70f"
        },
        {
            "name": "威超",
            "color": "#4F4F4F"
        },
        {
            "name": "意甲",
            "color": "#0088FF"
        },
        {
            "name": "法甲",
            "color": "#663333"
        },
        {
            "name": "西甲",
            "color": "#006633"
        },
        {
            "name": "西乙",
            "color": "#438E0B"
        },
        {
            "name": "英超",
            "color": "#FF3333"
        },
        {
            "name": "葡超",
            "color": "#006665"
        },
        {
            "name": "哥伦乙",
            "color": "#55A0FF"
        },
        {
            "name": "尼拉甲",
            "color": "#1199BB"
        },
        {
            "name": "哥斯甲",
            "color": "#9DA8A0"
        },
        {
            "name": "波斯甲",
            "color": "#165106"
        },
        {
            "name": "埃及超",
            "color": "#8b4400"
        },
        {
            "name": "挪乙",
            "color": "#999900"
        },
        {
            "name": "巴林超",
            "color": "#33CC99"
        },
        {
            "name": "挪超",
            "color": "#666666"
        },
        {
            "name": "希腊超",
            "color": "#8C8A64"
        },
        {
            "name": "瑞典超",
            "color": "#004488"
        },
        {
            "name": "法乙",
            "color": "#B1A7A7"
        },
        {
            "name": "牙买超",
            "color": "#663333"
        },
        {
            "name": "厄瓜甲",
            "color": "#CD5E31"
        },
        {
            "name": "印度甲",
            "color": "#9DA8A0"
        },
        {
            "name": "波兰杯",
            "color": "#5F9292"
        },
        {
            "name": "土杯",
            "color": "#FFCC66"
        },
        {
            "name": "意杯",
            "color": "#3c3cff"
        },
        {
            "name": "保杯",
            "color": "#6C6C00"
        },
        {
            "name": "立陶乙",
            "color": "#57A87B"
        },
        {
            "name": "爱沙甲",
            "color": "#519b3d"
        },
        {
            "name": "俄杯",
            "color": "#003309"
        },
        {
            "name": "丹麦杯",
            "color": "#8d8abf"
        },
        {
            "name": "德国杯",
            "color": "#a00800"
        },
        {
            "name": "比利时杯",
            "color": "#fc9b0a"
        },
        {
            "name": "荷兰杯",
            "color": "#ff6677"
        },
        {
            "name": "西杯",
            "color": "#006666"
        },
        {
            "name": "英锦赛",
            "color": "#FF2F73"
        },
        {
            "name": "英联杯",
            "color": "#818181"
        },
        {
            "name": "葡联杯",
            "color": "#CB194C"
        },
        {
            "name": "日职乙",
            "color": "#33CC99"
        },
        {
            "name": "卡塔尔联",
            "color": "#92CCCC"
        },
        {
            "name": "格鲁甲",
            "color": "#a04e12"
        },
        {
            "name": "乌克超",
            "color": "#000000"
        },
        {
            "name": "斯伐甲",
            "color": "#BCBCC2"
        },
        {
            "name": "瑞典乙",
            "color": "#6699ff"
        },
        {
            "name": "法丙",
            "color": "#a00800"
        },
        {
            "name": "摩洛超",
            "color": "#668800"
        },
        {
            "name": "北爱超",
            "color": "#67417E"
        },
        {
            "name": "英足总杯",
            "color": "#0000cc"
        },
        {
            "name": "欧罗巴杯",
            "color": "#6F00DD"
        },
        {
            "name": "欧会杯",
            "color": "#D078D8"
        },
        {
            "name": "伊朗甲",
            "color": "#4999bb"
        },
        {
            "name": "苏甲",
            "color": "#71ec15"
        },
        {
            "name": "约旦甲",
            "color": "#3a794e"
        },
        {
            "name": "英南/北超",
            "color": "#999011"
        },
        {
            "name": "捷丙",
            "color": "#339866"
        },
        {
            "name": "非洲预选",
            "color": "#2f4e07"
        },
        {
            "name": "美洲联",
            "color": "#519b3d"
        },
        {
            "name": "日足联",
            "color": "#74d643"
        },
        {
            "name": "港菁杯",
            "color": "#CC6633"
        },
        {
            "name": "西协甲",
            "color": "#3366CC"
        },
        {
            "name": "捷丙",
            "color": "#339866"
        },
        {
            "name": "卡塔杯",
            "color": "#92CCCC"
        },
        {
            "name": "萨尔超",
            "color": "#000080"
        },
        {
            "name": "斯尼杯",
            "color": "#8C8A64"
        },
        {
            "name": "阿联酋甲",
            "color": "#C99901"
        },
        {
            "name": "巴林甲",
            "color": "#FF4273"
        },
        {
            "name": "韩K2联",
            "color": "#0099cc"
        },
        {
            "name": "泰甲",
            "color": "#993366"
        },
        {
            "name": "土乙",
            "color": "#e8a82c"
        },
        {
            "name": "爱沙乙",
            "color": "#339966"
        }
    ]
    if ( color == '#000') {
        for (let i = 0; i < colordata.length; i++) {
            if (league.indexOf(colordata[i].name)!==-1) {
                color = colordata[i].color;
                break;
            }else{
                color = '#000';
            }
        }
    }
    return color
}

export function qsdaColorSet(handicap,qsda,handicapToNum,convertToReal,compare){
    let qsdaColor = 'rgb(128,0,255)';
    let lowOrHight = 2;
    if (handicap!=='') {
        const handicapNum = handicapToNum(handicap);
        const realHandicapNum = convertToReal(qsda);
        if (handicapNum === 0) {
            qsdaColor = 'rgb(128,0,255)';
            return qsdaColor
        }else{
            if (typeof handicapNum !== 'undefined' && typeof realHandicapNum !== 'undefined') {
                    lowOrHight = compare(handicapNum, realHandicapNum);
                if(lowOrHight === 0){
                    qsdaColor = '#666';
                    return qsdaColor
                }
                if (lowOrHight === 1) {
                    qsdaColor = 'red';
                    return qsdaColor
                }
                if (lowOrHight === -1) {
                    qsdaColor = 'green';
                    return qsdaColor
                }
            }
        }
    }
}