// 检查用户登录状态
export  function checkLoginStatus() {
    // 从本地存储中获取登录状态
    // const isLoggedIn = sessionStorage.getItem('isLoggedIn');
    // const isLoggedIn = localStorage.getItem('isLoggedIn');
    const token = localStorage.getItem('token');
    const expirationDate = localStorage.getItem('expirationDate');
    const currentTime = new Date().getTime();
    if (!token || !expirationDate || currentTime > expirationDate) {
        console.log('未登录或登录状态已过期');
        // 未登录或登录状态已过期
        logout();
        return false;
    }
    // console.log('已登录');
    return true;
}

// 在登录成功后保存登录状态
export function saveLoginStatus(token,refreshtoken) {
    // console.log('保存登录状态');
    // 设置有效期为7天
    if (token) {
    const expirationDate = new Date().getTime() + 7 * 24 * 60 * 60 * 1000;
    localStorage.setItem('expirationDate', expirationDate);
    // 将用户信息存储在本地存储中
    // localStorage.setItem('token', token);
    // localStorage.setItem('isLoggedIn','true');
    localStorage.setItem('token', token); //使用 sessionStorage 存储令牌
    localStorage.setItem('refreshtoken', refreshtoken); //存储刷新令牌
    // sessionStorage.setItem('isLoggedIn', 'true');
    // 设置登录状态为已登录
    // localStorage.setItem('isLoggedIn', 'true');
    }
}

export function logout() {
    // 清除保存的登录状态和 token
    // localStorage.removeItem('token'); // 或者使用 sessionStorage.removeItem('token');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshtoken');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('refreshDate');
}

export async function refreshToken(){
        const oldToken = localStorage.getItem('token');
        const oldRefreshtoken = localStorage.getItem('refreshtoken');
        const refreshDateStr = localStorage.getItem('refreshDate');
        // console.log(oldToken,oldRefreshtoken);
        //第一次登录，不刷新token并存储一个刷新日期的localStorage，返回
        if (refreshDateStr == null) {
            const currentTime = new Date();
            localStorage.setItem('refreshDate', currentTime); 
            return
        }
        // 检查如果超过24小时，就先刷新token
        if (refreshDateStr !== null && new Date() - refreshDateStr > 24 * 60 * 60 * 1000) {
        // 调用刷新token的接口
            fetch('/users/editer/refreshtoken',{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    token: oldToken,
                    refreshtoken: oldRefreshtoken,
                })
            }).then(response => response.json()).then((res)=>{
                // console.log(res);
                //刷新成功
                if (res.code === 200) {
                    const token = res.data.token;
                    const refreshToken = res.data.refreshtoken;
                    const refreshDate = new Date().getTime();
                    localStorage.setItem('refreshDate', refreshDate);
                    console.log('刷新token成功!');
                    // 登录成功后保存 token
                    saveLoginStatus(token,refreshToken);
                }else{
                    console.log(res.msg);
                }
            }).catch((error)=>{
                    console.log('刷新token失败:',error);
                    logout();
            })
        }
}