<template>
    <div>
    <Nav :isDark.sync="isDark" @darkmode="isDark = $event"></Nav>
    <div class="container">
        <div class="text-center" style="height: calc(100vh - 76px);" v-if="this.matchData.length === 0 && !fullscreenLoading">
            <div class="nodata d-flex flex-column justify-content-center" :class="{'h-100':this.matchData.length === 0}">
                <span class="align-center text-secondary">暂无数据</span>
            </div>
        </div>
        <div class="sword" v-loading.fullscreen.lock="fullscreenLoading" v-if="this.matchData.length !== 0">
            <div class="d-flex justify-content-between align-items-center py-3">
                <h4>{{ dateTitle }}</h4>
                <router-link :to="'/checkback-m'" class="text-primary fs-12" style="cursor: pointer;">手机端查看>></router-link>
            </div>
            
            <div class="sword-items" v-for="(item,index) in matchData" :key="index">
                <table :class="{'table-dark': isDark}" class="table table-bordered w-100 font-weight-bold mb-0">
                    <tbody class="">
                        <tr class="fw-bold">
                            <td width="136" class="text-white text-center align-middle" :style="'background:'+ item.color">{{item.league}}</td>
                            <td width="82" class="text-center align-middle">{{item.time}}</td>
                            <td class="text-center">
                                <a :href="'https://zq.titan007.com/analysis/'+item.m_id+'cn.htm'" target='_blank' class="text-decoration-none">
                                <div class="match-team">
                                    <span>
                                        <sup class="fw-normal"><font>{{ item.home_rank }}</font></sup>
                                        {{item.home}}
                                    </span>
                                    <span class="mx-4 px-4" :style="{color: qsdaColorSet(item.handicap,item.qsda)}">{{item.qsda}}</span>
                                    <span>
                                        {{item.away}}
                                        <sup class="fw-normal"><font>{{ item.away_rank }}</font></sup>
                                    </span>
                                </div></a>
                            </td>
                            <td width="135"  class="text-center align-middle"><a :href="'https://live.nowscore.com/analysis/'+item.id+'cn.html'" target='_blank' class="text-decoration-none" style="color: inherit;">{{item.handicap}}</a></td>
                            <td width="69"  class="text-center align-middle">{{item.bigsmall}}</td>
                            <!-- <td width="69"  class="text-center font-weight-bold align-middle" :style="{color: qsdaColorSet(item.handicap,item.qsda)}">{{item.qsda}}</td> -->
                        </tr>
                    </tbody>
                </table>
                <!-- <table :class="{'table-dark': isDark}" class="table table-striped table-bordered table-sm mb-4 text-nowrap">
                    <thead :class="{'table-dark': isDark}" class="table-light" style="font-size:12px">
                        <tr>
                            <th>日期</th>
                            <th>联赛</th>
                            <th>主队</th>
                            <th>比分</th>
                            <th>客队</th>
                            <th>结果</th>
                            <th>初/终</th>
                            <th>指数1</th>
                            <th>Crown</th>
                            <th>指数2</th>
                            <th>大球</th>
                            <th>大小球</th>
                            <th>小球</th>
                        </tr>
                    </thead>
                    <tbody :class="'sword-list-'+ (index+1)">
                        <tr v-for="(element,i) in history[index]" :key="i">
                            <td width="10%" class="align-middle text-center">{{ element.date }}</td>
                            <td width="6%" class="align-middle text-center">{{element.league}}</td>
                            <td width="17%" class="align-middle text-end">{{element.home}}</td>
                            <td width="5%" class="align-middle text-center">{{element.score}}</td>
                            <td width="17%" class="align-middle">{{element.away}}</td>
                            <td width="5%" class="align-middle text-center" :style="'color:'+checkcolor(element.state)">{{element.state}}</td>
                            <td width="5%" class="align-middle text-center">
                                <div>初盘</div>
                                <div>终盘</div>
                            </td>
                            <td width="5%" class="align-middle text-center">
                                <div>{{element.h_index1}}</div>
                                <div>{{element.h_index2}}</div>
                            </td>
                            <td width="5%" class="align-middle text-center">
                                <div>{{element.init}}</div>
                                <div>{{element.final}}</div>
                            </td>
                            <td width="5%" class="align-middle text-center">
                                <div>{{element.a_index1}}</div>
                                <div>{{element.a_index2}}</div>
                            </td>
                            <td width="5%" class="align-middle text-center">
                                <div>{{element.big_1}}</div>
                                <div>{{element.big_2}}</div>
                            </td>
                            <td width="5%" class="align-middle text-center">
                                <div>{{element.bs_init}}</div>
                                <div>{{element.bs_final}}</div>
                            </td>
                            <td width="5%" class="align-middle text-center">
                                <div>{{element.samll_1}}</div>
                                <div>{{element.samll_2}}</div>
                            </td>
                        </tr>
                    </tbody>
                </table> -->
                <!-- <ux-grid
                ref="plxTable"
                stripe
                show-body-overflow="title"
                use-virtual
                :data = history[index]
                :highlightCurrentRow="true"
                :class="'sword-list-'+ (index+1)"
                show-header-overflow="title"
                @table-body-scroll="scroll">
                    <ux-table-column field="date" title="日期">
                        <template v-slot="scope">
                            {{scope.row.date}}
                        </template>
                    </ux-table-column>
                    <ux-table-column field="time" title="时间">
                        <template v-slot="scope">
                            {{scope.row.time}}
                        </template>
                    </ux-table-column>
                    <ux-table-column field="home" title="主队">
                        <template v-slot="scope">
                            {{scope.row.home}}
                        </template>
                    </ux-table-column>
                    <ux-table-column field="score" title="比分">
                        <template v-slot="scope">
                            {{scope.row.score}}
                        </template>
                    </ux-table-column>
                    <ux-table-column field="away" title="客队">
                        <template v-slot="scope">
                            {{scope.row.away}}
                        </template>
                    </ux-table-column>
                    <ux-table-column field="qsda" title="qsda">
                        <template v-slot="scope">
                            {{scope.row.qsda}}
                        </template>
                    </ux-table-column>
                    <ux-table-column field="state" title="结果" :filters="result" :filter-method="filterTag">
                        <template v-slot="scope">
                            <span :style="'color:'+checkcolor(scope.row.state)">{{scope.row.state}}</span>
                        </template>
                    </ux-table-column>
                    <ux-table-column title="初/终" field="cz">
                        <template>
                            <div>初盘</div>
                            <div>终盘</div>
                        </template>
                    </ux-table-column>
                    <ux-table-column field="index1" title="指数1">
                        <template v-slot="scope">
                            <div>{{scope.row.h_index1}}</div>
                            <div>{{scope.row.h_index2}}</div>
                        </template>
                    </ux-table-column>
                    <ux-table-column field="crown" title="Crown">
                        <template v-slot="scope">
                            <div>{{scope.row.init}}</div>
                            <div>{{scope.row.final}}</div>
                        </template>
                    </ux-table-column>
                    <ux-table-column field="index2" title="指数2">
                        <template v-slot="scope">
                            <div>{{scope.row.a_index1}}</div>
                            <div>{{scope.row.a_index2}}</div>
                        </template>
                    </ux-table-column>
                    <ux-table-column field="big" title="大球">
                        <template v-slot="scope">
                            <div>{{scope.row.big_1}}</div>
                            <div>{{scope.row.big_2}}</div>
                        </template>
                    </ux-table-column>
                    <ux-table-column field="bigsmall" title="大小球">
                        <template v-slot="scope">
                            <div>{{scope.row.bs_init}}</div>
                            <div>{{scope.row.bs_final}}</div>
                        </template>
                    </ux-table-column>
                    <ux-table-column field="small" title="小球">
                        <template v-slot="scope">
                            <div>{{scope.row.samll_1}}</div>
                            <div>{{scope.row.samll_2}}</div>
                        </template>
                    </ux-table-column>
                </ux-grid> -->
                <div class="collapse" :id="'collapse'+index">
                <el-table :data="history[index]" border stripe style="width: 100%" :cell-style="columnStyle" :header-cell-class-name="headerClass">
                    <el-table-column row-class-name="bgdark" prop="date" label="日期" width="140" ></el-table-column>
                    <el-table-column prop="league" label="时间" width="80"></el-table-column>
                    <el-table-column prop="home" label="主队" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="score" label="比分" width="59"></el-table-column>
                    <el-table-column prop="away" label="客队" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="qsda" label="qsda" width="60"></el-table-column>
                    <el-table-column prop="state" label="结果" width="70" column-key="state" :filters="result" :filter-method="filterHandler">
                        <template v-slot="scope">
                            <span>{{scope.row.state}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="初/终" width="60">
                        <template>
                            <div>初盘</div>
                            <div>终盘</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="index1" label="指数1" width="60">
                        <template v-slot="scope">
                            <div>{{scope.row.h_index1}}</div>
                            <div>{{scope.row.h_index2}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="crown" label="澳/Crown" width="100">
                        <template v-slot="scope">
                            <div>{{scope.row.init}}</div>
                            <div>{{scope.row.final}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="index2" label="指数2" width="60">
                        <template v-slot="scope">
                            <div>{{scope.row.a_index1}}</div>
                            <div>{{scope.row.a_index2}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="big" label="大球" width="60">
                        <template v-slot="scope">
                            <div>{{scope.row.big_1}}</div>
                            <div>{{scope.row.big_2}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="bigsmall" label="大小球" width="60">
                        <template v-slot="scope">
                            <div>{{scope.row.bs_init}}</div>
                            <div>{{scope.row.bs_final}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="small" label="小球" width="60">
                        <template v-slot="scope">
                            <div>{{scope.row.samll_1}}</div>
                            <div>{{scope.row.samll_2}}</div>
                        </template>
                    </el-table-column>
                </el-table>
                </div>
                <button v-if="history[index].length>0" class="btn border w-100 text-primary fs-12" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse'+index" aria-expanded="false" aria-controls="collapseExample" @click="showAllBtn(index)">
                    <span :class="{'d-none':showAll[index]}">展开</span><span :class="{'d-none':!showAll[index]}">收起</span>
                </button>
                <div class="pb-4"></div>
            </div>
            <div v-if="loadMore && !fullscreenLoading" class="text-center">
                <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div v-if="this.checkbackData.length !== 0">
                <button type="button" :class="{'btn-dark': isDark}" class="btn btn-light text-secondary w-100 mb-3" v-if="!noMore" @click="this.loadData">加载更多</button>
                <div v-if="noMore && !fullscreenLoading" class="text-center mb-3" style="color: #ccc;">-- 已经到底了 --</div>
            </div>
        </div>
    </div>
    <Footer></Footer>
    </div>
</template>
<script>
// import checkbackData from '../data/checkback.json'
import Footer from './comment/footer.vue'
import colorList from '../data/colorList.json'
import { checkColor, handicapToNum, convertToReal, compare, dateFormatted } from '../utils/utils'
import Nav from './comment/nav.vue'
import { throttle } from 'lodash';
import { checkbackResUrl, checkBackListTomorrowUrl } from '../constants/http.config'
export default {
    components: { Nav,Footer },
    data() {
        return {
            dateTitle: '',
            fullscreenLoading: true,
            visibleData: [],
            matchData: [],
            history: [],
            leagueColor: '#000',
            qsdaColor: 'rgb(128,0,255)',
            lowOrHight: 2,
            isDark: false,
            currentPage: 1,
            pageSize: 10,
            loadMore: false,
            hasHistory: false,
            noMore : false,
            showAll: [],
            checkbackData: [],
            columns: [
                { label: '日期', prop: 'date'},
                { label: '联赛', prop: 'league'},
                { label: '主队', prop: 'home'},
                { label: '比分', prop: 'score'},
                { label: '客队', prop: 'away'},
                {label: '结果', prop: 'state'},
                { label: 'qsda', prop: 'qsda'},
                { label: '指数1', prop: 'index1'},
                {label: '澳/Crown', prop: 'init'},
                {label: '指数2', prop: 'index2'},
                {label: '大球', prop: 'big'},
                {label: '大小球', prop: 'bigsmall'},
                {label: '小球', prop: 'small'},
            ],
            result: [
                {text: '胜',value: '胜'},
                {text: '平',value: '平'},
                {text: '负',value: '负'}
            ]
        }
    },
    mounted() {
        // this.matchData = checkbackData
        // const today = new Date();
        // const currentHours = today.getHours();
        // const currentMin = today.getMinutes();
        this.getdata().then(()=>{
            // if (currentHours < 10 || currentHours == 10 && currentMin < 30 || this.checkbackData[0].date == this.dateFormatted(today)) {
                // console.log('数据是今天或明日');
                this.loadData();
            // }else{
            //     console.log('数据不是今日或明日的');
            //     this.checkbackData = [];
            //     this.fullscreenLoading = false;
            // }
        });
        // 使用lodash的throttle函数创建一个新的函数，这个新函数会在每300ms内最多执行一次handleScroll函数
        this.throttledHandleScroll = throttle(this.handleScroll, 300);
        // 监听滚动事件
        // window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() {
        // 移除滚动事件监听
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        dateFormatted,
        //回查结果
        async getHistory(qsda,init,bsInit){
            // console.log(qsda,init,bsInit);
            let res = []
            const url = checkbackResUrl + 'qsda=' + qsda + '&init=' + init + '&bsInit=' + bsInit + '&pageNum=1&pageSize=20';
            // const url = 'localhost:8888/api/match/history?';
            await fetch(url, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => response.json())
            .then(data => {
                    // console.log(data.data.list);
                    res = data.data.list;
            });
            return res;
            // console.log(res);
        },
        //明日回查比赛
        async getdata(){
            const url = '/match/checkback-t';
            await fetch(checkBackListTomorrowUrl, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => response.json())
            .then(async res => {
                console.log('checkback length',res.data[0]);
                this.checkbackData = res.data;
                this.dateTitle = res.data[0].date;
            }).catch((error)=>{
                console.log(error);
            });
            
        },
        checkcolor(key){
            return checkColor(key)
        },
        handleScroll() {
            let scrollTop = document.documentElement.scrollTop; //滚动条在Y轴滚动过的高度
            let scrollHeight = document.documentElement.scrollHeight; //滚动条的高度
            let clientHeight = document.documentElement.clientHeight; //浏览器的可视高度
            // 检查是否接近页面底部
            if (scrollTop + clientHeight == scrollHeight && !this.loadMore) {
                // 加载更多数据
                this.loadData();
                console.log('加载更多');
            }
        },
        async dataHandler(num, lengthNum){
                for (let index = num; index < lengthNum; index++) {
                    const element = this.checkbackData[index];
                    // console.log(index+1,element);
                    if (element.handicap !== '' || typeof element.handicap !== 'undefined') {
                        const handicapNum = handicapToNum(element.handicap);
                        const realHandicapNum = convertToReal(element.qsda);
                        if (handicapNum !== 0) {
                            if (typeof handicapNum !== 'undefined' && typeof realHandicapNum !== 'undefined') {
                                    this.lowOrHight = compare(handicapNum, realHandicapNum);
                                if(this.lowOrHight === 0){
                                    this.qsdaColor = 'black';
                                }
                                if (this.lowOrHight === 1) {
                                    this.qsdaColor = 'red';
                                }
                                if (this.lowOrHight === -1) {
                                    this.qsdaColor = 'green';
                                }
                            }
                        }
                        if ( element.color == '#000' || typeof element.color == 'undefined') {
                            for (let i = 0; i < colorList.length; i++) {
                                // console.log(colordata[i].name.indexOf(league));
                                    if (element.league.indexOf(colorList[i].league)!==-1) {
                                        element.color = colorList[i].color;
                                    break;
                                }
                            }
                        }else{
                            // element.color = '#000';
                        }
                    }
                    if (element.handicap !== '' && element.qsda !== '' && element.bigsmall !== '') {
                        const transformHandicap = element.handicap.replace('受', '*');
                        await this.getHistory(element.qsda,transformHandicap,element.bigsmall).then((res)=>{
                            if (res.length > 0) {
                                // console.log('有回查');
                                this.fullscreenLoading = false;
                                this.hasHistory = true
                                this.matchData.push(element);
                                this.history.push(res);
                                this.showAll.push(false);
                            // console.log('222',res);
                            }
                        })
                    }
                }
            // console.log(this.matchData,this.showAll);
            
        },
        async loadData() {
            console.log('loadmore');
            // 避免重复加载
            if (this.loadMore) {
                return;
            }
            this.loadMore = true;
            const start = (this.currentPage - 1) * this.pageSize;
            const end = start + this.pageSize <= this.checkbackData.length ? start + this.pageSize : this.checkbackData.length;
            // console.log(start,end,this.currentPage);
            
            
            await this.dataHandler(start,end).then(()=>{
                    this.currentPage++;
                    this.loadMore = false;
                if (start == 0 && this.matchData.length <= 2) {
                    console.log('no his');
                    const start = (this.currentPage - 1) * this.pageSize;
                    const end = start + this.pageSize;
                    console.log(start,end,this.currentPage);
                    this.dataHandler(start,end)
                    this.currentPage++
                }
                if(start !== 0 && this.hasHistory){
                    console.log('has his');
                    this.hasHistory = true;
                    if (this.currentPage > 1 && end == this.checkbackData.length) {
                        this.loadMore = false;
                        this.noMore = true;
                        console.log('没有更多了');
                        return;
                    }
                }
            });
            
        },
        qsdaColorSet(handicap,qsda){
            let qsdaColor = 'rgb(128,0,255)';
            let lowOrHight = 2;
            if (handicap!=='') {
                const handicapNum = handicapToNum(handicap);
                const realHandicapNum = convertToReal(qsda);
                if (handicapNum === 0) {
                    qsdaColor = 'rgb(128,0,255)';
                    return qsdaColor
                }else{
                    if (typeof handicapNum !== 'undefined' && typeof realHandicapNum !== 'undefined') {
                            lowOrHight = compare(handicapNum, realHandicapNum);
                        if(lowOrHight === 0){
                            qsdaColor = this.isDark ? '#ccc' : 'black';
                            return qsdaColor
                        }
                        if (lowOrHight === 1) {
                            qsdaColor = 'red';
                            return qsdaColor
                        }
                        if (lowOrHight === -1) {
                            qsdaColor = 'green';
                            return qsdaColor
                        }
                    }
                }
            }
        },
        filterTag({value, row, column}) {
            // console.log(column);
            const property = column['property'];
            return row[property] === value;
        },
        filterHandler(value, row, column) {
            const property = column['property'];
            return row[property] === value;
        },
        showAllBtn(index){
            this.showAll[index] = !this.showAll[index];
            // console.log(index,this.showAll[index]);
        },
        columnStyle({ row,columnIndex }){
            if(columnIndex > 0){
                if (columnIndex === 6) {
                // console.log('ssssss',row);
                    return 'background:'+this.checkcolor(row.state)+'; text-align: center; color: #000'
                }
                if (columnIndex >= 5) {
                    return 'text-align: center; padding: 0;'
                }
                return 'padding: 0;'
            }
        },
        headerClass({ columnIndex }){
            if (columnIndex>=0) {
                if (columnIndex >=5) {
                    return 'p-0 fs-12 text-center'
                }
                return 'p-0 fs-12'
            }
        }
    }
}
</script>
<style lang="scss">
    @import '../assets/sass/global.scss';
    .bgdark {background-color: #0d1421}
    .sword {padding-top: 60px;}
    .sword-items {
        .btn {
            border-radius: 0;
        }
    }
    .table {border-bottom: 1px solid transparent;}
    ::v-deep .elx-table {font-size: 1rem;}
    ::v-deep .el-table__cell {padding: 5px 0!important;}
    ::v-deep .el-table__header{
        font-size: 12px;
    }
    ::v-deep .el-table .el-table__cell {padding: 0; color: blue;}
    .NIGHT .el-table__row,.NIGHT .el-table th.el-table__cell {
        background-color: #0d1421;
        color: #fff;
    }
    .NIGHT .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell,.NIGHT .el-table, .el-table__expanded-cell {
        background-color: #192336;
    }
    .NIGHT .el-table th.el-table__cell.is-leaf {
        border-bottom: 1px solid #4d5154;
    }
    .NIGHT .el-table--border .el-table__cell {
        border-right: 1px solid #4d5154;
        border-bottom: 1px solid #4d5154;
    }
    .NIGHT .el-table--border {
        border: 1px solid #4d5154;
    }
    .NIGHT .el-table::before,.NIGHT .el-table--border::after {background-color: unset;}
    .NIGHT .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {background-color: #192336;}
    @media (max-width: 1024px) {
        .match-team {
            display: flex;
            flex-direction: column;
        }
    }
</style>