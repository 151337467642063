<template>
  <div v-loading="loading" :style="{height: this.data.length === 0 ? 'calc(100vh - 76px)' : 'auto'}">
    <div class="container" :class="{'h-100':this.data.length === 0}" :style="{height: loading ? '100vh' : 'auto'}">
        <Nav :isDark.sync="this.isDark" @darkmode="isDark = $event"></Nav>
        <el-backtop></el-backtop>
        <div class="d-flex justify-content-between align-items-center" style="width: 950px;margin: 0 auto;">
            <span class="fs-4">{{ this.dateStr }}</span>
            <router-link :to="'/qsda-today'" class="text-primary" style="cursor: pointer;">查看今日比赛>></router-link>
        </div>
        <!-- <div class="mb-4 text-center" :class="{'h-100':this.data.length === 0}" v-if="this.data.length === 0 && !loading">
            <div class="nodata d-flex flex-column justify-content-center" :class="{'h-100':this.data.length === 0}">
                <span class="align-center">暂无比赛数据</span>
            </div>
        </div> -->
        <div class="d-flex justify-content-center" :class="{'h-100':this.data.length === 0}">
        <div style="width: 1080px;">
        <ux-grid
             ref="table"
             stripe
             show-overflow
             use-virtual
             :widthResize = 'true'
             :empty-text = this.emptyText
             :data = data
             :highlightCurrentRow="true"
             show-header-overflow="ellipsis">
                <ux-table-column type="index" :width="40"/>
                <ux-table-column :width="100" field="league" title="联赛" :filters="filters" :filter-method="filterTag">
                    <template v-slot="scope">
                        <div class="text-white text-center" :style="{background: scope.row.color, padding: '0 8px'}">{{scope.row.league}}</div>
                    </template>
                </ux-table-column>
                <ux-table-column :width="60" field="time" title="时间"></ux-table-column>
                <ux-table-column field="home" title="主队">
                    <template v-slot="scope">
                        <sup><font>{{ scope.row.home_rank }}</font></sup>
                        <router-link :to="{ path: '/charts/' + scope.row.id, query: { teams: scope.row.home+' VS '+scope.row.away } }" class="my-2 text-decoration-none ps-1" tag="a" target="_blank">{{ scope.row.home }}</router-link>
                    </template>
                </ux-table-column>
                <ux-table-column :width="80" field="qsda" title="qsda">
                    <template v-slot="scope">
                        <a :href="'https://zq.titan007.com/analysis/'+scope.row.m_id+'cn.htm'" target='_blank' class="text-decoration-none font-weight-bold">
                            <span class="qsda qsdaColor" :style="{color: qsdaColorSet(scope.row.handicap,scope.row.qsda)}">{{ scope.row.qsda }}</span>
                        </a>
                    </template>
                </ux-table-column>
                <ux-table-column field="away" title="客队">
                    <template v-slot="scope">
                        {{ scope.row.away }}
                        <sup><font>{{ scope.row.away_rank }}</font></sup>
                    </template>
                </ux-table-column>
                <!-- <ux-table-column :width="60" field="index1" title="指数1">
                    <template v-slot="scope">
                        <div class="d-flex flex-column">
                            <span style='color:#880000;'>{{ scope.row.h_index }}</span>
                            <span>{{ scope.row.big }}</span>
                        </div>
                    </template>
                </ux-table-column> -->
                <ux-table-column :width="100" field="handicap" title="Crow">
                    <template v-slot="scope">
                        <div class="d-flex flex-column">
                            <span style='color:#880000;'>{{ scope.row.handicap }}</span>
                            <span>{{ scope.row.bigsmall }}</span>
                        </div>
                    </template>
                </ux-table-column>
                <!-- <ux-table-column :width="60" field="index1" title="指数2">
                    <template v-slot="scope">
                        <div class="d-flex flex-column">
                            <span style='color:#880000;'>{{ scope.row.a_index }}</span>
                            <span>{{ scope.row.small }}</span>
                        </div>
                    </template>
                </ux-table-column> -->
            </ux-grid>
        </div>
        </div>
        <!-- <table :class="{'table-dark': this.isDark}" class="table table-striped border" ref="table" v-if="this.data.length !== 0" style="width: 950px;margin: 0 auto;">
                <thead class="fs-12" style="background-color: #82959F!important;">
                    <tr class="font-weight-normal py-0 text-white">
                        <th class="border-end font-weight-normal py-1" width='40'>#</th>
                        <th class="border-end text-center font-weight-normal py-1">联赛</th>
                        <th class="border-end text-center font-weight-normal py-1" width='80'>时间</th>
                        <th class="border-end text-end font-weight-normal py-1">主队</th>
                        <th class="border-end text-center font-weight-normal py-1" width='80'>qsda</th>
                        <th class="border-end text-left font-weight-normal py-1">客队</th>
                        <th class='border-end text-center font-weight-normal py-1' v-if="isToday == true">指数1</th>
                        <th class='border-end text-center font-weight-normal py-1'>Crow*</th>
                        <th class='border-end text-center font-weight-normal py-1' v-if="isToday == true">指数2</th>
                        <th class='text-center font-weight-normal py-1' v-if="isToday == false">大小</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in data" :key="index">
                        <td class="border-end align-middle" width='40'>{{index+1}}</td>
                        <td :style="{'background':callSetColor(item.color,item.league)}" class='border-end td2 text-white align-middle text-center py-0' width='100'>{{ item.league }}</td>
                        <td class='border-end text-center align-middle' width='80'>{{item.time}}</td>
                        <td class='border-end text-end align-middle'>
                            <font>{{ item.home_rank }}</font>
                            {{ item.home }}
                        </td>
                        <td class='text-center font-weight-bold border-end align-middle qsda qsdaColor' width='80' :style="{color: qsdaColorSet(item.handicap,item.qsda)}"><a :href="'https://zq.titan007.com/analysis/'+item.id+'cn.htm'" target='_blank' class="text-decoration-none">{{ item.qsda }}</a>
                        </td>
                        <td class='border-end text-left align-middle'>
                            {{ item.away }}
                            <font>{{ item.away_rank }}</font>
                        </td>
                        <td class='border-end text-center' width="80" v-if="isToday == true">
                            <div style='color:#880000;'>{{ item.index1 }}</div>
                            <div>{{ item.index2 }}</div>
                        </td>
                        <td class='border-end text-center' width="120">
                            <div style='color:#880000;'>{{ item.handicap }}</div>
                            <div v-if="isToday == true">{{ item.bigsmall }}</div>
                        </td>
                        <td class='border-end text-center' width="80" v-if="isToday == true">
                            <div style='color:#880000;'>{{ item.index1 }}</div>
                            <div>{{ item.index2 }}</div>
                        </td>
                        <td class='border-end text-center' v-if="isToday == false">{{ item.bigsmall }}</td>
                    </tr>
                </tbody>
            </table> -->
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import {setcolor } from '@/utils/setcolor';
import { checkColor, handicapToNum, convertToReal, compare, dateFormatted } from '../utils/utils'
import { getMatchUrl } from '../constants/http.config'
import Nav from './comment/nav.vue'
import Footer from './comment/footer.vue'
export default {
    inject: ['reload'],
    components: { Nav,Footer },
    data() {
        return {
            data: [],
            filters: [],
            emptyText: '数据加载中...',
            isDark: false,
            isToday: true,
            isTomorrow: false,
            loading: true,
            dateStr: '',
            currentDate: 0
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        setcolor,
        dateFormatted,
        handicapToNum,
        checkColor,
        convertToReal,
        compare,
        callSetColor(color,league){
            return setcolor(color,league);
        },
        qsdaColorSet(handicap,qsda){
            let qsdaColor = 'rgb(128,0,255)';
            let lowOrHight = 2;
            if (handicap!=='') {
                const handicapNum = handicapToNum(handicap);
                const realHandicapNum = convertToReal(qsda);
                if (handicapNum === 0) {
                    qsdaColor = 'rgb(128,0,255)';
                    return qsdaColor
                }else{
                    if (typeof handicapNum !== 'undefined' && typeof realHandicapNum !== 'undefined') {
                            lowOrHight = compare(handicapNum, realHandicapNum);
                        if(lowOrHight === 0){
                            qsdaColor = '#666';
                            return qsdaColor
                        }
                        if (lowOrHight === 1) {
                            qsdaColor = 'red';
                            return qsdaColor
                        }
                        if (lowOrHight === -1) {
                            qsdaColor = 'green';
                            return qsdaColor
                        }
                    }
                }
            }
        },
        getData(){
            let date = new Date();
            date.setDate(date.getDate() + 1);
            const tomorrowDate = this.dateFormatted(date)
            // console.log(tomorrowDate);
            const url = getMatchUrl + tomorrowDate;
            // const url = '/match/date/' + tomorrowDate;
            fetch(url)
            .then(response => response.json())
            .then(data => {
                this.loading = false;
                console.log(data.data);
                this.data = data.data;
                if (this.data.length == 0) {
                        this.emptyText = '暂无数据';
                    }else{
                        this.emptyText = '加载中...';
                    }
                    this.$refs.table.reloadData(this.data);
                    this.updateFilterEvent()
            }).catch(err=>{
                this.loading = false;
                console.log('读取数据出错',err);
            })
        },
        filterTag({value, row, column}) {
            // console.log(column.filters);
            const property = column['property'];
                return row[property] === value;
        },
        updateFilterEvent(){
            const column = this.$refs.table.getColumnByField('league')
            const leagueSet = new Set(this.data.map((item) => item.league));
            const leagueArray = Array.from(leagueSet);
            this.filters = leagueArray.map((item) => ({ label: item, value: item }));
            // console.log(this.filters,'wwwww');
            this.$refs.table.setFilter(column, this.filters)
            this.$refs.table.updateData()
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../assets/sass/global.scss';
    .container{padding-top: 60px; padding-bottom: 15px;}
    font {color: rgb(136, 136, 136);}
    .table {
        --bs-table-bg: none;
        --bs-table-color: none;
        td {padding: 0.75rem;}
        .td2 {--bs-table-bg-type: inherit;}
    }
    ::v-deep .elx-body--row .col_2 .elx-cell {padding: 0;}
    ::v-deep .elx-header--row .col_3 .elx-cell {justify-content: center;}
    ::v-deep .elx-header--row .col_5 .elx-cell {justify-content: right;}
    ::v-deep .elx-header--row .col_6 .elx-cell {justify-content: center;}
    ::v-deep .elx-table--body-wrapper {font-size: 16px; color: #000;}
    ::v-deep .col_3 .elx-cell {padding: 0; line-height: 48px;}
    ::v-deep .elx-body--column { text-align: center!important; }
    ::v-deep .col_5 { text-align: right!important; }
    ::v-deep .col_7 { text-align: left!important; }
    // ::v-deep .elx-table.border--full .elx-table--header-wrapper { background-color: #82959f;}
    // ::v-deep .elx-header--column .elx-cell { color: #fff; }
    ::v-deep .elx-table .elx-header--column.col--ellipsis {height: auto;padding: 5px 0;}
    .nodata {
            color: #ccc;
        }
</style>